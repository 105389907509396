import React, { Component } from "react";

//images
import findConsult from "../../include/images/find-consultant.svg";
import overseasConsult from "../../include/images/overseas-consultant.svg";
import iconSchool from "../../include/images/icon-school.svg";
import addConsult from "../../include/images/add-consultant.svg";
import reviewConsult from "../../include/images/review-consultant.svg";
import distEduConsult from "../../include/images/distance-edu-consultant.svg";
import visaConsult from "../../include/images/visa-consultant.svg";
import placementConsult from "../../include/images/placement-consultant.svg";
import jobConsult from "../../include/images/job-consultant.svg";
import arrow1 from "../../include/images/arrow-1.svg";
import iconMang from "../../include/images/icon-management.svg";
import iconEng from "../../include/images/icon-engineering.svg";
import iconMedical from "../../include/images/icon-medical.svg";
import iconScience from "../../include/images/icon-science.svg";
import iconArt from "../../include/images/icon-arts.svg";
import iconArchi from "../../include/images/icon-architecture.svg";
import iconPhar from "../../include/images/icon-pharmacy.svg";
import iconLaw from "../../include/images/icon-law.svg";
import iconPara from "../../include/images/icon-paramedical.svg";
import iconPlus from "../../include/images/icon-plus.svg";
import iconDesign from "../../include/images/icon-design.svg";
import iconCrf1 from "../../include/images/icon-crf-1.svg";
import iconCrf4 from "../../include/images/icon-crf-4.svg";
import exam3 from "../../include/images/exam-3.svg";
import iconApp from "../../include/images/icon-application.svg";
import iconAdms2 from "../../include/images/icon-addmosion2.svg";
import profileEval from "../../include/images/profileevaluation.svg";
import visa from "../../include/images/visa-passport.svg";
import travel from "../../include/images/travel-stay.svg";
import youtubeplay from "../../include/images/youtube-play-icon.svg";
import whatWeDo from "../../include/images/what-we-do.svg";
import whyUs from "../../include/images/why-us.svg";
import howwork from "../../include/images/how-work.svg";
import cconatctUs from "../../include/images/contact-us.svg";
import school1 from "../../include/images/school-img1.jpg";
import school2 from "../../include/images/school-img2.jpg";
import school3 from "../../include/images/school-img3.jpg";
import iconTesti from "../../include/images/testmonials-icon.svg";

//Compoent
import Header from "../common/header";
import Hero from "../common/hero";
import Slider from "react-slick";
import NewsLetter from "../common/newLetter";
import Footer from "../common/footer";

class Consultacncy extends Component {
  state = {
    location: "india",
    course: "colleges",
    school: "location",
    distanceEdu: "india",
    distanceEduCourse: "india",
    cityCourse: "course",
  };

  settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    infinite: true,
    centerMode: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          adaptiveHeight: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          adaptiveHeight: false,
        },
      },
    ],
  };

  settings2 = {
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    infinite: true,
    centerMode: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          adaptiveHeight: false,
        },
      },
    ],
  };

  render() {
    return (
      <>
        <Header page="consultancy" />
        <div id="main">
          <Hero page="consultancy" />
          <div class="content-container">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="heading">
                    <h3>Find Consultant By Categories</h3>
                    <div class="heading_shap"></div>
                  </div>
                  <div class="grid-container">
                    <ul class="grid-list square type_2 d-flex flex-wrap">
                      <li class="grid-item">
                        <a href="#" class="grid-box">
                          <div class="grid-content">
                            <div class="grid-img">
                              <img src={findConsult} alt="" />
                            </div>
                            <h5>
                              Find
                              <br /> Consultant
                            </h5>
                          </div>
                        </a>
                      </li>
                      <li class="grid-item">
                        <a href="#" class="grid-box">
                          <div class="grid-content">
                            <div class="grid-img">
                              <img src={overseasConsult} alt="" />
                            </div>
                            <h5>
                              Overseas
                              <br /> Consultant
                            </h5>
                          </div>
                        </a>
                      </li>
                      <li class="grid-item">
                        <a href="#" class="grid-box">
                          <div class="grid-content">
                            <div class="grid-img">
                              <img src={iconSchool} alt="" />
                            </div>
                            <h5>
                              School
                              <br /> Consultant
                            </h5>
                          </div>
                        </a>
                      </li>
                      <li class="grid-item">
                        <a href="#" class="grid-box">
                          <div class="grid-content">
                            <div class="grid-img">
                              <img src={addConsult} alt="" />
                            </div>
                            <h5>
                              Add Your <br /> Consultant
                            </h5>
                          </div>
                        </a>
                      </li>
                      <li class="grid-item">
                        <a href="#" class="grid-box">
                          <div class="grid-content">
                            <div class="grid-img">
                              <img src={reviewConsult} alt="" />
                            </div>
                            <h5>
                              Review <br />& Rating
                            </h5>
                          </div>
                        </a>
                      </li>
                      <li class="grid-item">
                        <a href="#" class="grid-box">
                          <div class="grid-content">
                            <div class="grid-img">
                              <img src={distEduConsult} alt="" />
                            </div>
                            <h5>
                              Distance Edu.
                              <br /> Consultant
                            </h5>
                          </div>
                        </a>
                      </li>
                      <li class="grid-item">
                        <a href="#" class="grid-box">
                          <div class="grid-content">
                            <div class="grid-img">
                              <img src={visaConsult} alt="" />
                            </div>
                            <h5>
                              Visa <br /> Consultant
                            </h5>
                          </div>
                        </a>
                      </li>
                      <li class="grid-item">
                        <a href="#" class="grid-box">
                          <div class="grid-content">
                            <div class="grid-img">
                              <img src={placementConsult} alt="" />
                            </div>
                            <h5>
                              Placement <br /> Consultant
                            </h5>
                          </div>
                        </a>
                      </li>
                      <li class="grid-item">
                        <a href="#" class="grid-box">
                          <div class="grid-content">
                            <div class="grid-img">
                              <img src={jobConsult} alt="" />
                            </div>
                            <h5>
                              Job <br /> Consultant
                            </h5>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="show-more-btn text-center">
                    <a href="#" class="btn btn-default">
                      Show More <img src={arrow1} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container btn-tabs-container grey-bg1">
            <div class="container container1">
              <div class="row">
                <div class="col-md-12">
                  <div class="heading blue-heading">
                    <h3>Find Consultant by Location</h3>
                    <div class="heading_shap"></div>
                    <p>Find Consultant In india & Abroad</p>
                  </div>
                  <div class="merit-tabs">
                    <ul class="nav nav-tabs bg_red" id="ex1" role="tablist">
                      <li class="nav-item" role="presentation">
                        <a
                          class={
                            this.state.location === "india"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="ex1-tab-8"
                          data-mdb-toggle="tab"
                          href="#ex1-tabs-8"
                          role="tab"
                          aria-controls="ex1-tabs-8"
                          aria-selected="true"
                          onClick={(e) => {
                            if (e) e.preventDefault();
                            this.setState({ location: "india" });
                          }}
                        >
                          India
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          class={
                            this.state.location === "abroad"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="ex1-tab-9"
                          data-mdb-toggle="tab"
                          href="#ex1-tabs-9"
                          role="tab"
                          aria-controls="ex1-tabs-9"
                          aria-selected="false"
                          onClick={(e) => {
                            if (e) e.preventDefault();
                            this.setState({ location: "abroad" });
                          }}
                        >
                          Abroad
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="meril-tab-body">
                    <div class="tab-content" id="ex1-content">
                      <div
                        class={
                          this.state.location === "india"
                            ? "tab-pane fade active show"
                            : "tab-pane fade"
                        }
                        id="ex1-tabs-8"
                        role="tabpane8"
                        aria-labelledby="ex1-tab-8"
                      >
                        <div class="btn-tabs-container">
                          <div class="btn-tabs-box type_2">
                            <a href="#" class="btn-tabs-link">
                              Uttar Pradesh
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Madhya Pradesh
                            </a>
                            <a href="#" class="btn-tabs-link">
                              West Benga
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Andhra Pradesh
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Maharashtra
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Rajasthan
                            </a>
                            <a href="#" class="btn-tabs-link">
                              NewDelhi
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Tamil Nadu
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Karnataka
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Kerala
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Uttarakhand
                            </a>
                          </div>
                          <div class="show-more-btn text-center">
                            <a href="#" class="btn btn-primary">
                              Show More <img src={arrow1} alt="" />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        class={
                          this.state.location === "abroad"
                            ? "tab-pane fade active show"
                            : "tab-pane fade"
                        }
                        id="ex1-tabs-9"
                        role="tabpane9"
                        aria-labelledby="ex1-tab-9"
                      >
                        <div class="btn-tabs-container">
                          <div class="btn-tabs-box type_2">
                            <a href="#" class="btn-tabs-link">
                              USA
                            </a>
                            <a href="#" class="btn-tabs-link">
                              UK
                            </a>
                            <a href="#" class="btn-tabs-link">
                              UAE
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Germany
                            </a>
                            <a href="#" class="btn-tabs-link">
                              France
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Italy
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Canada
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Singapore
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Australia
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Russia
                            </a>
                            <a href="#" class="btn-tabs-link">
                              China
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Ukraine
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Nepal
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Bangladesh
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Phillipines
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Krzystan
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Kazakhastan
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Uzbekistan
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Japan
                            </a>
                            <a href="#" class="btn-tabs-link">
                              New Zealand
                            </a>
                          </div>
                          <div class="show-more-btn text-center">
                            <a href="#" class="btn btn-primary">
                              Show More <img src={arrow1} alt="" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="heading ">
                    <h3>Find Consultant By Course</h3>
                    <div class="heading_shap"></div>
                    <p>Find Consultant Course In India & Abroad</p>
                  </div>
                  <div class="merit-tabs">
                    <ul
                      class="nav nav-tabs with-shadow"
                      id="ex1"
                      role="tablist"
                    >
                      <li class="nav-item" role="presentation">
                        <a
                          class={
                            this.state.course === "colleges"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="ex1-tab-10"
                          data-mdb-toggle="tab"
                          href="#ex1-tabs-10"
                          role="tab"
                          aria-controls="ex1-tabs-10"
                          aria-selected="true"
                          onClick={(e) => {
                            if (e) e.preventDefault();
                            this.setState({ course: "colleges" });
                          }}
                        >
                          Colleges
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          class={
                            this.state.course === "exams"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="ex1-tab-11"
                          data-mdb-toggle="tab"
                          href="#ex1-tabs-11"
                          role="tab"
                          aria-controls="ex1-tabs-11"
                          aria-selected="false"
                          onClick={(e) => {
                            if (e) e.preventDefault();
                            this.setState({ course: "exams" });
                          }}
                        >
                          Exams
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="meril-tab-body">
                    <div class="tab-content" id="ex1-content">
                      <div
                        class={
                          this.state.course === "colleges"
                            ? "tab-pane fade active show"
                            : "tab-pane fade"
                        }
                        id="ex1-tabs-10"
                        role="tabpane10"
                        aria-labelledby="ex1-tab-10"
                      >
                        <ul class="grid-list square type_3 d-flex flex-wrap">
                          <li class="grid-item">
                            <a href="#" class="grid-box">
                              <div class="grid-content">
                                <div class="grid-img">
                                  <img src={iconMang} alt="" />
                                </div>
                                <h4>Management</h4>
                              </div>
                            </a>
                          </li>
                          <li class="grid-item">
                            <a href="#" class="grid-box">
                              <div class="grid-content">
                                <div class="grid-img">
                                  <img src={iconEng} alt="" />
                                </div>
                                <h4>Engineering</h4>
                              </div>
                            </a>
                          </li>
                          <li class="grid-item">
                            <a href="#" class="grid-box">
                              <div class="grid-content">
                                <div class="grid-img">
                                  <img src={iconMedical} alt="" />
                                </div>
                                <h4>Medical</h4>
                              </div>
                            </a>
                          </li>
                          <li class="grid-item">
                            <a href="#" class="grid-box">
                              <div class="grid-content">
                                <div class="grid-img">
                                  <img src={iconScience} alt="" />
                                </div>
                                <h4>Science</h4>
                              </div>
                            </a>
                          </li>
                          <li class="grid-item">
                            <a href="#" class="grid-box">
                              <div class="grid-content">
                                <div class="grid-img">
                                  <img src={iconArt} alt="" />
                                </div>
                                <h4>Arts</h4>
                              </div>
                            </a>
                          </li>
                          <li class="grid-item">
                            <a href="#" class="grid-box">
                              <div class="grid-content">
                                <div class="grid-img">
                                  <img src={iconArchi} alt="" />
                                </div>
                                <h4>Architecture</h4>
                              </div>
                            </a>
                          </li>
                          <li class="grid-item">
                            <a href="#" class="grid-box">
                              <div class="grid-content">
                                <div class="grid-img">
                                  <img src={iconPhar} alt="" />
                                </div>
                                <h4>Pharmacy</h4>
                              </div>
                            </a>
                          </li>
                          <li class="grid-item">
                            <a href="#" class="grid-box">
                              <div class="grid-content">
                                <div class="grid-img">
                                  <img src={iconLaw} alt="" />
                                </div>
                                <h4>Law</h4>
                              </div>
                            </a>
                          </li>
                          <li class="grid-item">
                            <a href="#" class="grid-box">
                              <div class="grid-content">
                                <div class="grid-img">
                                  <img src={iconPara} alt="" />
                                </div>
                                <h4>Paramedical</h4>
                              </div>
                            </a>
                          </li>
                          <li class="grid-item">
                            <a href="#" class="grid-box">
                              <div class="grid-content">
                                <div class="grid-img">
                                  <img src={iconDesign} alt="" />
                                </div>
                                <h4>Design</h4>
                              </div>
                            </a>
                          </li>
                        </ul>
                        <div class="show-more-btn text-center">
                          <a href="#" class="btn btn-primary">
                            Show More <img src={arrow1} alt="" />
                          </a>
                        </div>
                      </div>
                      <div
                        class={
                          this.state.course === "exams"
                            ? "tab-pane fade active show"
                            : "tab-pane fade"
                        }
                        id="ex1-tabs-11"
                        role="tabpane11"
                        aria-labelledby="ex1-tab-11"
                      >
                        <div class="btn-tabs-container">
                          <div class="btn-tabs-box type_2">
                            <a href="#" class="btn-tabs-link">
                              NEET UG
                            </a>
                            <a href="#" class="btn-tabs-link">
                              NEET PG
                            </a>
                            <a href="#" class="btn-tabs-link">
                              JEE Mains.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              JEE Advance.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              CAT.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              MAT.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              GMAT.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              GATE.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              COMED-K.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              VITEEE.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              MHCET
                            </a>
                            <a href="#" class="btn-tabs-link">
                              MET.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BITSAT.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              SNAP.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              NMAT
                            </a>
                            <a href="#" class="btn-tabs-link">
                              XAT.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              CMAT
                            </a>
                            <a href="#" class="btn-tabs-link">
                              SRM JEE.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              WB JEE.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Uniguage
                            </a>
                            <a href="#" class="btn-tabs-link">
                              ICAR
                            </a>
                            <a href="#" class="btn-tabs-link">
                              NATA
                            </a>
                          </div>
                          <div class="show-more-btn text-center">
                            <a href="#" class="btn btn-primary">
                              Show More <img src={arrow1} alt="" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container btn-tabs-container grey-bg4">
            <div class="container container1">
              <div class="row">
                <div class="col-md-12">
                  <div class="heading blue-heading">
                    <h3>Find Consultant For School</h3>
                    <div class="heading_shap"></div>
                    <p>Find Consultant For School By Location & Grades</p>
                  </div>
                  <div class="merit-tabs">
                    <ul class="nav nav-tabs bg_red" id="ex1" role="tablist">
                      <li class="nav-item" role="presentation">
                        <a
                          class={
                            this.state.school === "location"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="ex1-tab-12"
                          data-mdb-toggle="tab"
                          href="#ex1-tabs-12"
                          role="tab"
                          aria-controls="ex1-tabs-12"
                          aria-selected="true"
                          onClick={(e) => {
                            if (e) e.preventDefault();
                            this.setState({ school: "location" });
                          }}
                        >
                          Location
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          class={
                            this.state.school === "grades"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="ex1-tab-13"
                          data-mdb-toggle="tab"
                          href="#ex1-tabs-13"
                          role="tab"
                          aria-controls="ex1-tabs-13"
                          aria-selected="false"
                          onClick={(e) => {
                            if (e) e.preventDefault();
                            this.setState({ school: "grades" });
                          }}
                        >
                          Grades
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="meril-tab-body">
                    <div class="tab-content" id="ex1-content">
                      <div
                        class={
                          this.state.school === "location"
                            ? "tab-pane fade active show"
                            : "tab-pane fade"
                        }
                        id="ex1-tabs-12"
                        role="tabpane12"
                        aria-labelledby="ex1-tab-12"
                      >
                        <div class="btn-tabs-container">
                          <div class="btn-tabs-box type_2">
                            <a href="#" class="btn-tabs-link">
                              Uttar Pradesh
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Madhya Pradesh
                            </a>
                            <a href="#" class="btn-tabs-link">
                              West Benga
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Andhra Pradesh
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Maharashtra
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Rajasthan
                            </a>
                            <a href="#" class="btn-tabs-link">
                              NewDelhi
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Tamil Nadu
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Karnataka
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Kerala
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Uttarakhand
                            </a>
                          </div>
                          <div class="show-more-btn text-center">
                            <a href="#" class="btn btn-primary">
                              Show More <img src={arrow1} alt="" />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        class={
                          this.state.school === "grades"
                            ? "tab-pane fade active show"
                            : "tab-pane fade"
                        }
                        id="ex1-tabs-13"
                        role="tabpane13"
                        aria-labelledby="ex1-tab-13"
                      >
                        <div class="btn-tabs-container">
                          <div class="btn-tabs-box type_2">
                            <a href="#" class="btn-tabs-link">
                              Std. 1
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Std. 2
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Std. 3
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Std. 4
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Std. 5
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Std. 6
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Std. 7
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Std. 8
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Std. 9
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Xth
                            </a>
                            <a href="#" class="btn-tabs-link">
                              X1th
                            </a>
                            <a href="#" class="btn-tabs-link">
                              X11th
                            </a>
                          </div>
                          <div class="show-more-btn text-center">
                            <a href="#" class="btn btn-primary">
                              Show More <img src={arrow1} alt="" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="heading">
                    <h3>Overseas Consultant</h3>
                    <div class="heading_shap"></div>
                  </div>
                  <div class="block-container">
                    <ul class="block-list mobile-slider blue-dots d-flex flex-wrap">
                      <li class="block-item">
                        <div class="block-box">
                          <div class="block-content">
                            <div class="block-img">
                              <img src={iconCrf1} alt="" />
                            </div>
                            <h5>Find Insutitute</h5>
                          </div>
                        </div>
                      </li>
                      <li class="block-item">
                        <div class="block-box">
                          <div class="block-content">
                            <div class="block-img">
                              <img src={exam3} alt="" />
                            </div>
                            <h5>Exams</h5>
                          </div>
                        </div>
                      </li>
                      <li class="block-item">
                        <div class="block-box">
                          <div class="block-content">
                            <div class="block-img">
                              <img src={iconApp} alt="" />
                            </div>
                            <h5>Application Process</h5>
                          </div>
                        </div>
                      </li>
                      <li class="block-item">
                        <div class="block-box">
                          <div class="block-content">
                            <div class="block-img">
                              <img src={iconAdms2} alt="" />
                            </div>
                            <h5>Addmission</h5>
                          </div>
                        </div>
                      </li>
                      <li class="block-item">
                        <div class="block-box">
                          <div class="block-content">
                            <div class="block-img">
                              <img src={profileEval} alt="" />
                            </div>
                            <h5>Profile Evaluation</h5>
                          </div>
                        </div>
                      </li>
                      <li class="block-item">
                        <div class="block-box">
                          <div class="block-content">
                            <div class="block-img">
                              <img src={visa} alt="" />
                            </div>
                            <h5>Visa & Passport</h5>
                          </div>
                        </div>
                      </li>
                      <li class="block-item">
                        <div class="block-box">
                          <div class="block-content">
                            <div class="block-img">
                              <img src={travel} alt="" />
                            </div>
                            <h5>Travel & Stay</h5>
                          </div>
                        </div>
                      </li>
                      <li class="block-item">
                        <div class="block-box">
                          <div class="block-content">
                            <div class="block-img">
                              <img src={iconCrf4} alt="" />
                            </div>
                            <h5>Scholership & Financial Aid</h5>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div class="text-center">
                      <a href="#" class="btn btn-default">
                        Find Counsultant
                        <img src={arrow1} alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container add-your-consultancy grey-bg1">
            <div class="container">
              <div class="row">
                <div class="col-lg-8 offset-lg-2">
                  <div class="heading blue-heading">
                    <h3>Add Your Consultancy</h3>
                    <div class="heading_shap"></div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="organisation-content-container">
                    <div class="organisation-page-ink-content-outer">
                      <div class="organisation-page-link-list">
                        <div class="opl-item">
                          <a href="#!" class="opl-box">
                            <div class="opl-conetnt">
                              <div class="opl-img">
                                <img src={whatWeDo} alt="" />
                              </div>
                              <h6>What we do ?</h6>
                              <p>
                                {" "}
                                What do you think is better to receive after
                                each lesson: a lovely looking .
                              </p>
                              <span class="opl-link">Read more</span>
                            </div>
                          </a>
                        </div>
                        <div class="opl-item">
                          <a href="#!" class="opl-box">
                            <div class="opl-conetnt">
                              <div class="opl-img">
                                <img src={whyUs} alt="" />
                              </div>
                              <h6>Why with us ?</h6>
                              <p>
                                {" "}
                                What do you think is better to receive after
                                each lesson: a lovely looking .
                              </p>
                              <span class="opl-link">Read more</span>
                            </div>
                          </a>
                        </div>
                        <div class="opl-item">
                          <a href="#!" class="opl-box">
                            <div class="opl-conetnt">
                              <div class="opl-img">
                                <img src={howwork} alt="" />
                              </div>
                              <h6>How does it work</h6>
                              <p>
                                {" "}
                                What do you think is better to receive after
                                each lesson: a lovely looking .
                              </p>
                              <span class="opl-link">Read more</span>
                            </div>
                          </a>
                        </div>
                        <div class="opl-item">
                          <a href="#!" class="opl-box">
                            <div class="opl-conetnt">
                              <div class="opl-img">
                                <img src={cconatctUs} alt="" />
                              </div>
                              <h6> Contact Us</h6>
                              <p>
                                {" "}
                                What do you think is better to receive after
                                each lesson: a lovely looking .
                              </p>
                              <span class="opl-link">Read more</span>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="organisation-video-content">
                      <div class="organisation-video-box">
                        <div class="ratio ratio-16x9">
                          <iframe
                            src="https://www.youtube.com/embed/vlDzYIIOYmM"
                            title="YouTube video"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                      <div class="organisation-video-caption">
                        <img src={youtubeplay} alt="" />
                        <h6> STDUY CONSULTANCY</h6>
                      </div>
                      <div class="organisation-video-btn text-center">
                        <a href="#" class="btn btn-default">
                          <img src={iconPlus} alt="" />
                          List Institue / Business ...
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container btn-tabs-container">
            <div class="container container1">
              <div class="row">
                <div class="col-md-12">
                  <div class="heading">
                    <h3>Find Consultant For Distance Education</h3>
                    <div class="heading_shap"></div>
                  </div>
                  <div class="merit-tabs">
                    <ul
                      class="nav nav-tabs with-shadow"
                      id="ex1"
                      role="tablist"
                    >
                      <li class="nav-item" role="presentation">
                        <a
                          class={
                            this.state.distanceEdu === "india"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="ex1-tab-14"
                          data-mdb-toggle="tab"
                          href="#ex1-tabs-14"
                          role="tab"
                          aria-controls="ex1-tabs-14"
                          aria-selected="true"
                          onClick={(e) => {
                            if (e) e.preventDefault();
                            this.setState({ distanceEdu: "india" });
                          }}
                        >
                          India
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          class={
                            this.state.distanceEdu === "abroad"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="ex1-tab-15"
                          data-mdb-toggle="tab"
                          href="#ex1-tabs-15"
                          role="tab"
                          aria-controls="ex1-tabs-15"
                          aria-selected="false"
                          onClick={(e) => {
                            if (e) e.preventDefault();
                            this.setState({ distanceEdu: "abroad" });
                          }}
                        >
                          Abroad
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="meril-tab-body">
                    <div class="tab-content" id="ex1-content">
                      <div
                        class={
                          this.state.distanceEdu === "india"
                            ? "tab-pane fade active show"
                            : "tab-pane fade"
                        }
                        id="ex1-tabs-14"
                        role="tabpane14"
                        aria-labelledby="ex1-tab-14"
                      >
                        <div class="btn-tabs-container">
                          <div class="btn-tabs-box type_2">
                            <a href="#" class="btn-tabs-link">
                              Uttar Pradesh
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Madhya Pradesh
                            </a>
                            <a href="#" class="btn-tabs-link">
                              West Benga
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Andhra Pradesh
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Maharashtra
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Rajasthan
                            </a>
                            <a href="#" class="btn-tabs-link">
                              NewDelhi
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Tamil Nadu
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Karnataka
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Kerala
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Uttarakhand
                            </a>
                          </div>
                          <div class="show-more-btn text-center">
                            <a href="#" class="btn btn-default">
                              Show More <img src={arrow1} alt="" />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        class={
                          this.state.distanceEdu === "abroad"
                            ? "tab-pane fade active show"
                            : "tab-pane fade"
                        }
                        id="ex1-tabs-15"
                        role="tabpane15"
                        aria-labelledby="ex1-tab-15"
                      >
                        <div class="btn-tabs-container">
                          <div class="btn-tabs-box type_2">
                            <a href="#" class="btn-tabs-link">
                              USA
                            </a>
                            <a href="#" class="btn-tabs-link">
                              UK
                            </a>
                            <a href="#" class="btn-tabs-link">
                              UAE
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Germany
                            </a>
                            <a href="#" class="btn-tabs-link">
                              France
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Italy
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Canada
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Singapore
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Australia
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Russia
                            </a>
                            <a href="#" class="btn-tabs-link">
                              China
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Ukraine
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Nepal
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Bangladesh
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Phillipines
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Krzystan
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Kazakhastan
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Uzbekistan
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Japan
                            </a>
                            <a href="#" class="btn-tabs-link">
                              New Zealand
                            </a>
                          </div>
                          <div class="show-more-btn text-center">
                            <a href="#" class="btn btn-default">
                              Show More <img src={arrow1} alt="" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container btn-tabs-container grey-bg4">
            <div class="container container1">
              <div class="row">
                <div class="col-md-12">
                  <div class="heading blue-heading">
                    <h3>Find Consultant For Distance Education</h3>
                    <div class="heading_shap"></div>
                  </div>
                  <div class="merit-tabs">
                    <ul class="nav nav-tabs bg_red" id="ex1" role="tablist">
                      <li class="nav-item" role="presentation">
                        <a
                          class={
                            this.state.distanceEduCourse === "india"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="ex1-tab-16"
                          data-mdb-toggle="tab"
                          href="#ex1-tabs-16"
                          role="tab"
                          aria-controls="ex1-tabs-16"
                          aria-selected="true"
                          onClick={(e) => {
                            if (e) e.preventDefault();
                            this.setState({ distanceEduCourse: "india" });
                          }}
                        >
                          India
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          class={
                            this.state.distanceEduCourse === "abroad"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="ex1-tab-17"
                          data-mdb-toggle="tab"
                          href="#ex1-tabs-17"
                          role="tab"
                          aria-controls="ex1-tabs-17"
                          aria-selected="false"
                          onClick={(e) => {
                            if (e) e.preventDefault();
                            this.setState({ distanceEduCourse: "abroad" });
                          }}
                        >
                          Abroad
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="meril-tab-body">
                    <div class="tab-content" id="ex1-content">
                      <div
                        class={
                          this.state.distanceEduCourse === "india"
                            ? "tab-pane fade active show"
                            : "tab-pane fade"
                        }
                        id="ex1-tabs-16"
                        role="tabpane16"
                        aria-labelledby="ex1-tab-16"
                      >
                        <div class="btn-tabs-container">
                          <div class="btn-tabs-box">
                            <a href="#" class="btn-tabs-link">
                              NEET - UG
                            </a>
                            <a href="#" class="btn-tabs-link">
                              NEET-PG
                            </a>
                            <a href="#" class="btn-tabs-link">
                              JEE Main
                            </a>
                            <a href="#" class="btn-tabs-link">
                              JEE ADV
                            </a>
                            <a href="#" class="btn-tabs-link">
                              GATE
                            </a>
                            <a href="#" class="btn-tabs-link">
                              CAT
                            </a>
                            <a href="#" class="btn-tabs-link">
                              C-MAT
                            </a>
                            <a href="#" class="btn-tabs-link">
                              XAT
                            </a>
                            <a href="#" class="btn-tabs-link">
                              CLAT
                            </a>
                            <a href="#" class="btn-tabs-link">
                              VITEE
                            </a>
                            <a href="#" class="btn-tabs-link">
                              SET
                            </a>
                            <a href="#" class="btn-tabs-link">
                              NMAT
                            </a>
                            <a href="#" class="btn-tabs-link">
                              NPAT
                            </a>
                            <a href="#" class="btn-tabs-link">
                              WE JEE
                            </a>
                            <a href="#" class="btn-tabs-link">
                              IBSAT
                            </a>
                            <a href="#" class="btn-tabs-link">
                              MAHCET
                            </a>
                          </div>
                          <div class="show-more-btn text-center">
                            <a href="#" class="btn btn-primary">
                              Show More <img src={arrow1} alt="" />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        class={
                          this.state.distanceEduCourse === "abroad"
                            ? "tab-pane fade active show"
                            : "tab-pane fade"
                        }
                        id="ex1-tabs-17"
                        role="tabpane17"
                        aria-labelledby="ex1-tab-17"
                      >
                        <div class="btn-tabs-container">
                          <div class="btn-tabs-box">
                            <a href="#" class="btn-tabs-link">
                              USA
                            </a>
                            <a href="#" class="btn-tabs-link">
                              UK
                            </a>
                            <a href="#" class="btn-tabs-link">
                              UAE
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Germany
                            </a>
                            <a href="#" class="btn-tabs-link">
                              France
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Italy
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Canada
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Singapore
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Australia
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Russia
                            </a>
                            <a href="#" class="btn-tabs-link">
                              China
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Ukraine
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Nepal
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Bangladesh
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Phillipines
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Krzystan
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Kazakhastan
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Uzbekistan
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Japan
                            </a>
                            <a href="#" class="btn-tabs-link">
                              New Zealand
                            </a>
                          </div>
                          <div class="show-more-btn text-center">
                            <a href="#" class="btn btn-primary">
                              Show More <img src={arrow1} alt="" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container">
            <div class="container container2">
              <div class="row">
                <div class="col-lg-12">
                  <div class="heading">
                    <h3>Top Verified Consultants</h3>
                    <div class="heading_shap"></div>
                  </div>
                  <div class="verified-container">
                    <Slider
                      {...this.settings}
                      className="verified-list verified-slider"
                    >
                      <li class="verified-item">
                        <a href="#!" class="verified-box">
                          <div class="verified-img">
                            <img src={school1} alt="" />
                          </div>
                          <span class="btn">
                            Learn more <img src={arrow1} alt="" />
                          </span>
                        </a>
                      </li>
                      <li class="verified-item">
                        <a href="#!" class="verified-box">
                          <div class="verified-img">
                            <img src={school2} alt="" />
                          </div>
                          <span class="btn">
                            Learn more <img src={arrow1} alt="" />
                          </span>
                        </a>
                      </li>
                      <li class="verified-item">
                        <a href="#!" class="verified-box">
                          <div class="verified-img">
                            <img src={school3} alt="" />
                          </div>
                          <span class="btn">
                            Learn more <img src={arrow1} alt="" />
                          </span>
                        </a>
                      </li>
                      <li class="verified-item">
                        <a href="#!" class="verified-box">
                          <div class="verified-img">
                            <img src={school2} alt="" />
                          </div>
                          <span class="btn">
                            Learn more <img src={arrow1} alt="" />
                          </span>
                        </a>
                      </li>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container btn-tabs-container grey-bg">
            <div class="container container1">
              <div class="row">
                <div class="col-md-12">
                  <div class="heading blue-heading">
                    <h3>Find Consultant By Top Cities & Course</h3>
                    <div class="heading_shap"></div>
                  </div>
                  <div class="merit-tabs">
                    <ul class="nav nav-tabs bg_red" id="ex1" role="tablist">
                      <li class="nav-item" role="presentation">
                        <a
                          class={
                            this.state.cityCourse === "course"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="ex1-tab-18"
                          data-mdb-toggle="tab"
                          href="#ex1-tabs-18"
                          role="tab"
                          aria-controls="ex1-tabs-18"
                          aria-selected="true"
                          onClick={(e) => {
                            if (e) e.preventDefault();
                            this.setState({ cityCourse: "course" });
                          }}
                        >
                          Course
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          class={
                            this.state.cityCourse === "exam"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="ex1-tab-19"
                          data-mdb-toggle="tab"
                          href="#ex1-tabs-19"
                          role="tab"
                          aria-controls="ex1-tabs-19"
                          aria-selected="false"
                          onClick={(e) => {
                            if (e) e.preventDefault();
                            this.setState({ cityCourse: "exam" });
                          }}
                        >
                          Exam
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="meril-tab-body">
                    <div class="tab-content" id="ex1-content">
                      <div
                        class={
                          this.state.cityCourse === "course"
                            ? "tab-pane fade active show"
                            : "tab-pane fade"
                        }
                        id="ex1-tabs-18"
                        role="tabpane18"
                        aria-labelledby="ex1-tab-18"
                      >
                        <div class="btn-tabs-container">
                          <div class="btn-tabs-box">
                            <a href="#" class="btn-tabs-link">
                              BE/B.tech
                            </a>
                            <a href="#" class="btn-tabs-link">
                              PGDM
                            </a>
                            <a href="#" class="btn-tabs-link">
                              B.Com
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BA
                            </a>
                            <a href="#" class="btn-tabs-link">
                              B.PHA
                            </a>
                            <a href="#" class="btn-tabs-link">
                              LLB
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BSC
                            </a>
                            <a href="#" class="btn-tabs-link">
                              GNM
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BUMS
                            </a>
                            <a href="#" class="btn-tabs-link">
                              MBBS
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BBA
                            </a>
                            <a href="#" class="btn-tabs-link">
                              B.Arch
                            </a>
                            <a href="#" class="btn-tabs-link">
                              MBA
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BHMS
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BVSc
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BHA
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BHM
                            </a>
                          </div>
                          <div class="show-more-btn text-center">
                            <a href="#" class="btn btn-primary">
                              See More <img src={arrow1} alt="" />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        class={
                          this.state.cityCourse === "exam"
                            ? "tab-pane fade active show"
                            : "tab-pane fade"
                        }
                        id="ex1-tabs-19"
                        role="tabpane19"
                        aria-labelledby="ex1-tab-19"
                      >
                        <div class="btn-tabs-container">
                          <div class="btn-tabs-box type_2">
                            <a href="#" class="btn-tabs-link">
                              NEET UG
                            </a>
                            <a href="#" class="btn-tabs-link">
                              NEET PG
                            </a>
                            <a href="#" class="btn-tabs-link">
                              JEE Mains.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              JEE Advance.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              CAT.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              MAT.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              GMAT.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              GATE.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              COMED-K.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              VITEEE.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              MHCET
                            </a>
                            <a href="#" class="btn-tabs-link">
                              MET.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BITSAT.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              SNAP.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              NMAT
                            </a>
                            <a href="#" class="btn-tabs-link">
                              XAT.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              CMAT
                            </a>
                            <a href="#" class="btn-tabs-link">
                              SRM JEE.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              WB JEE.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Uniguage
                            </a>
                            <a href="#" class="btn-tabs-link">
                              ICAR
                            </a>
                            <a href="#" class="btn-tabs-link">
                              NATA
                            </a>
                          </div>
                          <div class="show-more-btn text-center">
                            <a href="#" class="btn btn-primary">
                              Show More <img src={arrow1} alt="" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container testmonials-container">
            <div class="container container1">
              <div class="row">
                <div class="col-md-12">
                  <div class="heading">
                    <h3>Reviews</h3>
                    <div class="heading_shap"></div>
                  </div>
                  <div class="testmonials-container">
                    <Slider
                      {...this.settings2}
                      className="testmonials-list testmonials-slider pb-0"
                    >
                      <li class="testmonials-item">
                        <div class="testmonials-box">
                          <div class="testimonials-meta">
                            <p>
                              <strong>Johan marti</strong>Warsaw, Poland
                            </p>
                          </div>
                          <div class="testmonials-content">
                            <div class="testmonials-icon">
                              <img src={iconTesti} alt="" />
                            </div>
                            <p>
                              MeritBell.com is an extensive search engine for
                              the students, parents, and education industry
                              players who are seeking information and good
                              website for study information.
                            </p>
                          </div>
                        </div>
                      </li>
                      <li class="testmonials-item">
                        <div class="testmonials-box">
                          <div class="testimonials-meta">
                            <p>
                              <strong>Viezh Robert</strong>Warsaw, Poland
                            </p>
                          </div>
                          <div class="testmonials-content">
                            <div class="testmonials-icon">
                              <img src={iconTesti} alt="" />
                            </div>
                            <p>
                              MeritBell.com is an extensive search engine for
                              the students, parents, and education industry
                              players who are seeking information and good
                              website for study information.
                            </p>
                          </div>
                        </div>
                      </li>
                      <li class="testmonials-item">
                        <div class="testmonials-box">
                          <div class="testimonials-meta">
                            <p>
                              <strong>Marcus Tone</strong>Warsaw, Poland
                            </p>
                          </div>
                          <div class="testmonials-content">
                            <div class="testmonials-icon">
                              <img src={iconTesti} alt="" />
                            </div>
                            <p>
                              MeritBell.com is an extensive search engine for
                              the students, parents, and education industry
                              players who are seeking information and good
                              website for study information.
                            </p>
                          </div>
                        </div>
                      </li>
                      <li class="testmonials-item">
                        <div class="testmonials-box">
                          <div class="testimonials-meta">
                            <p>
                              <strong>Johan marti</strong>Warsaw, Poland
                            </p>
                          </div>
                          <div class="testmonials-content">
                            <div class="testmonials-icon">
                              <img src={iconTesti} alt="" />
                            </div>
                            <p>
                              MeritBell.com is an extensive search engine for
                              the students, parents, and education industry
                              players who are seeking information and good
                              website for study information.
                            </p>
                          </div>
                        </div>
                      </li>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <NewsLetter />
          <Footer />
        </div>
      </>
    );
  }
}
export default Consultacncy;
