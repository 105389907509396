import React, { useState } from "react";

//libs
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBNavbar,
  MDBContainer,
  MDBNavbarToggler,
  MDBCollapse,
  MDBTooltip,
} from "mdb-react-ui-kit";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

//images
import download from "../../include/images/download.svg";
import iconPlus from "../../include/images/icon-plus.svg";

//Componenet
import Logo from "./logo";
import { useEffect } from "react";
import ListBusiness from "./modals/listBusiness";

const Header = (props) => {
  //props
  const {
    setToggleCat,
    toggleCat,
    setType,
    // toggleBusiness,
    // setToggleBusiness,
  } = props;

  //states
  const [dropDownLang, setDropDownLang] = useState(false);
  const [mobHeader, setMobHeader] = useState(false);
  const [toggleBusiness, setToggleBusiness] = useState(false);
  const navigate = useNavigate();

  const toggleDropDownLang = (e) => {
    if (e) e.preventDefault();
    setDropDownLang(!dropDownLang);
  };

  const toggleMobHeader = (e) => {
    if (e) e.preventDefault();
    setMobHeader(!mobHeader);
  };

  useEffect(() => {
    if (mobHeader) {
      document.body.classList.add("sidebar-open");
    } else {
      document.body.classList.remove("sidebar-open");
    }
  }, [mobHeader]);

  return (
    <>
      <header class="header" id="header">
        <div class="header-upper d-none d-xl-block">
          <div class="container">
            <div class="navbar">
              <ul class="header-menu-top">
                <li class="menu-item">
                  <a href="#" class="menu-link active">
                    <b>Top Coupons</b>
                  </a>
                </li>
                <li class="menu-item">
                  <a href="#" class="menu-link">
                    <b> FAQ</b>
                  </a>
                </li>
                <li class="menu-item">
                  <a href="#" class="menu-link">
                    <b> Refer Friends</b>
                  </a>
                </li>
                <li class="menu-item">
                  <a href="#" class="menu-link">
                    <b>Blog</b>
                  </a>
                </li>
                <li class="menu-item">
                  <a href="#" class="menu-link">
                    <b>Common Registration Form</b>
                  </a>
                </li>
                <li class="menu-item">
                  <a href="#" class="menu-link">
                    <b>News</b>
                  </a>
                </li>
                <li class="menu-item">
                  <a href="#" class="menu-link">
                    <b>Helpline</b>
                  </a>
                </li>
                <li class="menu-item heighlighted">
                  <a href="#" class="menu-link">
                    <b>Write a review</b>
                  </a>
                </li>
              </ul>
              <div class="header-upper-btn">
                <div class="dropdown language">
                  <button
                    class={
                      dropDownLang
                        ? "btn btn-secondary dropdown-toggle show active"
                        : "btn btn-secondary dropdown-toggle"
                    }
                    type="button"
                    id="choose_lag"
                    data-mdb-toggle="dropdown"
                    aria-expanded="false"
                    onClick={(e) => toggleDropDownLang(e)}
                  >
                    Eng
                  </button>
                  <ul
                    class={
                      dropDownLang
                        ? "dropdown-menu dropdown-menu-end show"
                        : "dropdown-menu dropdown-menu-end"
                    }
                    aria-labelledby="choose_lag"
                  >
                    <li>
                      <a class="dropdown-item" href="#">
                        Eng
                      </a>
                    </li>
                  </ul>
                </div>
                <a href="#" class="btn">
                  <img src={download} alt="" />
                  Download App
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="header-mid">
          <MDBNavbar class="navbar navbar-expand-xl" expand="xl">
            <MDBContainer class="container position-relative">
              <Logo />
              <MDBNavbarToggler
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarTogglerDemo01"
                aria-controls="navbarTogglerDemo01"
                aria-expanded={mobHeader ? "true" : "false"}
                aria-label="Toggle navigation"
                onClick={(e) => toggleMobHeader(e)}
              >
                <span class="navbar-toggler-icon"></span>
              </MDBNavbarToggler>
              <div class="header-mid-right">
                <Tooltip id="phone-tooltip" />
                <a
                  href="tel:999999999999"
                  class="header-contact"
                  data-tooltip-id="phone-tooltip"
                  data-tooltip-content="999999999999"
                  data-tooltip-place="bottom"
                >
                  <i class="fas fa-phone-alt"></i>
                </a>
                <a href="#" class="btn btn-secondary like_me">
                  <i class="fas fa-heart like"></i>
                  <i class="far fa-heart"></i>
                </a>
                <a href="#" class="add-cart d-none d-md-block">
                  <i class="fas fa-cart-plus"></i>
                </a>
                <MDBDropdown className="d-none d-xl-block">
                  <MDBDropdownToggle
                    className="btn btn-default"
                    type="button"
                    id="dropdownMenuButton"
                    data-mdb-toggle="dropdown"
                    aria-expanded="false"
                    onClick={() => setToggleBusiness(!toggleBusiness)}
                  >
                    <img src={iconPlus} alt="" style={{ width: "21px" }} />
                    <b> add business </b>
                  </MDBDropdownToggle>
                  {/* <MDBDropdownMenu
                    className="dropdown-menu-end"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <MDBDropdownItem
                      link
                      href="javascript:void(0)"
                      onClick={() => navigate("/dashboard")}
                    >
                      List Schools
                    </MDBDropdownItem>

                    <MDBDropdownItem
                      link
                      href="javascript:void(0)"
                      onClick={() => navigate("/dashboard")}
                    >
                      List college/universities
                    </MDBDropdownItem>

                    <MDBDropdownItem
                      link
                      href="javascript:void(0)"
                      onClick={() => navigate("/dashboard")}
                    >
                      List consultancy
                    </MDBDropdownItem>

                    <MDBDropdownItem
                      link
                      href="javascript:void(0)"
                      onClick={() => navigate("/dashboard")}
                    >
                      List PG/Hostels
                    </MDBDropdownItem>

                    <MDBDropdownItem
                      link
                      href="javascript:void(0)"
                      onClick={() => navigate("/dashboard")}
                    >
                      List scholarships
                    </MDBDropdownItem>

                    <MDBDropdownItem
                      link
                      href="javascript:void(0)"
                      onClick={() => navigate("/dashboard")}
                    >
                      List Business
                    </MDBDropdownItem>
                  </MDBDropdownMenu> */}
                </MDBDropdown>
                <MDBDropdown>
                  <MDBDropdownToggle
                    className="btn btn-secondary login"
                    type="button"
                    id="dropdownMenuButton"
                    data-mdb-toggle="dropdown"
                    aria-expanded="false"
                    onClick={() => {
                      setType("login");
                      setToggleCat(!toggleCat);
                    }}
                  >
                    <i class="fas fa-user"></i>
                  </MDBDropdownToggle>
                  {/* <MDBDropdownMenu
                    className="dropdown-menu-end"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <MDBDropdownItem
                      link
                      onClick={() => {
                        setType("login");
                        setToggleCat(!toggleCat);
                      }}
                    >
                      Login
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      link
                      onClick={() => {
                        setType("register");
                        setToggleCat(!toggleCat);
                      }}
                    >
                      Register
                    </MDBDropdownItem>
                  </MDBDropdownMenu> */}
                </MDBDropdown>
              </div>
            </MDBContainer>
          </MDBNavbar>
        </div>
        <div class="header-lower">
          <nav class="navbar navbar-expand-xl">
            <div class="container">
              <MDBCollapse
                show={mobHeader}
                class="collapse navbar-collapse"
                id="navbarTogglerDemo01"
              >
                <div class="navbar-inside">
                  <ul class="navbar-nav">
                    <li class="nav-item">
                      <Link
                        class={
                          props.page === "home" ? "nav-link active" : "nav-link"
                        }
                        aria-current="page"
                        to="/"
                      >
                        <b>Home</b>
                      </Link>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">
                        <b>Schools</b>
                      </a>
                    </li>
                    <li class="nav-item">
                      <Link
                        class={
                          props.page === "college"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/college"
                      >
                        <b>Colleges</b>
                      </Link>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">
                        <b>Courses</b>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">
                        <b>Exams</b>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">
                        <b>Ranking</b>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">
                        <b>Counselling</b>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">
                        <b>Scholorships</b>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">
                        <b>Counselling</b>
                      </a>
                    </li>
                    <li class="nav-item">
                      <Link
                        class={
                          props.page === "consultancy"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/consultant"
                      >
                        <b>Consultant</b>
                      </Link>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">
                        <b>Counselling</b>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">
                        <b>PG/Hostels</b>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">
                        <b>Counselling</b>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">
                        <b>Scholorships</b>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">
                        {" "}
                        <b>more</b>{" "}
                      </a>
                    </li>
                  </ul>
                  <ul class="header-menu-top d-block d-xl-none">
                    <li class="menu-item">
                      <a href="#" class="menu-link">
                        <b>Top Coupons</b>
                      </a>
                    </li>
                    <li class="menu-item">
                      <a href="#" class="menu-link">
                        <b>FAQ</b>
                      </a>
                    </li>
                    <li class="menu-item">
                      <a href="#" class="menu-link">
                        <b>Refer Friends</b>
                      </a>
                    </li>
                    <li class="menu-item">
                      <a href="#" class="menu-link">
                        <b>Write a review</b>
                      </a>
                    </li>
                    <li class="menu-item">
                      <a href="#" class="menu-link">
                        <b>Blog</b>
                      </a>
                    </li>
                    <li class="menu-item">
                      <a href="#" class="menu-link">
                        <b>Common Registration Form</b>
                      </a>
                    </li>
                    <li class="menu-item">
                      <a href="#" class="menu-link">
                        <b>News</b>
                      </a>
                    </li>
                    <li class="menu-item">
                      <a href="#" class="menu-link">
                        <b>Helpline</b>
                      </a>
                    </li>
                  </ul>
                </div>
              </MDBCollapse>
            </div>
          </nav>
        </div>
      </header>
      <ListBusiness
        toggleBusiness={toggleBusiness}
        setToggleBusiness={setToggleBusiness}
      />
    </>
  );
};

export default Header;
