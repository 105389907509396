import React, { Component } from "react";
import { MDBValidation, MDBValidationItem, MDBInput } from "mdb-react-ui-kit";

class Input extends Component {
  state = {
    formclass: "form-group custom-input",
  };

  handleFocus = () => {
    this.setState({ formclass: "form-group custom-input show on" });
  };

  handleBlur = (e) => {
    const classname = e.target.value
      ? "form-group custom-input show on"
      : "form-group custom-input";
    this.setState({ formclass: classname });
  };

  componentDidMount() {
    const classname = this.props.value
      ? "form-group custom-input show on"
      : "form-group custom-input";
    this.setState({ formclass: classname });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      const classname = this.props.value
        ? "form-group custom-input show on"
        : "form-group custom-input";
      this.setState({ formclass: classname });
    }
  }
  render() {
    const { name, label, error, readOnly, onChange, value, type, ...rest } =
      this.props;
    return (
      <div className={this.state.formclass}>
        <MDBInput
          value={value}
          name={name}
          onChange={onChange}
          id={name}
          label={label}
          labelStyle={{
            top: "8px",
          }}
          type={type}
        />
        {error && <label className="error">{error}</label>}
      </div>

      // <div className={this.state.formclass}>
      //   {label !== "" && <label htmlFor={name}>{label}</label>}
      //   <input
      //     name={name}
      //     id={name}
      //     className={error ? "form-control error" : "form-control"}
      //     onFocus={this.handleFocus}
      //     onBlur={this.handleBlur}
      //     readOnly={readOnly}
      //     {...rest}
      //   />
      //   {error && <label className="error">{error}</label>}
      // </div>
    );
  }
}

export default Input;
