import React, { Component } from "react";

//images
import arrow1 from "../../../include/images/arrow-1.svg";

//compoennt
import Form from "../../common/form/form";

class Timing extends Form {
  render() {
    return (
      <>
        <div class="main-container">
          <div class="heading heading-with-btn">
            <h3>Timing & Other Details</h3>
            <div class="heading_shap"></div>
            <a href="#" class="btn btn-default">
              {" "}
              Skip <img src={arrow1} alt="" />{" "}
            </a>
          </div>
          <div class="dashboard-box">
            <div class="row">
              <div class="col-md-12">
                <div class="next-prev-btn d-flex justify-content-between">
                  <a href="#" class="btn btn-default">
                    {" "}
                    <img src={arrow1} alt="" /> Back
                  </a>
                  <a href="#" class="btn btn-default">
                    {" "}
                    Next <img src={arrow1} alt="" />{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Timing;
