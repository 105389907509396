import React, { useEffect, useState } from "react";

//components
import Input from "../common/form/input";
import { Link } from "react-router-dom";
import ReactSelect from "../common/form/reactSelect";

const Register = (props) => {
  const { category, catOptions, type, onChangeType, callingCodes } = props;
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("+91");
  const [contactPersonName, setContactPersonName] = useState("");
  const [designation, setDesignation] = useState(null);
  const [desSchool, setDesSchool] = useState([
    { label: "Principal", value: "principal" },
    { label: "Owner", value: "owner" },
    { label: "Manager", value: "manager" },
    { label: "Admin", value: "admin" },
    { label: "Others", value: "others" },
  ]);
  const [desInstitution, setDesInstitution] = useState([
    { label: "Chairman", value: "chairman" },
    { label: "Principal", value: "principal" },
    { label: "Manager", value: "manager" },
    { label: "Registrar", value: "registrar" },
    { label: "Dean", value: "dean" },
    { label: "Admin", value: "admin" },
    { label: "Others", value: "others" },
  ]);
  const [desConsultant, setDesConsultant] = useState([
    { label: "Owner", value: "owner" },
    { label: "Counsellor", value: "counsellor" },
    { label: "Others", value: "others" },
  ]);
  const [desSeller, setDesSeller] = useState([
    { label: "Owner", value: "owner" },
    { label: "Sales Manager", value: "sales manager" },
    { label: "Others", value: "others" },
  ]);
  const [desCoaching, setDesCoaching] = useState([
    { label: "Owner", value: "owner" },
    { label: "Manager", value: "manager" },
    { label: "Others", value: "others" },
  ]);
  const [desHostel, setDesHostel] = useState([
    { label: "Owner", value: "owner" },
    { label: "Warden", value: "warden" },
    { label: "Manager", value: "manager" },
    { label: "Others", value: "others" },
  ]);
  const [desTutor, setDesTutor] = useState([
    { label: "Tutor", value: "tutor" },
    { label: "Others", value: "others" },
  ]);

  return (
    <>
      <div class="form-container d-flex flex-column align-items-center justify-content-center">
        <h5>Create Account - It's Free !</h5>
        <div class="dashboard-box">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Select Category</label>
                <ReactSelect
                  options={catOptions.sort()}
                  placeholder={"Select Category ..."}
                  value={type}
                  onChange={onChangeType}
                />
              </div>
            </div>
            <div class="col-md-12 position-relative">
              <Input
                name="name"
                label={
                  type?.value === "student"
                    ? "Name"
                    : "Institute/School/Consultant Name"
                }
                value={name}
              />
            </div>
            {type?.value !== "student" && (
              <div class="col-md-12 position-relative">
                <Input
                  name="contactPersonName"
                  label={"Contact Person Name"}
                  value={contactPersonName}
                />
              </div>
            )}
            <div class="col-md-12 position-relative">
              <Input
                name="email"
                label={
                  type?.value === "student" ? "Email" : "Official Email Address"
                }
                value={email}
              />
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Mobile Number</label>
                <div class="select-with-input">
                  <input
                    type="text"
                    class="phone-input form-control"
                    placeholder=""
                  />
                  <select class="form-control" value={phone}>
                    {callingCodes &&
                      Object.values(callingCodes)
                        .sort((a, b) => a - b)
                        .map((code) => (
                          <option key={code}>{`+${code}`}</option>
                        ))}
                  </select>
                </div>
              </div>
            </div>
            {type?.value !== "student" && (
              <div class="col-md-12">
                <div class="form-group">
                  <label>Select Designation</label>
                  <ReactSelect
                    options={
                      type?.value === "pg/hostel"
                        ? desHostel
                        : type?.value === "school"
                        ? desSchool
                        : type?.value === "institution"
                        ? desInstitution
                        : type?.value === "coachingInstitute"
                        ? desCoaching
                        : type?.value === "seller"
                        ? desSeller
                        : type?.value === "homeTutor"
                        ? desTutor
                        : type?.value === "consultant"
                        ? desConsultant
                        : []
                    }
                    placeholder={"Select Designation ..."}
                    value={designation}
                    onChange={(e) => setDesignation(e)}
                  />
                </div>
              </div>
            )}
            <div class="col-md-12 position-relative">
              <Input name="password" label="Create Password" value={password} />
            </div>

            <div class="col-md-12">
              <div class="save-btn text-center">
                <a href="#" class="btn btn-default">
                  Sign up
                </a>
              </div>
            </div>
            <div class="col-md-12">
              <div class="new-to-meritbell text-center mt-4">
                Already have an account ? <Link to="/login">Login</Link>{" "}
              </div>
            </div>
            <div class="or-cont col-md-12">
              <h6 className="or-text position-relative text-center m-0">OR</h6>
            </div>
            <div class="col-md-12 mt-4">
              <div class="comparison-link-content">
                <a href="#" class="btn btn-default btn-block">
                  <div className="plus-container">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 448 512"
                    >
                      <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                    </svg>{" "}
                  </div>
                  List Your Business{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
