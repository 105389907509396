import React, { Component, useState } from "react";
import Header from "../common/header";

//img
import iconSchool from "../../include/images/icon-school.svg";
import iconCollege from "../../include/images/icon-college.svg";
import iconUniv from "../../include/images/icon-university.svg";
import iconAbroad from "../../include/images/icon-study-abroad.svg";
import iconInst from "../../include/images/icon-instittution.svg";
import iconTution from "../../include/images/icon-tution.svg";
import arrow1 from "../../include/images/arrow-1.svg";
import arrow2 from "../../include/images/arrow-2.svg";
import iconCounsel from "../../include/images/icon-e-counseling.svg";
import iconAdms from "../../include/images/icon-admission.svg";
import iconEduFair from "../../include/images/icon-education-fair.svg";
import iconRank from "../../include/images/icon-rank.svg";
import iconPred from "../../include/images/icon-prediction.svg";
import iconPredColl from "../../include/images/icon-college-prediction.svg";
import iconCourse from "../../include/images/icon-courses.svg";
import iconExam from "../../include/images/icon-exam.svg";
import iconDistEdu from "../../include/images/icon-distance-education.svg";
import iconOnlineCourse from "../../include/images/icon-online-course.svg";
import iconGovExM from "../../include/images/icon-gov-exam.svg";
import iconGovJobs from "../../include/images/icon-gov-jobs.svg";
import iconJob from "../../include/images/icon-jobs.svg";
import iconQuiz from "../../include/images/icon-quiz.svg";
import iconBooks from "../../include/images/icon-books.svg";
import iconReview from "../../include/images/icon-review.svg";
import iconRanking from "../../include/images/icon-ranking.svg";
import iconComparison from "../../include/images/icon-comparison.svg";
import iconInternship from "../../include/images/icon-internship.svg";
import iconMock from "../../include/images/icon-mocktest.svg";
import iconMeritVid from "../../include/images/icon-meritbell-videos.svg";
import iconScholar from "../../include/images/icon-scholorship.svg";
import iconEduLoan from "../../include/images/icon-edu-laon.svg";
import iconFellow from "../../include/images/icon-fellowship.svg";
import iconStuMat from "../../include/images/icon-study-marerial.svg";
import iconOldQaPaper from "../../include/images/icon-old-qua-paper.svg";
import iconPlacement from "../../include/images/icon-placement.svg";
import iconNewUpload from "../../include/images/icon-new-&-upload.svg";
import iconBlog from "../../include/images/icon-blogs.svg";
import iconRef from "../../include/images/icon-referal.svg";
import icontestimonial from "../../include/images/icon-testmonial.svg";
import iconSucStory from "../../include/images/icon-sucess-story.svg";
import btnComparison from "../../include/images/btn-camparison2.svg";
import comparoson from "../../include/images/camparison-img.png";
import iconCrf4 from "../../include/images/icon-crf-4.svg";
import iconCrf3 from "../../include/images/icon-crf-3.svg";
import iconCrf2 from "../../include/images/icon-crf-2.svg";
import iconCrf1 from "../../include/images/icon-crf-1.svg";
import whatWeDo from "../../include/images/what-we-do.svg";
import whyUs from "../../include/images/why-us.svg";
import howWork from "../../include/images/how-work.svg";
import contactUs from "../../include/images/contact-us.svg";
import playIcon from "../../include/images/youtube-play-icon.svg";
import iconPlus from "../../include/images/icon-plus.svg";
import schoolImg1 from "../../include/images/school-img1.jpg";
import schoolImg2 from "../../include/images/school-img2.jpg";
import schoolImg3 from "../../include/images/school-img3.jpg";
import school from "../../include/images/school.svg";
import courseType2 from "../../include/images/course-type2.svg";
import courseType3 from "../../include/images/course-type3.svg";
import placement from "../../include/images/placement.svg";
import exploreBtn from "../../include/images/explre-btn-icon.svg";
import applyBtn from "../../include/images/apply-btn-icon.svg";
import examType2 from "../../include/images/exam-type2.svg";
import collegeAdvise from "../../include/images/college-advise-type2.svg";
import career from "../../include/images/career-type2.svg";
import counsalty from "../../include/images/consultancy.svg";
import businessListing from "../../include/images/business-listing.svg";
import applicationForm from "../../include/images/application-form.svg";
import support from "../../include/images/support.svg";
import blogImg1 from "../../include/images/blog-img1.jpg";
import blogCalendar from "../../include/images/blog-calendar.svg";
import postEvent1 from "../../include/images/post-event-1.jpg";
import postEvent2 from "../../include/images/post-event-2.jpg";
import postEvent3 from "../../include/images/post-event-3.jpg";
import recommned from "../../include/images/recomind-img.svg";

//libs
import Slider from "react-slick";
import Footer from "../common/footer";
import Hero from "../common/hero";
import NewsLetter from "../common/newLetter";
import CategorySelector from "../common/modals/categorySelector";
import ListBusiness from "../common/modals/listBusiness";

const Home = (props) => {
  const [course, setCourse] = useState("courses");
  const [newsUpdate, setNewsUpdate] = useState("news");
  const [trending, setTrending] = useState("school");
  const [toggleCat, setToggleCat] = useState(false);
  const [toggleBusiness, setToggleBusiness] = useState(false);
  const [type, setType] = useState("login");

  const setting3 = {
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    infinite: true,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          adaptiveHeight: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          adaptiveHeight: false,
        },
      },
    ],
  };

  const setting4 = {
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    infinite: true,
    centerMode: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          adaptiveHeight: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          adaptiveHeight: false,
        },
      },
    ],
  };
  return (
    <>
      <Header
        page="home"
        toggleCat={toggleCat}
        setToggleCat={setToggleCat}
        toggleBusiness={toggleBusiness}
        setToggleBusiness={setToggleBusiness}
        setType={setType}
      />
      <div id="main">
        <Hero page="home" />
        <div class="content-container your-future-container">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="heading">
                  <h3>Choose Your Future</h3>
                  <div class="heading_shap"></div>
                  <p>Explore What You Need</p>
                </div>
                <div class="grid-container">
                  <ul class="grid-list d-flex flex-wrap">
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconSchool} alt="" />
                          </div>
                          <h6>Schools</h6>
                          <p> 1500+ Listed</p>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconCollege} alt="" />
                          </div>
                          <h6>Colleges</h6>
                          <p> 1310+ Listed</p>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconUniv} alt="" />
                          </div>
                          <h6>University</h6>
                          <p> 1310+ Listed</p>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconAbroad} alt="" />
                          </div>
                          <h6>Study Abroad </h6>
                          <p>2450+ Listed</p>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconInst} alt="" />
                          </div>
                          <h6>Coaching Institutions</h6>
                          <p> 1700+ Listed</p>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconTution} alt="" />
                          </div>
                          <h6>Home Tutision</h6>
                          <p> 600+ Listed</p>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="show-more-btn text-center">
                  <a href="#" class="btn btn-primary">
                    Show More <img src={arrow1} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-container helpdesk-container grey-bg1">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="heading blue-heading">
                  <h3>Student Helpdesk</h3>
                  <div class="heading_shap"></div>
                </div>
                <div class="grid-container">
                  <ul class="grid-list square d-flex flex-wrap">
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconCounsel} alt="" />
                          </div>
                          <h5>E- Counseling</h5>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconAdms} alt="" />
                          </div>
                          <h5>Admission</h5>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconEduFair} alt="" />
                          </div>
                          <h5>Education fair</h5>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconRank} alt="" />
                          </div>
                          <h5>Rank</h5>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconPred} alt="" />
                          </div>
                          <h5>Prediction</h5>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconPredColl} alt="" />
                          </div>
                          <h5>College Prediction</h5>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="show-more-btn text-center">
                  <a href="#" class="btn btn-default">
                    Show More <img src={arrow1} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-container edu-catagory-container">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="heading">
                  <h3>Education Catagories</h3>
                  <div class="heading_shap"></div>
                </div>
                <div class="grid-container">
                  <ul class="grid-list d-flex flex-wrap">
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconCourse} alt="" />
                          </div>
                          <h5>Courses</h5>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconExam} alt="" />
                          </div>
                          <h5>Exams</h5>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconDistEdu} alt="" />
                          </div>
                          <h5>Distance Education</h5>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconOnlineCourse} alt="" />
                          </div>
                          <h5>Online course</h5>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconGovExM} alt="" />
                          </div>
                          <h5>Gov. Exam</h5>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconJob} alt="" />
                          </div>
                          <h5>Jobs</h5>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconBooks} alt="" />
                          </div>
                          <h5>Books</h5>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconGovJobs} alt="" />
                          </div>
                          <h5>Gov. Jobs</h5>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconQuiz} alt="" />
                          </div>
                          <h5>Quiz</h5>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="show-more-btn text-center">
                  <a href="#" class="btn btn-primary">
                    Show More <img src={arrow1} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-container education-service-container grey-bg4">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="heading blue-heading">
                  <h3>Education Services</h3>
                  <div class="heading_shap"></div>
                </div>
                <div class="grid-container">
                  <ul class="grid-list square d-flex flex-wrap">
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconReview} alt="" />
                          </div>
                          <h4>Reviews</h4>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconRanking} alt="" />
                          </div>
                          <h4>Ranking</h4>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconComparison} alt="" />
                          </div>
                          <h4>Comparison</h4>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconInternship} alt="" />
                          </div>
                          <h4>Internship</h4>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconMeritVid} alt="" />
                          </div>
                          <h4>Meritbell Videos</h4>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconMock} alt="" />
                          </div>
                          <h4>Mocktest</h4>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="show-more-btn text-center">
                  <a href="#" class="btn btn-default">
                    Show More <img src={arrow1} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-container review-container">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
                <div class="submit-review-container">
                  <div class="heading heading-white mb-4">
                    <h3>Submit Review Of Your College !</h3>
                    <div class="heading_shap"></div>
                    <p>
                      Rate your college across various parameters like
                      Infrastructure, Events, Industry Exposure Safety. Your
                      opinion matters the most to us.
                    </p>
                  </div>
                  <a href="#" class="btn btn-default">
                    Write a review
                    <img src={arrow1} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-container your-future-container">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="heading">
                  <h3>Education Needs</h3>
                  <div class="heading_shap"></div>
                </div>
                <div class="grid-container">
                  <ul class="grid-list d-flex flex-wrap">
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconScholar} alt="" />
                          </div>
                          <h5>Scholorship</h5>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconEduLoan} alt="" />
                          </div>
                          <h5>Edu. Loan</h5>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconFellow} alt="" />
                          </div>
                          <h5>Fellowship</h5>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconStuMat} alt="" />
                          </div>
                          <h5>Study material</h5>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconOldQaPaper} alt="" />
                          </div>
                          <h5>Old Qua. paper</h5>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconPlacement} alt="" />
                          </div>
                          <h5>Placement</h5>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="show-more-btn text-center">
                  <a href="#" class="btn btn-primary">
                    Show More <img src={arrow1} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-container education-information-container grey-bg1">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="heading blue-heading">
                  <h3>Education information</h3>
                  <div class="heading_shap"></div>
                </div>
                <div class="grid-container eic-container">
                  <ul class="grid-list square mobile-slider d-flex flex-wrap">
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconNewUpload} alt="" />
                          </div>
                          <h5>News & Upload</h5>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconBlog} alt="" />
                          </div>
                          <h5>Blogs</h5>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconSucStory} alt="" />
                          </div>
                          <h5>Success Story</h5>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={iconRef} alt="" />
                          </div>
                          <h5>Referal</h5>
                        </div>
                      </a>
                    </li>
                    <li class="grid-item">
                      <a href="#" class="grid-box">
                        <div class="grid-content">
                          <div class="grid-img">
                            <img src={icontestimonial} alt="" />
                          </div>
                          <h5>Testimonial</h5>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-container comparison-container">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="heading">
                  <h3>Comparison</h3>
                  <div class="heading_shap"></div>
                  <p>Compare School, colleges and Course</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="comparison-img">
                  <img src={comparoson} alt="" />
                </div>
              </div>
              <div class="col-md-6 ">
                <div class="comparison-link-content">
                  <a href="#" class="btn btn-default btn-block">
                    <img src={btnComparison} alt="" /> Compare Colleges{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-container common-registration-form-container">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="heading">
                  <h3>Common Registration Form (CRF)</h3>
                  <div class="heading_shap"></div>
                  <p>
                    1 single form and get access to 600+ colleges across India !
                  </p>
                </div>
                <div class="block-container">
                  <ul class="block-list  mobile-slider blue-dots d-flex flex-wrap">
                    <li class="block-item">
                      <div class="block-box">
                        <div class="block-content">
                          <div class="block-img">
                            <img src={iconCrf1} alt="" />
                          </div>
                          <h5>One platform to apply to 600+ colleges</h5>
                        </div>
                      </div>
                    </li>
                    <li class="block-item">
                      <div class="block-box">
                        <div class="block-content">
                          <div class="block-img">
                            <img src={iconCrf2} alt="" />
                          </div>
                          <h5>
                            Track & manage all your applications in one place
                          </h5>
                        </div>
                      </div>
                    </li>
                    <li class="block-item">
                      <div class="block-box">
                        <div class="block-content">
                          <div class="block-img">
                            <img src={iconCrf3} alt="" />
                          </div>
                          <h5>No need to visit College, Offline forms</h5>
                        </div>
                      </div>
                    </li>
                    <li class="block-item">
                      <div class="block-box">
                        <div class="block-content">
                          <div class="block-img">
                            <img src={iconCrf4} alt="" />
                          </div>
                          <h5>Upto 90% discount on your application fee</h5>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div class="text-center">
                    <a href="#" class="btn btn-default">
                      Start your Application Now
                      <img src={arrow1} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-container organisation-container grey-bg1">
          <div class="container">
            <div class="row">
              <div class="col-lg-8 offset-lg-2">
                <div class="heading blue-heading">
                  <h3>List / Add Your College</h3>
                  <div class="heading_shap"></div>
                  <p>
                    Get your college / institute / university / coaching / PG /
                    Consultancy / Business listed in MeritBell.
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="organisation-content-container">
                  <div class="organisation-page-ink-content-outer">
                    <div class="organisation-page-link-list">
                      <div class="opl-item">
                        <a href="#!" class="opl-box">
                          <div class="opl-conetnt">
                            <div class="opl-img">
                              <img src={whatWeDo} alt="" />
                            </div>
                            <h6>What we do ?</h6>
                            <p>
                              {" "}
                              What do you think is better to receive after each
                              lesson: a lovely looking .
                            </p>
                            <span class="opl-link">Read more</span>
                          </div>
                        </a>
                      </div>
                      <div class="opl-item">
                        <a href="#!" class="opl-box">
                          <div class="opl-conetnt">
                            <div class="opl-img">
                              <img src={whyUs} alt="" />
                            </div>
                            <h6>Why with us ?</h6>
                            <p>
                              {" "}
                              What do you think is better to receive after each
                              lesson: a lovely looking .
                            </p>
                            <span class="opl-link">Read more</span>
                          </div>
                        </a>
                      </div>
                      <div class="opl-item">
                        <a href="#!" class="opl-box">
                          <div class="opl-conetnt">
                            <div class="opl-img">
                              <img src={howWork} alt="" />
                            </div>
                            <h6>How does it work</h6>
                            <p>
                              {" "}
                              What do you think is better to receive after each
                              lesson: a lovely looking .
                            </p>
                            <span class="opl-link">Read more</span>
                          </div>
                        </a>
                      </div>
                      <div class="opl-item">
                        <a href="#!" class="opl-box">
                          <div class="opl-conetnt">
                            <div class="opl-img">
                              <img src={contactUs} alt="" />
                            </div>
                            <h6> Contact Us</h6>
                            <p>
                              {" "}
                              What do you think is better to receive after each
                              lesson: a lovely looking .
                            </p>
                            <span class="opl-link">Read more</span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="organisation-video-content">
                    <div class="organisation-video-box">
                      <div class="ratio ratio-16x9">
                        <iframe
                          src="https://www.youtube.com/embed/vlDzYIIOYmM"
                          title="YouTube video"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                    <div class="organisation-video-caption">
                      <img src={playIcon} alt="" />
                      <h6>THREE HOUR STDUY WITH ME UNIVERSITY</h6>
                    </div>
                    <div class="organisation-video-btn text-center">
                      <a href="#" class="btn btn-default">
                        <img src={iconPlus} alt="" />
                        List Institue / Business ...
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-container finance-education-container">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="heading blue-heading">
                  <h3>Finance Your Education</h3>
                  <div class="heading_shap"></div>
                </div>
                <div class="fe-container">
                  <ul class="fe-list mobile-slider  d-flex flex-wrap">
                    <li class="fe-item">
                      <div class="fe-box">
                        <div class="fe-img">
                          <img src={iconScholar} alt="" />
                        </div>
                        <div class="fe-content">
                          <h5>Scholorship</h5>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been
                          </p>
                          <div class="d-flex justify-content-between mt-5">
                            <a href="#!" class="btn-btn">
                              View more
                            </a>
                            <a href="#!" class="btn-btn">
                              Apply Now
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="fe-item">
                      <div class="fe-box">
                        <div class="fe-img">
                          <img src={iconEduLoan} alt="" />
                        </div>
                        <div class="fe-content">
                          <h5>Education Loan</h5>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been
                          </p>
                          <div class="d-flex justify-content-between mt-5">
                            <a href="#!" class="btn-btn">
                              View more
                            </a>
                            <a href="#!" class="btn-btn">
                              Apply Now
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="fe-item">
                      <div class="fe-box">
                        <div class="fe-img">
                          <img src={iconFellow} alt="" />
                        </div>
                        <div class="fe-content">
                          <h5>Fellowship</h5>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been
                          </p>
                          <div class="d-flex justify-content-between mt-5">
                            <a href="#!" class="btn-btn">
                              View more
                            </a>
                            <a href="#!" class="btn-btn">
                              Apply Now
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-container merilbell-youtube-chanel-container pt-0">
          <div class="container container1">
            <div class="row">
              <div class="col-md-12">
                <div class="heading blue-heading">
                  <h3>MeritBell.com Youtube Channel</h3>
                  <div class="heading_shap"></div>
                </div>
                <div class="meritbell-video-container">
                  <ul class="meritbell-video-list d-flex flex-wrap">
                    <li class="meritbell-video-item">
                      <div class="meritbell-video-box">
                        <div class="ratio ratio-16x9">
                          <iframe
                            src="https://www.youtube.com/embed/vlDzYIIOYmM"
                            title="YouTube video"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                    </li>
                    <li class="meritbell-video-item">
                      <div class="meritbell-video-box">
                        <div class="ratio ratio-16x9">
                          <iframe
                            src="https://www.youtube.com/embed/vlDzYIIOYmM"
                            title="YouTube video"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                    </li>
                    <li class="meritbell-video-item">
                      <div class="meritbell-video-box">
                        <div class="ratio ratio-16x9">
                          <iframe
                            src="https://www.youtube.com/embed/vlDzYIIOYmM"
                            title="YouTube video"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div class="more-video text-center">
                    <a href="#" class="btn btn-default">
                      <i class="fab fa-youtube"></i> more videos
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-container treading-school-college-container grey-bg2">
          <div class="container container1">
            <div class="row">
              <div class="col-md-12">
                <div class="heading blue-heading">
                  <h3>Treading School & Colleges</h3>
                  <div class="heading_shap"></div>
                </div>
                <div class="merit-tabs">
                  <ul class="nav nav-tabs" id="ex1" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a
                        class={
                          trending === "school" ? "nav-link active" : "nav-link"
                        }
                        id="ex1-tab-1"
                        data-mdb-toggle="tab"
                        href="#ex1-tabs-1"
                        role="tab"
                        aria-controls="ex1-tabs-1"
                        aria-selected="true"
                        onClick={(e) => {
                          if (e) e.preventDefault();
                          setTrending("school");
                        }}
                      >
                        School
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class={
                          trending === "college"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        id="ex1-tab-2"
                        data-mdb-toggle="tab"
                        href="#ex1-tabs-2"
                        role="tab"
                        aria-controls="ex1-tabs-2"
                        aria-selected="false"
                        onClick={(e) => {
                          if (e) e.preventDefault();
                          setTrending("college");
                        }}
                      >
                        College
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="meril-tab-body">
                  <div class="tab-content" id="ex1-content">
                    <div
                      class={
                        trending === "school"
                          ? "tab-pane fade active show"
                          : "tab-pane fade"
                      }
                      id="ex1-tabs-1"
                      role="tabpanel"
                      aria-labelledby="ex1-tab-1"
                    >
                      <div class="tsc-container">
                        <Slider
                          {...setting3}
                          className="tsc-list school-info-slider pb-0  d-flex flex-wrap"
                        >
                          <li class="tsc-item">
                            <div class="tsc-box">
                              <div class="tsc-thumb">
                                <img src={schoolImg1} alt="" />
                              </div>
                              <div class="tsc-body">
                                <h5 class="tsc-title">
                                  <img src={school} alt="" />
                                  Er.Perumal Manimekalai College
                                </h5>
                                <div class="tsc-meta">
                                  <span class="tsc-meta-item">
                                    <img src={courseType2} alt="" />
                                    course
                                  </span>
                                  <span class="tsc-meta-item">
                                    <img src={courseType3} alt="" />
                                    course
                                  </span>
                                  <span class="tsc-meta-item">
                                    <img src={placement} alt="" />
                                    Placement
                                  </span>
                                </div>
                                <div class="tsc-btn">
                                  <a href="#" class="btn btn-default">
                                    <img src={exploreBtn} alt="" />
                                    Explore
                                  </a>
                                  <a href="#" class="btn btn-default">
                                    <img src={applyBtn} alt="" />
                                    Apply
                                  </a>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="tsc-item">
                            <div class="tsc-box">
                              <div class="tsc-thumb">
                                <img src={schoolImg2} alt="" />
                              </div>
                              <div class="tsc-body">
                                <h5 class="tsc-title">
                                  <img src={school} alt="" />
                                  Er.Perumal Manimekalai College
                                </h5>
                                <div class="tsc-meta">
                                  <span class="tsc-meta-item">
                                    <img src={courseType2} alt="" />
                                    course
                                  </span>
                                  <span class="tsc-meta-item">
                                    <img src={courseType3} alt="" />
                                    course
                                  </span>
                                  <span class="tsc-meta-item">
                                    <img src={placement} alt="" />
                                    Placement
                                  </span>
                                </div>
                                <div class="tsc-btn">
                                  <a href="#" class="btn btn-default">
                                    <img src={exploreBtn} alt="" />
                                    Explore
                                  </a>
                                  <a href="#" class="btn btn-default">
                                    <img src={applyBtn} alt="" />
                                    Apply
                                  </a>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="tsc-item">
                            <div class="tsc-box">
                              <div class="tsc-thumb">
                                <img src={schoolImg3} alt="" />
                              </div>
                              <div class="tsc-body">
                                <h5 class="tsc-title">
                                  <img src={school} alt="" />
                                  Er.Perumal Manimekalai College
                                </h5>
                                <div class="tsc-meta">
                                  <span class="tsc-meta-item">
                                    <img src={courseType2} alt="" />
                                    course
                                  </span>
                                  <span class="tsc-meta-item">
                                    <img src={courseType3} alt="" />
                                    course
                                  </span>
                                  <span class="tsc-meta-item">
                                    <img src={placement} alt="" />
                                    Placement
                                  </span>
                                </div>
                                <div class="tsc-btn">
                                  <a href="#" class="btn btn-default">
                                    <img src={exploreBtn} alt="" />
                                    Explore
                                  </a>
                                  <a href="#" class="btn btn-default">
                                    <img src={applyBtn} alt="" />
                                    Apply
                                  </a>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="tsc-item">
                            <div class="tsc-box">
                              <div class="tsc-thumb">
                                <img src={schoolImg3} alt="" />
                              </div>
                              <div class="tsc-body">
                                <h5 class="tsc-title">
                                  <img src={school} alt="" />
                                  Er.Perumal Manimekalai College
                                </h5>
                                <div class="tsc-meta">
                                  <span class="tsc-meta-item">
                                    <img src={courseType2} alt="" />
                                    course
                                  </span>
                                  <span class="tsc-meta-item">
                                    <img src={courseType3} alt="" />
                                    course
                                  </span>
                                  <span class="tsc-meta-item">
                                    <img src={placement} alt="" />
                                    Placement
                                  </span>
                                </div>
                                <div class="tsc-btn">
                                  <a href="#" class="btn btn-default">
                                    <img src={exploreBtn} alt="" />
                                    Explore
                                  </a>
                                  <a href="#" class="btn btn-default">
                                    <img src={applyBtn} alt="" />
                                    Apply
                                  </a>
                                </div>
                              </div>
                            </div>
                          </li>
                        </Slider>
                      </div>
                    </div>
                    <div
                      class={
                        trending === "college"
                          ? "tab-pane fade active show"
                          : "tab-pane fade"
                      }
                      id="ex1-tabs-2"
                      role="tabpane1"
                      aria-labelledby="ex1-tab-2"
                    >
                      <div class="tsc-container">
                        <Slider
                          {...setting3}
                          className="tsc-list school-info-slider pb-0  d-flex flex-wrap"
                        >
                          <li class="tsc-item">
                            <div class="tsc-box">
                              <div class="tsc-thumb">
                                <img src={schoolImg1} alt="" />
                              </div>
                              <div class="tsc-body">
                                <h5 class="tsc-title">
                                  <img src={school} alt="" />
                                  Er.Perumal Manimekalai College
                                </h5>
                                <div class="tsc-meta">
                                  <span class="tsc-meta-item">
                                    <img src={courseType2} alt="" />
                                    course
                                  </span>
                                  <span class="tsc-meta-item">
                                    <img src={courseType3} alt="" />
                                    course
                                  </span>
                                  <span class="tsc-meta-item">
                                    <img src={placement} alt="" />
                                    Placement
                                  </span>
                                </div>
                                <div class="tsc-btn">
                                  <a href="#" class="btn btn-default">
                                    <img src={exploreBtn} alt="" />
                                    Explore
                                  </a>
                                  <a href="#" class="btn btn-default">
                                    <img src={applyBtn} alt="" />
                                    Apply
                                  </a>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="tsc-item">
                            <div class="tsc-box">
                              <div class="tsc-thumb">
                                <img src={schoolImg2} alt="" />
                              </div>
                              <div class="tsc-body">
                                <h5 class="tsc-title">
                                  <img src={school} alt="" />
                                  Er.Perumal Manimekalai College
                                </h5>
                                <div class="tsc-meta">
                                  <span class="tsc-meta-item">
                                    <img src={courseType2} alt="" />
                                    course
                                  </span>
                                  <span class="tsc-meta-item">
                                    <img src={courseType3} alt="" />
                                    course
                                  </span>
                                  <span class="tsc-meta-item">
                                    <img src={placement} alt="" />
                                    Placement
                                  </span>
                                </div>
                                <div class="tsc-btn">
                                  <a href="#" class="btn btn-default">
                                    <img src={exploreBtn} alt="" />
                                    Explore
                                  </a>
                                  <a href="#" class="btn btn-default">
                                    <img src={applyBtn} alt="" />
                                    Apply
                                  </a>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="tsc-item">
                            <div class="tsc-box">
                              <div class="tsc-thumb">
                                <img src={schoolImg2} alt="" />
                              </div>
                              <div class="tsc-body">
                                <h5 class="tsc-title">
                                  <img src={school} alt="" />
                                  Er.Perumal Manimekalai College
                                </h5>
                                <div class="tsc-meta">
                                  <span class="tsc-meta-item">
                                    <img src={courseType2} alt="" />
                                    course
                                  </span>
                                  <span class="tsc-meta-item">
                                    <img src={courseType3} alt="" />
                                    course
                                  </span>
                                  <span class="tsc-meta-item">
                                    <img src={placement} alt="" />
                                    Placement
                                  </span>
                                </div>
                                <div class="tsc-btn">
                                  <a href="#" class="btn btn-default">
                                    <img src={exploreBtn} alt="" />
                                    Explore
                                  </a>
                                  <a href="#" class="btn btn-default">
                                    <img src={applyBtn} alt="" />
                                    Apply
                                  </a>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="tsc-item">
                            <div class="tsc-box">
                              <div class="tsc-thumb">
                                <img src={schoolImg3} alt="" />
                              </div>
                              <div class="tsc-body">
                                <h5 class="tsc-title">
                                  <img src={school} alt="" />
                                  Er.Perumal Manimekalai College
                                </h5>
                                <div class="tsc-meta">
                                  <span class="tsc-meta-item">
                                    <img src={courseType2} alt="" />
                                    course
                                  </span>
                                  <span class="tsc-meta-item">
                                    <img src={courseType3} alt="" />
                                    course
                                  </span>
                                  <span class="tsc-meta-item">
                                    <img src={placement} alt="" />
                                    Placement
                                  </span>
                                </div>
                                <div class="tsc-btn">
                                  <a href="#" class="btn btn-default">
                                    <img src={exploreBtn} alt="" />
                                    Explore
                                  </a>
                                  <a href="#" class="btn btn-default">
                                    <img src={applyBtn} alt="" />
                                    Apply
                                  </a>
                                </div>
                              </div>
                            </div>
                          </li>
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-container btn-tabs-container grey-bg3">
          <div class="container container1">
            <div class="row">
              <div class="col-md-12">
                <div class="heading blue-heading">
                  <h3>Treading Course & Exam</h3>
                  <div class="heading_shap"></div>
                </div>
                <div class="merit-tabs">
                  <ul class="nav nav-tabs" id="ex1" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a
                        class={
                          course === "courses" ? "nav-link active" : "nav-link"
                        }
                        id="ex1-tab-6"
                        data-mdb-toggle="tab"
                        href="#ex1-tabs-6"
                        role="tab"
                        aria-controls="ex1-tabs-6"
                        aria-selected="true"
                        onClick={(e) => {
                          if (e) e.preventDefault();
                          setCourse("courses");
                        }}
                      >
                        Course
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class={
                          course === "exam" ? "nav-link active" : "nav-link"
                        }
                        id="ex1-tab-7"
                        data-mdb-toggle="tab"
                        href="#ex1-tabs-7"
                        role="tab"
                        aria-controls="ex1-tabs-7"
                        aria-selected="false"
                        onClick={(e) => {
                          if (e) e.preventDefault();
                          setCourse("exam");
                        }}
                      >
                        Exam
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="meril-tab-body">
                  <div class="tab-content" id="ex1-content">
                    <div
                      class={
                        course === "courses"
                          ? "tab-pane fade active show"
                          : "tab-pane fade"
                      }
                      id="ex1-tabs-6"
                      role="tabpane6"
                      aria-labelledby="ex1-tab-6"
                    >
                      <div class="btn-tabs-container">
                        <div class="btn-tabs-box">
                          <a href="#" class="btn-tabs-link">
                            BE/B.tech
                          </a>
                          <a href="#" class="btn-tabs-link">
                            PGDM
                          </a>
                          <a href="#" class="btn-tabs-link">
                            B.Com
                          </a>
                          <a href="#" class="btn-tabs-link">
                            BA
                          </a>
                          <a href="#" class="btn-tabs-link">
                            B.PHA
                          </a>
                          <a href="#" class="btn-tabs-link">
                            LLB
                          </a>
                          <a href="#" class="btn-tabs-link">
                            BSC
                          </a>
                          <a href="#" class="btn-tabs-link">
                            GNM
                          </a>
                          <a href="#" class="btn-tabs-link">
                            BUMS
                          </a>
                          <a href="#" class="btn-tabs-link">
                            MBBS
                          </a>
                          <a href="#" class="btn-tabs-link">
                            BBA
                          </a>
                          <a href="#" class="btn-tabs-link">
                            B.Arch
                          </a>
                          <a href="#" class="btn-tabs-link">
                            MBA
                          </a>
                          <a href="#" class="btn-tabs-link">
                            BHMS
                          </a>
                          <a href="#" class="btn-tabs-link">
                            BVSc
                          </a>
                          <a href="#" class="btn-tabs-link">
                            BHA
                          </a>
                          <a href="#" class="btn-tabs-link">
                            BHM
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      class={
                        course === "exam"
                          ? "tab-pane fade active show"
                          : "tab-pane fade"
                      }
                      id="ex1-tabs-7"
                      role="tabpane7"
                      aria-labelledby="ex1-tab-7"
                    >
                      <div class="btn-tabs-container">
                        <div class="btn-tabs-box type_2">
                          <a href="#" class="btn-tabs-link">
                            NEET UG
                          </a>
                          <a href="#" class="btn-tabs-link">
                            NEET PG
                          </a>
                          <a href="#" class="btn-tabs-link">
                            JEE Mains.
                          </a>
                          <a href="#" class="btn-tabs-link">
                            JEE Advance.
                          </a>
                          <a href="#" class="btn-tabs-link">
                            CAT.
                          </a>
                          <a href="#" class="btn-tabs-link">
                            MAT.
                          </a>
                          <a href="#" class="btn-tabs-link">
                            GMAT.
                          </a>
                          <a href="#" class="btn-tabs-link">
                            GATE.
                          </a>
                          <a href="#" class="btn-tabs-link">
                            COMED-K.
                          </a>
                          <a href="#" class="btn-tabs-link">
                            VITEEE.
                          </a>
                          <a href="#" class="btn-tabs-link">
                            MHCET
                          </a>
                          <a href="#" class="btn-tabs-link">
                            MET.
                          </a>
                          <a href="#" class="btn-tabs-link">
                            BITSAT.
                          </a>
                          <a href="#" class="btn-tabs-link">
                            SNAP.
                          </a>
                          <a href="#" class="btn-tabs-link">
                            NMAT
                          </a>
                          <a href="#" class="btn-tabs-link">
                            XAT.
                          </a>
                          <a href="#" class="btn-tabs-link">
                            CMAT
                          </a>
                          <a href="#" class="btn-tabs-link">
                            SRM JEE.
                          </a>
                          <a href="#" class="btn-tabs-link">
                            WB JEE.
                          </a>
                          <a href="#" class="btn-tabs-link">
                            Uniguage
                          </a>
                          <a href="#" class="btn-tabs-link">
                            ICAR
                          </a>
                          <a href="#" class="btn-tabs-link">
                            NATA
                          </a>
                        </div>
                        <div class="show-more-btn text-center">
                          <a href="#" class="btn btn-primary">
                            Show More <img src={arrow1} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-container why-choose-merit-container">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="heading blue-heading">
                  <h3>Why Choose MeritBell.com ?</h3>
                  <div class="heading_shap"></div>
                </div>
                <div class="wcm-container">
                  <ul class="wcm-list d-flex flex-wrap">
                    <li class="wcm-item">
                      <a href="#" class="wcm-box">
                        <div class="wcm-img">
                          <img src={school} alt="" />
                        </div>
                        <div class="wcm-content">
                          <h5>Schools</h5>
                          <p>Review Comparison</p>
                        </div>
                      </a>
                    </li>
                    <li class="wcm-item">
                      <a href="#" class="wcm-box">
                        <div class="wcm-img">
                          <img src={examType2} alt="" />
                        </div>
                        <div class="wcm-content">
                          <h5>Exams</h5>
                          <p>Date Schedule</p>
                        </div>
                      </a>
                    </li>
                    <li class="wcm-item">
                      <a href="#" class="wcm-box">
                        <div class="wcm-img">
                          <img src={collegeAdvise} alt="" />
                        </div>
                        <div class="wcm-content">
                          <h5>College Advise</h5>
                          <p>Ranking Review Comparison</p>
                        </div>
                      </a>
                    </li>
                    <li class="wcm-item">
                      <a href="#" class="wcm-box">
                        <div class="wcm-img">
                          <img src={career} alt="" />
                        </div>
                        <div class="wcm-content">
                          <h5>Career</h5>
                          <p>Counselling, Advice & Live talk</p>
                        </div>
                      </a>
                    </li>
                    <li class="wcm-item">
                      <a href="#" class="wcm-box">
                        <div class="wcm-img">
                          <img src={counsalty} alt="" />
                        </div>
                        <div class="wcm-content">
                          <h5>Consultacncy</h5>
                          <p>100% discount on applications forms</p>
                        </div>
                      </a>
                    </li>
                    <li class="wcm-item">
                      <a href="#" class="wcm-box">
                        <div class="wcm-img">
                          <img src={businessListing} alt="" />
                        </div>
                        <div class="wcm-content">
                          <h5>Business Listing</h5>
                          <p>Over 100+ career options</p>
                        </div>
                      </a>
                    </li>
                    <li class="wcm-item">
                      <a href="#" class="wcm-box">
                        <div class="wcm-img">
                          <img src={applicationForm} alt="" />
                        </div>
                        <div class="wcm-content">
                          <h5>Common Application Form</h5>
                          <p>100% discount on applications forms</p>
                        </div>
                      </a>
                    </li>
                    <li class="wcm-item">
                      <a href="#" class="wcm-box">
                        <div class="wcm-img">
                          <img src={support} alt="" />
                        </div>
                        <div class="wcm-content">
                          <h5>Support</h5>
                          <p>Personalized Admission Assistance</p>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-container ranking-container grey-bg">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="heading">
                  <h3>Ranking</h3>
                  <div class="heading_shap"></div>
                </div>
                <div class="ranking-content-outer d-flex flex-wrap">
                  <div class="ranking-text">
                    <h5>Why Ranking?</h5>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic
                    </p>
                  </div>
                  <div class="ranking-content">
                    <div class="ranking-left">
                      <h5>proceduce</h5>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic
                      </p>
                    </div>
                    <div class="ranking-right-video">
                      <div class="ratio ratio-16x9">
                        <iframe
                          src="https://www.youtube.com/embed/vlDzYIIOYmM"
                          title="YouTube video"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <a href="#" class="btn btn-default">
                    Get Ranked
                    <img src={arrow1} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-container blog-post-container">
          <div class="container container2">
            <div class="row">
              <div class="col-md-12">
                <div class="heading">
                  <h3>Blog</h3>
                  <div class="heading_shap"></div>
                </div>
                <div class="blog-post-container">
                  <Slider
                    {...setting4}
                    className="blog-post-list blog-post-slider pb-0 "
                  >
                    <li class="blog-post-item">
                      <a href="" class="blog-post-box btn-parents">
                        <div class="blog-post-thumb">
                          <img src={blogImg1} alt="" />
                        </div>
                        <div class="blog-post-body">
                          <div class="blog-post-info">
                            <div class="blog-post-meta">
                              <div class="blog-post-meta-item blog-post-by">
                                <i class="fas fa-user"></i>
                                <p>
                                  {" "}
                                  post by <em>John Doe</em>
                                </p>
                              </div>
                              <div class="blog-post-meta-item blog-post-meta-date">
                                <img src={blogCalendar} alt="" />
                                <p>April 05, 2021</p>
                              </div>
                            </div>
                            <div class="blog-post-title">
                              <h6>Bihar Board 10th result 2021</h6>
                            </div>
                          </div>
                          <div class="blog-post-content">
                            <p>
                              Bihar Board 10th Result 2021 certificates will be
                              issued today, June 26, 2021. Candidates can check
                              the notice here.
                            </p>
                          </div>
                          <div class="blog-post-btn">
                            <span class="btn btn-secondary">
                              {" "}
                              Read More <img src={arrow2} alt="" />{" "}
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li class="blog-post-item">
                      <a href="" class="blog-post-box btn-parents">
                        <div class="blog-post-thumb">
                          <img src={blogImg1} alt="" />
                        </div>
                        <div class="blog-post-body">
                          <div class="blog-post-info">
                            <div class="blog-post-meta">
                              <div class="blog-post-meta-item blog-post-by">
                                <i class="fas fa-user"></i>
                                <p>
                                  {" "}
                                  post by <em>John Doe</em>
                                </p>
                              </div>
                              <div class="blog-post-meta-item blog-post-meta-date">
                                <img src={blogCalendar} alt="" />
                                <p>April 05, 2021</p>
                              </div>
                            </div>
                            <div class="blog-post-title">
                              <h6>Bihar Board 10th result 2021</h6>
                            </div>
                          </div>
                          <div class="blog-post-content">
                            <p>
                              Bihar Board 10th Result 2021 certificates will be
                              issued today, June 26, 2021. Candidates can check
                              the notice here.
                            </p>
                          </div>
                          <div class="blog-post-btn">
                            <span class="btn btn-secondary">
                              {" "}
                              Read More <img src={arrow2} alt="" />{" "}
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li class="blog-post-item">
                      <a href="" class="blog-post-box btn-parents">
                        <div class="blog-post-thumb">
                          <img src={blogImg1} alt="" />
                        </div>
                        <div class="blog-post-body">
                          <div class="blog-post-info">
                            <div class="blog-post-meta">
                              <div class="blog-post-meta-item blog-post-by">
                                <i class="fas fa-user"></i>
                                <p>
                                  {" "}
                                  post by <em>John Doe</em>
                                </p>
                              </div>
                              <div class="blog-post-meta-item blog-post-meta-date">
                                <img src={blogCalendar} alt="" />
                                <p>April 05, 2021</p>
                              </div>
                            </div>
                            <div class="blog-post-title">
                              <h6>Bihar Board 10th result 2021</h6>
                            </div>
                          </div>
                          <div class="blog-post-content">
                            <p>
                              Bihar Board 10th Result 2021 certificates will be
                              issued today, June 26, 2021. Candidates can check
                              the notice here.
                            </p>
                          </div>
                          <div class="blog-post-btn">
                            <span class="btn btn-secondary">
                              {" "}
                              Read More <img src={arrow2} alt="" />{" "}
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li class="blog-post-item">
                      <a href="" class="blog-post-box btn-parents">
                        <div class="blog-post-thumb">
                          <img src={blogImg1} alt="" />
                        </div>
                        <div class="blog-post-body">
                          <div class="blog-post-info">
                            <div class="blog-post-meta">
                              <div class="blog-post-meta-item blog-post-by">
                                <i class="fas fa-user"></i>
                                <p>
                                  {" "}
                                  post by <em>John Doe</em>
                                </p>
                              </div>
                              <div class="blog-post-meta-item blog-post-meta-date">
                                <img src={blogCalendar} alt="" />
                                <p>April 05, 2021</p>
                              </div>
                            </div>
                            <div class="blog-post-title">
                              <h6>Bihar Board 10th result 2021</h6>
                            </div>
                          </div>
                          <div class="blog-post-content">
                            <p>
                              Bihar Board 10th Result 2021 certificates will be
                              issued today, June 26, 2021. Candidates can check
                              the notice here.
                            </p>
                          </div>
                          <div class="blog-post-btn">
                            <span class="btn btn-secondary">
                              {" "}
                              Read More <img src={arrow2} alt="" />{" "}
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-container post-events-container grey-bg1">
          <div class="container container2">
            <div class="row">
              <div class="col-md-12">
                <div class="heading">
                  <h3>Latest News & Updates</h3>
                  <div class="heading_shap"></div>
                </div>
                <div class="merit-tabs">
                  <ul class="nav nav-tabs" id="ex1" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a
                        class={
                          newsUpdate === "news" ? "nav-link active" : "nav-link"
                        }
                        id="ex1-tab-3"
                        data-mdb-toggle="tab"
                        href="#ex1-tabs-3"
                        role="tab"
                        aria-controls="ex1-tabs-3"
                        aria-selected="true"
                        onClick={(e) => {
                          if (e) e.preventDefault();
                          setNewsUpdate("news");
                        }}
                      >
                        News
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class={
                          newsUpdate === "examUpdate"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        id="ex1-tab-4"
                        data-mdb-toggle="tab"
                        href="#ex1-tabs-4"
                        role="tab"
                        aria-controls="ex1-tabs-4"
                        aria-selected="false"
                        onClick={(e) => {
                          if (e) e.preventDefault();
                          setNewsUpdate("examUpdate");
                        }}
                      >
                        Exam Update
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class={
                          newsUpdate === "other"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        id="ex1-tab-5"
                        data-mdb-toggle="tab"
                        href="#ex1-tabs-5"
                        role="tab"
                        aria-controls="ex1-tabs-5"
                        aria-selected="false"
                        onClick={(e) => {
                          if (e) e.preventDefault();
                          setNewsUpdate("other");
                        }}
                      >
                        Other
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="meril-tab-body">
                  <div class="tab-content" id="ex1-content">
                    <div
                      class={
                        newsUpdate === "news"
                          ? "tab-pane fade active show"
                          : "tab-pane fade"
                      }
                      id="ex1-tabs-3"
                      role="tabpanel"
                      aria-labelledby="ex1-tab-3"
                    >
                      <div class="post-events-container">
                        <ul class="post-events-list pb-0">
                          <li class="post-events-item">
                            <a href="" class="post-events-box">
                              <div class="post-events-thumb">
                                <img src={postEvent1} alt="" />
                              </div>
                              <div class="post-events-body">
                                <div class="post-events-info">
                                  <div class="post-events-meta">
                                    <div class="post-events-meta-item post-events-meta-date">
                                      <p>April 05, 2021</p>
                                    </div>
                                  </div>
                                  <div class="post-events-title">
                                    <h6>Bihar Board 10th result 2021</h6>
                                  </div>
                                </div>
                                <div class="post-events-content">
                                  <p>
                                    Bihar Board 10th Result 2021 certificates
                                    will be issued today, June 26, 2021.
                                    Candidates can check the notice here.
                                  </p>
                                </div>
                                <div class="post-events-btn">
                                  <span class="btn btn-default">
                                    {" "}
                                    Read More <img src={arrow1} alt="" />{" "}
                                  </span>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li class="post-events-item">
                            <a href="" class="post-events-box">
                              <div class="post-events-thumb">
                                <img src={postEvent1} alt="" />
                              </div>
                              <div class="post-events-body">
                                <div class="post-events-info">
                                  <div class="post-events-meta">
                                    <div class="post-events-meta-item post-events-meta-date">
                                      <p>April 05, 2021</p>
                                    </div>
                                  </div>
                                  <div class="post-events-title">
                                    <h6>Bihar Board 10th result 2021</h6>
                                  </div>
                                </div>
                                <div class="post-events-content">
                                  <p>
                                    Bihar Board 10th Result 2021 certificates
                                    will be issued today, June 26, 2021.
                                    Candidates can check the notice here.
                                  </p>
                                </div>
                                <div class="post-events-btn">
                                  <span class="btn btn-default">
                                    {" "}
                                    Read More <img src={arrow1} alt="" />{" "}
                                  </span>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li class="post-events-item">
                            <a href="" class="post-events-box">
                              <div class="post-events-thumb">
                                <img src={postEvent1} alt="" />
                              </div>
                              <div class="post-events-body">
                                <div class="post-events-info">
                                  <div class="post-events-meta">
                                    <div class="post-events-meta-item post-events-meta-date">
                                      <p>April 05, 2021</p>
                                    </div>
                                  </div>
                                  <div class="post-events-title">
                                    <h6>Bihar Board 10th result 2021</h6>
                                  </div>
                                </div>
                                <div class="post-events-content">
                                  <p>
                                    Bihar Board 10th Result 2021 certificates
                                    will be issued today, June 26, 2021.
                                    Candidates can check the notice here.
                                  </p>
                                </div>
                                <div class="post-events-btn">
                                  <span class="btn btn-default">
                                    {" "}
                                    Read More <img src={arrow1} alt="" />{" "}
                                  </span>
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      class={
                        newsUpdate === "examUpdate"
                          ? "tab-pane fade active show"
                          : "tab-pane fade"
                      }
                      id="ex1-tabs-4"
                      role="tabpanel"
                      aria-labelledby="ex1-tab-4"
                    >
                      <div class="post-events-container">
                        <ul class="post-events-list pb-0">
                          <li class="post-events-item">
                            <a href="" class="post-events-box">
                              <div class="post-events-thumb">
                                <img src={postEvent2} alt="" />
                              </div>
                              <div class="post-events-body">
                                <div class="post-events-info">
                                  <div class="post-events-meta">
                                    <div class="post-events-meta-item post-events-meta-date">
                                      <p>April 05, 2021</p>
                                    </div>
                                  </div>
                                  <div class="post-events-title">
                                    <h6>Bihar Board 10th result 2021</h6>
                                  </div>
                                </div>
                                <div class="post-events-content">
                                  <p>
                                    Bihar Board 10th Result 2021 certificates
                                    will be issued today, June 26, 2021.
                                    Candidates can check the notice here.
                                  </p>
                                </div>
                                <div class="post-events-btn">
                                  <span class="btn btn-default">
                                    {" "}
                                    Read More <img src={arrow1} alt="" />{" "}
                                  </span>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li class="post-events-item">
                            <a href="" class="post-events-box">
                              <div class="post-events-thumb">
                                <img src={postEvent2} alt="" />
                              </div>
                              <div class="post-events-body">
                                <div class="post-events-info">
                                  <div class="post-events-meta">
                                    <div class="post-events-meta-item post-events-meta-date">
                                      <p>April 05, 2021</p>
                                    </div>
                                  </div>
                                  <div class="post-events-title">
                                    <h6>Bihar Board 10th result 2021</h6>
                                  </div>
                                </div>
                                <div class="post-events-content">
                                  <p>
                                    Bihar Board 10th Result 2021 certificates
                                    will be issued today, June 26, 2021.
                                    Candidates can check the notice here.
                                  </p>
                                </div>
                                <div class="post-events-btn">
                                  <span class="btn btn-default">
                                    {" "}
                                    Read More <img src={arrow1} alt="" />{" "}
                                  </span>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li class="post-events-item">
                            <a href="" class="post-events-box">
                              <div class="post-events-thumb">
                                <img src={postEvent2} alt="" />
                              </div>
                              <div class="post-events-body">
                                <div class="post-events-info">
                                  <div class="post-events-meta">
                                    <div class="post-events-meta-item post-events-meta-date">
                                      <p>April 05, 2021</p>
                                    </div>
                                  </div>
                                  <div class="post-events-title">
                                    <h6>Bihar Board 10th result 2021</h6>
                                  </div>
                                </div>
                                <div class="post-events-content">
                                  <p>
                                    Bihar Board 10th Result 2021 certificates
                                    will be issued today, June 26, 2021.
                                    Candidates can check the notice here.
                                  </p>
                                </div>
                                <div class="post-events-btn">
                                  <span class="btn btn-default">
                                    {" "}
                                    Read More <img src={arrow1} alt="" />{" "}
                                  </span>
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      class={
                        newsUpdate === "other"
                          ? "tab-pane fade active show"
                          : "tab-pane fade"
                      }
                      id="ex1-tabs-5"
                      role="tabpanel"
                      aria-labelledby="ex1-tab-5"
                    >
                      <div class="post-events-container">
                        <ul class="post-events-list pb-0">
                          <li class="post-events-item">
                            <a href="" class="post-events-box">
                              <div class="post-events-thumb">
                                <img src={postEvent3} alt="" />
                              </div>
                              <div class="post-events-body">
                                <div class="post-events-info">
                                  <div class="post-events-meta">
                                    <div class="post-events-meta-item post-events-meta-date">
                                      <p>April 05, 2021</p>
                                    </div>
                                  </div>
                                  <div class="post-events-title">
                                    <h6>Bihar Board 10th result 2021</h6>
                                  </div>
                                </div>
                                <div class="post-events-content">
                                  <p>
                                    Bihar Board 10th Result 2021 certificates
                                    will be issued today, June 26, 2021.
                                    Candidates can check the notice here.
                                  </p>
                                </div>
                                <div class="post-events-btn">
                                  <span class="btn btn-default">
                                    {" "}
                                    Read More <img src={arrow1} alt="" />{" "}
                                  </span>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li class="post-events-item">
                            <a href="" class="post-events-box">
                              <div class="post-events-thumb">
                                <img src={postEvent3} alt="" />
                              </div>
                              <div class="post-events-body">
                                <div class="post-events-info">
                                  <div class="post-events-meta">
                                    <div class="post-events-meta-item post-events-meta-date">
                                      <p>April 05, 2021</p>
                                    </div>
                                  </div>
                                  <div class="post-events-title">
                                    <h6>Bihar Board 10th result 2021</h6>
                                  </div>
                                </div>
                                <div class="post-events-content">
                                  <p>
                                    Bihar Board 10th Result 2021 certificates
                                    will be issued today, June 26, 2021.
                                    Candidates can check the notice here.
                                  </p>
                                </div>
                                <div class="post-events-btn">
                                  <span class="btn btn-default">
                                    {" "}
                                    Read More <img src={arrow1} alt="" />{" "}
                                  </span>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li class="post-events-item">
                            <a href="" class="post-events-box">
                              <div class="post-events-thumb">
                                <img src={postEvent3} alt="" />
                              </div>
                              <div class="post-events-body">
                                <div class="post-events-info">
                                  <div class="post-events-meta">
                                    <div class="post-events-meta-item post-events-meta-date">
                                      <p>April 05, 2021</p>
                                    </div>
                                  </div>
                                  <div class="post-events-title">
                                    <h6>Bihar Board 10th result 2021</h6>
                                  </div>
                                </div>
                                <div class="post-events-content">
                                  <p>
                                    Bihar Board 10th Result 2021 certificates
                                    will be issued today, June 26, 2021.
                                    Candidates can check the notice here.
                                  </p>
                                </div>
                                <div class="post-events-btn">
                                  <span class="btn btn-default">
                                    {" "}
                                    Read More <img src={arrow1} alt="" />{" "}
                                  </span>
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-container recomind-container">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 offset-lg-3">
                <div class="heading">
                  <h3>
                    Rate & Recominded MeritBell.com to yout Friend & College
                  </h3>
                  <div class="heading_shap"></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="rating-form-content">
                  <h5>What we need to improve the most in the website ?</h5>
                  <div>
                    <div class="form-group form-check-group">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="select1"
                          value="option1"
                        />
                        <label class="form-check-label" for="select1">
                          content accuracy{" "}
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="select2"
                          value="option1"
                        />
                        <label class="form-check-label" for="select2">
                          Website loadtime
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="select3"
                          value="option1"
                        />
                        <label class="form-check-label" for="select3">
                          Website Design
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="select4"
                          value="option1"
                        />
                        <label class="form-check-label" for="select4">
                          Readability{" "}
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="select5"
                          value="option1"
                        />
                        <label class="form-check-label" for="select5">
                          Other
                        </label>
                      </div>
                    </div>
                    <div class="form-group">
                      <textarea placeholder="" class="form-control"></textarea>
                    </div>
                    <div class="form-group text-center mt-4 mt-md-5">
                      <button type="submit" class="btn btn-default">
                        {" "}
                        submit <img src={arrow1} alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="rating-img">
                  <img src={recommned} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <NewsLetter />
        <Footer />
        <CategorySelector
          toggleCat={toggleCat}
          setToggleCat={setToggleCat}
          type={type}
          setType={setType}
        />
        {/* <ListBusiness
          toggleBusiness={toggleBusiness}
          setToggleBusiness={setToggleBusiness}
        /> */}
      </div>
    </>
  );
};

export default Home;
