import React, { Component } from "react";

//images
import btnIconSave from "../../../include/images/btn-icon-save.svg";

//component
import Form from "../../common/form/form";
import { MDBFile } from "mdb-react-ui-kit";

class BasicDetail extends Form {
  state = {
    loading: false,
    data: {
      consultancyName: "",
    },
    errors: {},
  };

  render() {
    return (
      <>
        <div class="main-container">
          <div class="heading">
            <h3>Student Helpdesk</h3>
            <div class="heading_shap"></div>
          </div>
          <div class="dashboard-box">
            <div class="row">
              <div class="col-md-6">
                {this.renderInput("consultancyName", "Consultancy name")}
              </div>
              <div class="col-md-6">
                {this.renderInput("sortName", "Sort Name")}
              </div>
              <div class="col-md-6">
                {this.renderInput("upsTagline", "UPS (Tagline)")}
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  {/* <label>Establishment Year</label> */}
                  <select class="form-control">
                    <option selected> Establishment Year</option>
                    <option> 2001</option>
                    <option> 2002</option>
                    <option> 2003</option>
                    <option> 2004</option>
                    <option> 2005</option>
                    <option> 2006</option>
                    <option> 2007</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                {this.renderInput("websiteUrl", "Website URL")}
              </div>
              <div class="col-md-6">
                <div class="form-group choose-file">
                  <label class="file-label">Upload Logo</label>
                  <input type="file" class="form-control" id="customFile" />
                </div>
              </div>
              <div class="col-md-6">
                {this.renderInput("founderName", "Founder Name", "Name")}
              </div>
              <div class="col-md-6">
                {this.renderInput(
                  "email",
                  "Email Address",
                  "eg: xxxx@gmail.com",
                  "email"
                )}
              </div>
              <div class="col-md-6">
                {this.renderInput(
                  "phone",
                  "Contact Number",
                  "Whatsapp Number",
                  "tel"
                )}
              </div>
              <div class="col-md-6">
                {this.renderInput(
                  "alterPhn",
                  "Alternate Phone",
                  "Alternate Phone",
                  "tel"
                )}
                {/* <div class="form-group">
                  <label>Alternate Number</label>
                  <div class="select-with-input">
                    <input type="text" class="form-control" placeholder="" />
                    <select class="form-control">
                      <option>+ 91</option>
                      <option>+ 92</option>
                      <option>+ 93</option>
                      <option>+ 94</option>
                    </select>
                  </div>
                </div> */}
              </div>
              <div class="col-md-12">
                <div class="save-btn text-center">
                  <a href="#" class="btn btn-default">
                    <img src={btnIconSave} alt="" /> Save
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default BasicDetail;
