import React, { useState } from "react";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
  MDBModalTitle,
  MDBModalHeader,
} from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";

const ListBusiness = (props) => {
  const { toggleBusiness, setToggleBusiness } = props;
  const navigate = useNavigate();

  //local states
  const [list] = useState([
    {
      name: "Sell your Product",
      className: "fas fa-user fa-3x",
      value: "seller",
    },
    {
      name: "Post Home Tution",
      className: "fas fa-user fa-3x",
      value: "homeTutor",
    },
    {
      name: "Add Coaching Institute",
      className: "fas fa-chalkboard fa-3x",
      value: "coachingInstitute",
    },
    { name: "Add School", className: "fas fa-school fa-3x", value: "school" },
    {
      name: "Add PG/Hostel",
      className: "fas fa-hotel fa-3x",
      value: "pg/hostel",
    },
    {
      name: "Add Consultancy",
      className: "fas fa-users fa-3x",
      value: "consultant",
    },

    {
      name: "Add Institute",
      className: "fas fa-university fa-3x",
      value: "institution",
    },
  ]);
  return (
    <MDBModal
      className="category-modal"
      tabIndex="-1"
      show={toggleBusiness}
      setShow={setToggleBusiness}
    >
      <MDBModalDialog centered size="lg">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle className="w-100">
              <div className="title-conatiner d-flex flex-wrap w-100 mt-2">
                <a className={"title-link active"} href="javascript:void(0)">
                  Select your Business to List
                </a>
              </div>
            </MDBModalTitle>
            <i
              onClick={() => setToggleBusiness(!toggleBusiness)}
              class="fas fa-times fa-lg cursor-pointer"
            ></i>
          </MDBModalHeader>
          <MDBModalBody>
            <div className="category-container">
              <ul className="cat-list-container d-flex flex-wrap-reverse align-items-strech justify-content-center">
                {list.map((cat) => (
                  <li
                    className="cat-item d-flex align-items-center justify-content-center cursor-pointer"
                    onClick={() => navigate("/dashboard/basic-detail")}
                  >
                    <a
                      href="javascript:void(0)"
                      className="cat-link text-center "
                    >
                      <div className="icon-container">
                        <i class={cat.className}></i>
                      </div>
                      {cat.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default ListBusiness;
