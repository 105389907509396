import React, { Component } from "react";
import { Link } from "react-router-dom";

//images
import footerLogo from "../../include/images/footer-logo.svg";

class Footer extends Component {
  render() {
    return (
      <footer class="footer">
        <div class="footer-upper">
          <div class="container">
            <div class="row">
              <div class="col-xl-10 offset-xl-1">
                <div class="footer-upper-content d-flex flex-wrap">
                  <div class="footer-box">
                    <h5>Other Links</h5>
                    <ul class="footer-nav">
                      <li class="menu-item">
                        <Link to="/">Home </Link>
                      </li>
                      <li class="menu-item">
                        <a href="#">About us </a>
                      </li>
                      <li class="menu-item">
                        <a href="#">Education</a>
                      </li>
                      <li class="menu-item">
                        <a href="#">Our Events</a>
                      </li>
                      <li class="menu-item">
                        <a href="#">Our Packages</a>
                      </li>
                    </ul>
                  </div>
                  <div class="footer-box">
                    <h5>Study Abroad</h5>
                    <ul class="footer-nav">
                      <li class="menu-item">
                        <a href="#">Canada </a>
                      </li>
                      <li class="menu-item">
                        <a href="#">USA</a>
                      </li>
                      <li class="menu-item">
                        <a href="#">UK</a>
                      </li>
                      <li class="menu-item">
                        <a href="#">Australia</a>
                      </li>
                      <li class="menu-item">
                        <a href="#">Germany</a>
                      </li>
                    </ul>
                  </div>
                  <div class="footer-box">
                    <h5>Top Exams</h5>
                    <ul class="footer-nav">
                      <li class="menu-item">
                        <a href="#">CAT</a>
                      </li>
                      <li class="menu-item">
                        <a href="#">GATE</a>
                      </li>
                      <li class="menu-item">
                        <a href="#">JEE-MAIN</a>
                      </li>
                      <li class="menu-item">
                        <a href="#">NEET</a>
                      </li>
                      <li class="menu-item">
                        <a href="#">XAT</a>
                      </li>
                    </ul>
                  </div>
                  <div class="footer-box">
                    <h5>Top Universities</h5>
                    <ul class="footer-nav">
                      <li class="menu-item">
                        <a href="#">Engineering </a>
                      </li>
                      <li class="menu-item">
                        <a href="#">Management</a>
                      </li>
                      <li class="menu-item">
                        <a href="#">Medical</a>
                      </li>
                      <li class="menu-item">
                        <a href="#">Law</a>
                      </li>
                      <li class="menu-item">
                        <a href="#">Commerce</a>
                      </li>
                    </ul>
                  </div>
                  <div class="footer-box">
                    <h5>Top Colleges</h5>
                    <ul class="footer-nav">
                      <li class="menu-item">
                        <a href="#">MBA </a>
                      </li>
                      <li class="menu-item">
                        <a href="#">MCA</a>
                      </li>
                      <li class="menu-item">
                        <a href="#">B.Sc(IT)</a>
                      </li>
                      <li class="menu-item">
                        <a href="#">BE (EEE)</a>
                      </li>
                      <li class="menu-item">
                        <a href="#">All Courses... </a>
                      </li>
                    </ul>
                  </div>
                  <div class="footer-box">
                    <h5>Locations</h5>
                    <ul class="footer-nav">
                      <li class="menu-item">
                        <a href="#">Ireland </a>
                      </li>
                      <li class="menu-item">
                        <a href="#">New Zealand</a>
                      </li>
                      <li class="menu-item">
                        <a href="#">Singapore</a>
                      </li>
                      <li class="menu-item">
                        <a href="#">Malaysia</a>
                      </li>
                      <li class="menu-item">
                        <a href="#"> Hong Kong </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-mid">
          <div class="container">
            <div class="row">
              <div class="col-xl-10 offset-xl-1">
                <div class="footer-log">
                  <img src={footerLogo} alt="" />
                </div>
                <div class="footer-social-media">
                  <a href="#" target="_blank">
                    <i class="fab fa-facebook-f"></i>
                  </a>
                  <a href="#" target="_blank">
                    <i class="fab fa-instagram"></i>
                  </a>
                  <a href="#" target="_blank">
                    <i class="fab fa-google-plus-g"></i>
                  </a>
                  <a href="#" target="_blank">
                    <i class="fab fa-twitter"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-lower">
          <div class="container">
            <div class="row">
              <div class="col-xl-10 offset-xl-1">
                <div class="socket">
                  <div class="copyright">
                    &copy; 2020 Copyright meritbell International. All Rights
                    Are Reserved.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
