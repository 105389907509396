import React, { Component } from "react";

//images
import searchIcon from "../../include/images/icon-search.svg";
import spotAdmsn from "../../include/images/icon-spot-addmission.svg";
import calendar from "../../include/images/icon-calendar.svg";
import heroImg from "../../include/images/hero-img1.jpg";
import btnStudy from "../../include/images/btn-study.svg";
import announcment from "../../include/images/announcment.png";

//libs
import Slider from "react-slick";

class Hero extends Component {
  state = {
    nav1: null,
    nav2: null,
  };
  componentDidMount = () => {
    this.setState({ nav1: this.slider1, nav2: this.slider2 });
  };

  settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    infinite: true,
    centerMode: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  settings1 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    fade: true,
    arrows: false,
    dots: false,
  };

  settings2 = {
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    infinite: true,
    centerMode: false,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          adaptiveHeight: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          arrows: true,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 580,
        settings: {
          arrows: true,
          slidesToShow: 1,
        },
      },
    ],
  };

  render() {
    return (
      <>
        <div class="hero-container d-flex justify-content-center align-items-center">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="hero-content">
                  {this.props.page === "home" ? (
                    <>
                      {" "}
                      <h6>welcome to meritbell</h6>
                      <h1>
                        plan your career{" "}
                        <span>
                          with <a href="#"> meritbell.com</a>
                        </span>
                      </h1>
                    </>
                  ) : (
                    ""
                  )}
                  <a href="#" class="hero-search-box">
                    <img src={searchIcon} alt="" />
                    <input
                      type="text"
                      placeholder="Search for Colleges, School, Coueses, Exam, News and more"
                    />
                    <button
                      type="submit"
                      value="search"
                      class="btn btn-default"
                    >
                      {" "}
                      Search
                    </button>
                  </a>
                  {this.props.page !== "consultancy" && (
                    <div class="hero-btn-box">
                      <a href="#" class="btn btn-default">
                        <img src={btnStudy} alt="" /> Study Abroad
                      </a>
                      <a href="#" class="btn btn-default">
                        <img src={spotAdmsn} alt="" /> Spot Admission
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Slider
              asNavFor={this.state.nav2}
              ref={(slider) => (this.slider1 = slider)}
              {...this.settings1}
              className="hero-college-link-slide"
            >
              <a href="#">St. Joseph's college ( Autonomous), Bengalore</a>
              <a href="#">St. Joseph's college ( Autonomous), Mumbai</a>
              <a href="#">St. Joseph's college ( Autonomous), Panjab</a>
            </Slider>
          </div>
          <div class="book-apppointment-box">
            <a href="#" class="btn ">
              E- Counselling
            </a>
            <a href="#" class="btn btn-default">
              <img src={calendar} alt="" /> Book Appointment
            </a>
          </div>
          <Slider
            asNavFor={this.state.nav1}
            ref={(slider) => (this.slider2 = slider)}
            {...this.settings}
            className="hero-img-slide-box hero-img-slider pb-0"
          >
            <div class="hero-img-slide-item">
              <img src={heroImg} alt="" />
            </div>
            <div class="hero-img-slide-item">
              <img src={heroImg} alt="" />
            </div>
            <div class="hero-img-slide-item">
              <img src={heroImg} alt="" />
            </div>
          </Slider>
        </div>
        {this.props.page === "home" && (
          <div class="ticker-container w-100">
            <div class="ticker-content ticker-slider">
              <div class="ticker-box">
                <p class="ticker-text">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                </p>
              </div>
              <div class="ticker-box">
                <p class="ticker-text">consectetur adipisicing elit.</p>
              </div>
              <div class="ticker-box">
                <p class="ticker-text">
                  <a href="#">Lorem ipsum</a>
                </p>
              </div>
              <div class="ticker-box">
                <p class="ticker-text">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                </p>
              </div>
            </div>
            <div class="tickerBlock">
              <a href="#!" class="tickerTag">
                <img src={announcment} alt="" />
                <span>Trending Today</span>
              </a>
            </div>
          </div>
        )}
        <div className="content-container populer-course-slide-container p-0">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="populer-course-conetent">
                  <Slider
                    {...this.settings2}
                    className="populer-course-list populer-course-slder overflow-visible pb-0 "
                  >
                    <li className="populer-course-item">
                      <a href="#" className="populer-course-content">
                        <h6> India School Of Business</h6>
                        <p> Most Popular 1 - Year MBA Course</p>
                      </a>
                    </li>
                    <li className="populer-course-item">
                      <a href="#" className="populer-course-content">
                        <h6> India School Of Business</h6>
                        <p> Most Popular 1 - Year MBA Course</p>
                      </a>
                    </li>
                    <li className="populer-course-item">
                      <a href="#" className="populer-course-content">
                        <h6> India School Of Business</h6>
                        <p> Most Popular 1 - Year MBA Course</p>
                      </a>
                    </li>
                    <li className="populer-course-item">
                      <a href="#" className="populer-course-content">
                        <h6> India School Of Business</h6>
                        <p> Most Popular 1 - Year MBA Course</p>
                      </a>
                    </li>
                    <li className="populer-course-item">
                      <a href="#" className="populer-course-content">
                        <h6> India School Of Business</h6>
                        <p> Most Popular 1 - Year MBA Course</p>
                      </a>
                    </li>
                    <li className="populer-course-item">
                      <a href="#" className="populer-course-content">
                        <h6> India School Of Business</h6>
                        <p> Most Popular 1 - Year MBA Course</p>
                      </a>
                    </li>
                    <li className="populer-course-item">
                      <a href="#" className="populer-course-content">
                        <h6> India School Of Business</h6>
                        <p> Most Popular 1 - Year MBA Course</p>
                      </a>
                    </li>
                    <li className="populer-course-item">
                      <a href="#" className="populer-course-content">
                        <h6> India School Of Business</h6>
                        <p> Most Popular 1 - Year MBA Course</p>
                      </a>
                    </li>
                    <li className="populer-course-item">
                      <a href="#" className="populer-course-content">
                        <h6> India School Of Business</h6>
                        <p> Most Popular 1 - Year MBA Course</p>
                      </a>
                    </li>
                    <li className="populer-course-item">
                      <a href="#" className="populer-course-content">
                        <h6> India School Of Business</h6>
                        <p> Most Popular 1 - Year MBA Course</p>
                      </a>
                    </li>
                    <li className="populer-course-item">
                      <a href="#" className="populer-course-content">
                        <h6> India School Of Business</h6>
                        <p> Most Popular 1 - Year MBA Course</p>
                      </a>
                    </li>
                    <li className="populer-course-item">
                      <a href="#" className="populer-course-content">
                        <h6> India School Of Business</h6>
                        <p> Most Popular 1 - Year MBA Course</p>
                      </a>
                    </li>
                    <li className="populer-course-item">
                      <a href="#" className="populer-course-content">
                        <h6> India School Of Business</h6>
                        <p> Most Popular 1 - Year MBA Course</p>
                      </a>
                    </li>
                    <li className="populer-course-item">
                      <a href="#" className="populer-course-content">
                        <h6> India School Of Business</h6>
                        <p> Most Popular 1 - Year MBA Course</p>
                      </a>
                    </li>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Hero;
