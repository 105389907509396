import React from "react";
import Select from "react-select";

const ReactSelect = (props) => {
  const { options, onChange, placeholder, ...rest } = props;
  const customStyles = {
    indicatorSeparator: (styles) => ({ display: "none" }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        background: "#172f40",
        color: "#fff",
      },
      borderRadius: 4,
      color: state.isSelected ? "#fff" : "black",
    }),
    control: (base, state) => ({
      ...base,
      minHeight: 45,
      borderRadius: "3px",
      fontSize: 14,
      border: 0,
    }),
    multiValue: (state) => ({
      ...state,
    }),
    multiValueLabel: (state) => ({
      ...state,
    }),
    multiValueRemove: (base, state) => ({
      ...base,
    }),
  };
  return (
    <Select
      options={options}
      onChange={onChange}
      placeholder={placeholder}
      styles={customStyles}
      classNamePrefix="options-cont"
      className="options-cont"
      {...rest}
    />
  );
};

export default ReactSelect;
