import React, { Component } from "react";

//images
import arrow1 from "../../../include/images/arrow-1.svg";

//Component
import Form from "../../common/form/form";

class Services extends Form {
  render() {
    return (
      <>
        <div class="main-container">
          <div class="heading heading-with-btn">
            <h3>Operation / Service</h3>
            <div class="heading_shap"></div>
            <a href="#" class="btn btn-default">
              {" "}
              Skip <img src={arrow1} alt="" />{" "}
            </a>
          </div>
          <div class="dashboard-box">
            <div class="row">
              <div class="col-md-12">
                <div class="sub-heading">
                  <h5>Operrating in :</h5>
                </div>
              </div>
              <div class="col-md-6">
                <p>( If india Selected )</p>
                <div class="form-group">
                  <select class="form-control">
                    <option>Select State </option>
                    <option> Option 1</option>
                    <option> Option 2</option>
                    <option> Option 3</option>
                    <option> Option 4</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <p>( If Abroad Selected )</p>
                <div class="form-group">
                  <select class="form-control">
                    <option>Select Country </option>
                    <option> Option 1</option>
                    <option> Option 2</option>
                    <option> Option 3</option>
                    <option> Option 4</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <p>(If Both Selected)</p>
                <div class="form-group">
                  <select class="form-control">
                    <option>Select State </option>
                    <option> Option 1</option>
                    <option> Option 2</option>
                    <option> Option 3</option>
                    <option> Option 4</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <p>
                  {" "}
                  <strong>Note</strong> : Give Options for Multiple Chocies /
                  Selcetion
                </p>
                <div class="form-group">
                  <select class="form-control">
                    <option>Select Country </option>
                    <option> Option 1</option>
                    <option> Option 2</option>
                    <option> Option 3</option>
                    <option> Option 4</option>
                  </select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="sub-heading">
                  <h5>Services Offered for :</h5>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-check-group d-flex flex-wrap type2 grid-tree">
                  <div class="form-check ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="select5"
                      value="All Services"
                    />
                    <label class="form-check-label" for="select5">
                      All Services
                    </label>
                  </div>
                  <div class="form-check ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="select6"
                      value="College/ University"
                    />
                    <label class="form-check-label" for="select6">
                      College/ University
                    </label>
                  </div>
                  <div class="form-check ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="select7"
                      value="Open Board"
                    />
                    <label class="form-check-label" for="select7">
                      Open Board
                    </label>
                  </div>
                  <div class="form-check ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="select8"
                      value="Distance Education"
                    />
                    <label class="form-check-label" for="select8">
                      Distance Education
                    </label>
                  </div>
                  <div class="form-check ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="select9"
                      value="Coaching Institude"
                    />
                    <label class="form-check-label" for="select9">
                      Coaching Institude
                    </label>
                  </div>
                  <div class="form-check ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="select10"
                      value="Neet "
                    />
                    <label class="form-check-label" for="select10">
                      Neet{" "}
                    </label>
                  </div>
                  <div class="form-check ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="select11"
                      value="JEE"
                    />
                    <label class="form-check-label" for="select11">
                      JEE
                    </label>
                  </div>
                  <div class="form-check ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="select12"
                      value="School"
                    />
                    <label class="form-check-label" for="select12">
                      School
                    </label>
                  </div>
                  <div class="form-check ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="select13"
                      value="Other ( if others)"
                    />
                    <label class="form-check-label" for="select13">
                      Other ( if others)
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="sub-heading">
                  <h5>Counselling courses :</h5>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-check-group d-flex flex-wrap type2 grid-tree">
                  <div class="form-check ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="select14"
                      value="Medical"
                    />
                    <label class="form-check-label" for="select14">
                      Medical
                    </label>
                  </div>
                  <div class="form-check ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="select15"
                      value="Engineering"
                    />
                    <label class="form-check-label" for="select15">
                      Engineering
                    </label>
                  </div>
                  <div class="form-check ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="select16"
                      value="All Course"
                    />
                    <label class="form-check-label" for="select16">
                      All Course
                    </label>
                  </div>
                  <div class="form-check ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="select17"
                      value="Management"
                    />
                    <label class="form-check-label" for="select17">
                      Management
                    </label>
                  </div>
                  <div class="form-check ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="select18"
                      value="Science"
                    />
                    <label class="form-check-label" for="select18">
                      Science
                    </label>
                  </div>
                  <div class="form-check ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="select19"
                      value="Others "
                    />
                    <label class="form-check-label" for="select19">
                      Others{" "}
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="next-prev-btn d-flex justify-content-between">
                  <a href="#" class="btn btn-default">
                    {" "}
                    <img src={arrow1} alt="" /> Back
                  </a>
                  <a href="#" class="btn btn-default">
                    {" "}
                    Next <img src={arrow1} alt="" />{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Services;
