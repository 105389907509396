import React, { Component } from "react";

//images
import arrow1 from "../../../include/images/arrow-1.svg";
import facebook from "../../../include/images/facebook.png";
import youtube from "../../../include/images/youtube.png";
import instagram from "../../../include/images/instagram.png";
import linkedin from "../../../include/images/linkedin.png";
import twitter from "../../../include/images/twitter.png";
import whatsapp from "../../../include/images/whatsapp.png";
import telegram from "../../../include/images/telegram.png";
import blog from "../../../include/images/blog.png";

//compoennt

class SocialMedia extends Component {
  render() {
    return (
      <>
        <div class="main-container">
          <div class="heading heading-with-btn">
            <h3>Social Media</h3>
            <div class="heading_shap"></div>
            <a href="#" class="btn btn-default">
              {" "}
              Skip <img src={arrow1} alt="" />{" "}
            </a>
          </div>
          <div class="dashboard-box">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Facebook URL</label>
                  <div class="input-group">
                    <span class="input-group-text">
                      <img src={facebook} alt="" />
                    </span>
                    <input type="text" class="form-control" placeholder="" />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Youtube URL</label>
                  <div class="input-group">
                    <span class="input-group-text">
                      <img src={youtube} alt="" />
                    </span>
                    <input type="text" class="form-control" placeholder="" />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Instgram URL</label>
                  <div class="input-group">
                    <span class="input-group-text">
                      <img src={instagram} alt="" />
                    </span>
                    <input type="text" class="form-control" placeholder="" />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Linked URL</label>
                  <div class="input-group">
                    <span class="input-group-text">
                      <img src={linkedin} alt="" />
                    </span>
                    <input type="text" class="form-control" placeholder="" />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Twitter URL</label>
                  <div class="input-group">
                    <span class="input-group-text">
                      <img src={twitter} alt="" />
                    </span>
                    <input type="text" class="form-control" placeholder="" />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Whatsapp URL</label>
                  <div class="input-group">
                    <span class="input-group-text">
                      <img src={whatsapp} alt="" />
                    </span>
                    <input type="text" class="form-control" placeholder="" />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Telegram URL</label>
                  <div class="input-group">
                    <span class="input-group-text">
                      <img src={telegram} alt="" />
                    </span>
                    <input type="text" class="form-control" placeholder="" />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Blog URL</label>
                  <div class="input-group">
                    <span class="input-group-text">
                      <img src={blog} alt="" />
                    </span>
                    <input type="text" class="form-control" placeholder="" />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Blog URL</label>
                  <div class="input-group">
                    <span class="input-group-text">
                      <img src={blog} alt="" />
                    </span>
                    <input type="text" class="form-control" placeholder="" />
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="next-prev-btn d-flex justify-content-between">
                  <a href="#" class="btn btn-default">
                    {" "}
                    <img src={arrow1} alt="" /> Back
                  </a>
                  <a href="#" class="btn btn-default">
                    {" "}
                    Next <img src={arrow1} alt="" />{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SocialMedia;
