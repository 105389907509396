import React, { useState } from "react";

//components
import Input from "../common/form/input";
import { Link } from "react-router-dom";
import ReactSelect from "../common/form/reactSelect";

const Login = (props) => {
  const { category, catOptions, callingCodes, type, onChangeType } = props;

  //states
  const [phone, setPhone] = useState(null);
  const [password, setPassword] = useState("");

  return (
    <>
      <div class="form-container d-flex flex-column align-items-center justify-content-center">
        <h5>Login To get started !</h5>
        <div class="dashboard-box">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Select Category</label>
                <ReactSelect
                  options={catOptions}
                  placeholder={"Select Category ..."}
                  value={type}
                  onChange={onChangeType}
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Mobile Number</label>
                <div class="select-with-input">
                  <input
                    type="text"
                    class="phone-input form-control"
                    placeholder=""
                  />
                  <select class="form-control">
                    <option>+ 91</option>
                    <option>+ 92</option>
                    <option>+ 93</option>
                    <option>+ 94</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-12 position-relative">
              <Input name="password" label="Password" value={password} />
              <a href="#" className="forgot-password">
                Forgot Password ?
              </a>
            </div>
            {/* <div className="d-flex justify-content-end"></div> */}

            <div class="col-md-12">
              <div class="save-btn text-center">
                <a href="#" class="btn btn-default">
                  Login
                </a>
              </div>
            </div>
            <div class="col-md-12">
              <div class="save-btn text-center">
                <a href="#" class="btn btn-primary">
                  Login Via OTP
                </a>
              </div>
              <div class="new-to-meritbell text-center mt-4">
                New To MeritBell ? <Link to="/signup">Sign up</Link>{" "}
              </div>
            </div>
            <div class="or-cont col-md-12">
              <h6 className="or-text position-relative text-center m-0">OR</h6>
            </div>
            <div class="col-md-12 mt-4">
              <div class="comparison-link-content">
                <a href="#" class="btn btn-default btn-block">
                  <div className="plus-container">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 448 512"
                    >
                      <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                    </svg>{" "}
                  </div>
                  List Your Business{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
