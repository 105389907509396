import React, { Component } from "react";
import { withRouter } from "../utils/withRouter";

//component
import DashboardHeader from "./dashboardHeader";
import DashboardSidebar from "./dashboardSidebar";
import Address from "./dashboardTabs/address";
import Banking from "./dashboardTabs/banking";
import BasicDetail from "./dashboardTabs/basicDetail";
import Certifications from "./dashboardTabs/certifications";
import CompanyDetail from "./dashboardTabs/companyDetail";
import ContactDetail from "./dashboardTabs/contactDetail";
import Dash from "./dashboardTabs/dash";
import Facilities from "./dashboardTabs/facilities";
import Gallery from "./dashboardTabs/gallery";
import HwoToReach from "./dashboardTabs/hwoToReach";
import Services from "./dashboardTabs/services";
import SocialMedia from "./dashboardTabs/socialMedia";
import Timing from "./dashboardTabs/timing";

class Dashboard extends Component {
  state = {
    mobSidebar: false,
  };

  componentDidMount = () => {
    document.body.classList.add("has-sidebar");
  };

  componentWillUnmount = () => {
    document.body.classList.remove("has-sidebar");
  };

  toggleMobSideBar = (e) => {
    if (e) e.preventDefault();
    if (this.state.mobSidebar) {
      this.setState({ mobSidebar: false }, () =>
        document.body.classList.remove("sidebar-open")
      );
    } else {
      this.setState({ mobSidebar: true }, () =>
        document.body.classList.add("sidebar-open")
      );
    }
  };
  render() {
    const pathname = this.props.router.location.pathname;
    return (
      <>
        <DashboardHeader
          toggleMobSideBar={this.toggleMobSideBar}
          mobSidebar={this.state.mobSidebar}
        />
        <DashboardSidebar
          page="dashboard"
          toggleMobSideBar={this.toggleMobSideBar}
          mobSidebar={this.state.mobSidebar}
        />
        {pathname === "/dashboard" && <Dash />}
        {pathname === "/dashboard/basic-detail" && <BasicDetail />}
        {pathname === "/dashboard/contact-detail" && <ContactDetail />}
        {pathname === "/dashboard/address" && <Address />}
        {pathname === "/dashboard/company-detail" && <CompanyDetail />}
        {pathname === "/dashboard/service" && <Services />}
        {pathname === "/dashboard/gallery" && <Gallery />}
        {pathname === "/dashboard/facilities" && <Facilities />}
        {pathname === "/dashboard/social-media" && <SocialMedia />}
        {pathname === "/dashboard/certification" && <Certifications />}
        {pathname === "/dashboard/timing" && <Timing />}
        {pathname === "/dashboard/how-to-reach" && <HwoToReach />}
        {pathname === "/dashboard/banking" && <Banking />}
      </>
    );
  }
}

export default withRouter(Dashboard);
