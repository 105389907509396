import React, { Component } from "react";

//imaegs
import arrow1 from "../../../include/images/arrow-1.svg";

//Compoennt

import Form from "../../common/form/form";

class CompanyDetail extends Form {
  render() {
    return (
      <>
        <div class="main-container">
          <div class="heading ">
            <h3>Company information( Business Proof)</h3>
            <div class="heading_shap"></div>
          </div>
          <div class="dashboard-box">
            <div class="row">
              <div class="col-md-12">
                <div class="sub-heading d-flex">
                  <h5>Company Registrations : </h5>
                  <div class="form-check-group d-flex type2 ms-5">
                    <div class="form-check ">
                      <input
                        class="form-check-input"
                        type="radio"
                        id="select1"
                        name="yesNoe"
                        value="Yes"
                        checked
                      />
                      <label class="form-check-label" for="select1">
                        Yes
                      </label>
                    </div>
                    <div class="form-check ">
                      <input
                        class="form-check-input"
                        type="radio"
                        id="select2"
                        name="yesNoe"
                        value="No"
                      />
                      <label class="form-check-label" for="select2">
                        No
                      </label>
                    </div>
                  </div>
                  <small class="ms-5">( If Yes )</small>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select class="form-control">
                    <option>Select Type Of Registration </option>
                    <option> Option 1</option>
                    <option> Option 2</option>
                    <option> Option 3</option>
                    <option> Option 4</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                {this.renderInput("specifyName", "", "Secify Name (If any)")}
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select class="form-control">
                    <option>Upload Any Document </option>
                    <option> Option 1</option>
                    <option> Option 2</option>
                    <option> Option 3</option>
                    <option> Option 4</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6 ">
                <div class="form-group">
                  <input
                    type="file"
                    placeholder="Land Mark"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="sub-heading">
                  <h5>
                    Verification Document of Owner / counselor ( For
                    Identification ){" "}
                  </h5>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select class="form-control">
                    <option>Upload Any Document </option>
                    <option> Option 1</option>
                    <option> Option 2</option>
                    <option> Option 3</option>
                    <option> Option 4</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6 ">
                <div class="form-group">
                  <input
                    type="file"
                    placeholder="Land Mark"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="sub-heading">
                  <h5>Counselor image : </h5>
                </div>
              </div>
              <div class="col-md-6 ">
                <div class="form-group">
                  <input
                    type="file"
                    placeholder="Land Mark"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="next-prev-btn d-flex justify-content-between">
                  <a href="#" class="btn btn-default">
                    <img src={arrow1} alt="" /> Back
                  </a>
                  <a href="#" class="btn btn-default">
                    Next <img src={arrow1} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CompanyDetail;
