import React, { Component } from "react";

//imaegs
import arrow1 from "../../../include/images/arrow-1.svg";

//component

import Form from "../../common/form/form";

class ContactDetail extends Form {
  state = {
    data: {},
    errors: {},
  };

  render() {
    return (
      <>
        <div class="main-container">
          <div class="heading ">
            <h3>Contact Details</h3>
            <div class="heading_shap"></div>
          </div>
          <div class="dashboard-box">
            <div class="row">
              <div class="col-md-6">
                {this.renderInput("contactPerson", "Contact Person", "Name")}
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Designation (Job Role)</label>
                  <select class="form-control">
                    <option>Select </option>
                    <option> Option 1</option>
                    <option> Option 2</option>
                    <option> Option 3</option>
                    <option> Option 4</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                {this.renderInput(
                  "primaryEmail",
                  "Email Address",
                  "Primary Email",
                  "email"
                )}
              </div>
              <div class="col-md-6">
                {this.renderInput(
                  "alternateEmail",
                  "Email Address",
                  "Alternate Email",
                  "email"
                )}
              </div>
              <div class="col-md-6">
                {this.renderInput(
                  "whatsappNumber",
                  "Whatsapp Number",
                  "",
                  "number"
                )}
              </div>
              <div class="col-md-6">
                {this.renderInput(
                  "landLineNumber",
                  "Landline Number",
                  "",
                  "number"
                )}
              </div>
              <div class="col-md-12">
                <div class="next-prev-btn d-flex justify-content-between">
                  <a href="#" class="btn btn-default">
                    {" "}
                    <img src={arrow1} alt="" /> Back
                  </a>
                  <a href="#" class="btn btn-default">
                    {" "}
                    Next <img src={arrow1} alt="" />{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ContactDetail;
