import { BrowserRouter, Routes, Route } from "react-router-dom";
import College from "./component/college/college";
import Consultacncy from "./component/consultancy/consultancy";
import Dashboard from "./component/dashboard/dashboard";

import Home from "./component/home/home";
import AuthLayout from "./component/auth/authLayout";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login/:category" element={<AuthLayout />} />
        <Route path="/signup/:category" element={<AuthLayout />} />
        <Route path="/consultant" element={<Consultacncy />} />
        <Route path="/college" element={<College />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/basic-detail" element={<Dashboard />} />
        <Route path="/dashboard/contact-detail" element={<Dashboard />} />
        <Route path="/dashboard/address" element={<Dashboard />} />
        <Route path="/dashboard/company-detail" element={<Dashboard />} />
        <Route path="/dashboard/service" element={<Dashboard />} />
        <Route path="/dashboard/gallery" element={<Dashboard />} />
        <Route path="/dashboard/facilities" element={<Dashboard />} />
        <Route path="/dashboard/social-media" element={<Dashboard />} />
        <Route path="/dashboard/certification" element={<Dashboard />} />
        <Route path="/dashboard/timing" element={<Dashboard />} />
        <Route path="/dashboard/banking" element={<Dashboard />} />
        <Route path="/dashboard/how-to-reach" element={<Dashboard />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
