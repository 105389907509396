import React, { useEffect, useState } from "react";
import Logo from "../common/logo";

//img
import headerCall from "../../include/images/header-call.svg";
import headerBell from "../../include/images/header-bell.svg";
import headerMail from "../../include/images/header-mail.svg";
import profileHead from "../../include/images/progile-head.jpg";

const DashboardHeader = (props) => {
  return (
    <header class="header" id="header2">
      <nav class="navbar navbar-expand-xl">
        <div class="container-fluid">
          <div class="nav-inside d-flex align-items-center justify-content-between">
            <div
              id="nav-icon"
              class=" d-block d-xl-none"
              onClick={() => props.toggleMobSideBar()}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <Logo />
            <div class="header-right ml-auto d-flex align-items-center">
              <a href="tel:44" class="">
                <img src={headerCall} alt="" />
              </a>
              <a href="#!" class="">
                <img src={headerBell} alt="" />
              </a>
              <a href="mailto:domain@gmail.com" class="">
                <img src={headerMail} alt="" />
              </a>
              <div class="header-profile position-relative">
                <a
                  href="#"
                  class="hp-img dropdown-toggle"
                  type="role"
                  data-mdb-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={profileHead} alt="" />
                  <ul class="dropdown-menu dropdown-menu-end">
                    <li class="dropdown-item">
                      <a href="#!" class="dropdown-link">
                        option 1
                      </a>
                    </li>
                    <li class="dropdown-item">
                      <a href="#!" class="dropdown-link">
                        option 2
                      </a>
                    </li>
                    <li class="dropdown-item">
                      <a href="#!" class="dropdown-link">
                        option 3
                      </a>
                    </li>
                  </ul>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};
export default DashboardHeader;
