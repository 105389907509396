import React, { Component } from "react";

//images
import arrow1 from "../../../include/images/arrow-1.svg";

//Component

import Form from "../../common/form/form";

class Address extends Form {
  state = {
    data: {},
    errors: {},
  };

  render() {
    return (
      <>
        <div class="main-container">
          <div class="heading heading-with-btn">
            <h3>Address</h3>
            <div class="heading_shap"></div>
            <a href="#" class="btn btn-default">
              {" "}
              Skip <img src={arrow1} alt="" />{" "}
            </a>
          </div>
          <div class="dashboard-box">
            <div class="row">
              <div class="col-md-12">
                <div class="sub-heading">
                  <h5>
                    Registerd Office : <small>( In India )</small>
                  </h5>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select class="form-control">
                    <option>Select State </option>
                    <option> Option 1</option>
                    <option> Option 2</option>
                    <option> Option 3</option>
                    <option> Option 4</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select class="form-control">
                    <option>Select State </option>
                    <option> Option 1</option>
                    <option> Option 2</option>
                    <option> Option 3</option>
                    <option> Option 4</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select class="form-control">
                    <option>Area </option>
                    <option> Option 1</option>
                    <option> Option 2</option>
                    <option> Option 3</option>
                    <option> Option 4</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select class="form-control">
                    <option>Sub - Area </option>
                    <option> Option 1</option>
                    <option> Option 2</option>
                    <option> Option 3</option>
                    <option> Option 4</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                {this.renderInput("landMark", "", "Land Mark")}
              </div>
              <div class="col-md-6 col-lg-4">
                {this.renderInput("houseNo", "", "House No")}
              </div>
              <div class="col-md-6 col-lg-4">
                {this.renderInput("buildingName", "", "Building Name")}
              </div>
              <div class="col-md-6 col-lg-4">
                {this.renderInput("landMark", "", "Land Mark")}
              </div>
              <div class="col-md-6 col-lg-4">
                {this.renderInput("getMyLocation", "", "Get My Location")}
              </div>
              <div class="col-md-6 col-lg-4">
                {this.renderInput("pincode", "", "Pincode", "number")}
              </div>
              <div class="col-md-12">
                <div class="sub-heading">
                  <h5>
                    Registerd Office : <small>( In Abroad )</small>
                  </h5>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select class="form-control">
                    <option>Select Country </option>
                    <option> Option 1</option>
                    <option> Option 2</option>
                    <option> Option 3</option>
                    <option> Option 4</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select class="form-control">
                    <option>Select Region </option>
                    <option> Option 1</option>
                    <option> Option 2</option>
                    <option> Option 3</option>
                    <option> Option 4</option>
                  </select>
                </div>
              </div>
              <div class="col-md-12">
                {this.renderInput(
                  "postalAddress",
                  "",
                  "Complete Postal Address"
                )}
              </div>
              <div class="col-md-12">
                <div class="sub-heading">
                  <h5>
                    Do You Have Office : <small>( If Yes )</small>
                  </h5>
                  <p> Report Aboute Section</p>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-check-group d-flex type2">
                  <div class="form-check ">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="select3"
                      name="yesNo"
                      value="Yes"
                      checked
                    />
                    <label class="form-check-label" for="select3">
                      Yes
                    </label>
                  </div>
                  <div class="form-check ">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="select4"
                      name="yesNo"
                      value="No"
                    />
                    <label class="form-check-label" for="select4">
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="next-prev-btn d-flex justify-content-between">
                  <a href="#" class="btn btn-default">
                    {" "}
                    <img src={arrow1} alt="" /> Back
                  </a>
                  <a href="#" class="btn btn-default">
                    {" "}
                    Next <img src={arrow1} alt="" />{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Address;
