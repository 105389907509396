import React, { Component } from "react";

//images
import arrow1 from "../../include/images/arrow-1.svg";
import iconUniv from "../../include/images/icon-university.svg";
import iconcollege from "../../include/images/icon-college.svg";
import iconSchool from "../../include/images/icon-school.svg";
import iconNIT from "../../include/images/icon-nits.svg";
import iconIIm from "../../include/images/icon-iim.svg";
import iconAdms3 from "../../include/images/icon-addmision3.svg";
import iconAdms from "../../include/images/icon-admission.svg";
import iconECoiuns from "../../include/images/icon-e-counceling.svg";
import iconRank from "../../include/images/icon-rank.svg";
import iconDesign from "../../include/images/icon-design.svg";
import iconParaMed from "../../include/images/icon-paramedical.svg";
import iconLaw from "../../include/images/icon-law.svg";
import iconPharmacy from "../../include/images/icon-pharmacy.svg";
import iconarch from "../../include/images/icon-architecture.svg";
import iconArts from "../../include/images/icon-arts.svg";
import iconScience from "../../include/images/icon-science.svg";
import iconMed from "../../include/images/icon-medical.svg";
import iconEng from "../../include/images/icon-engineering.svg";
import iconMang from "../../include/images/icon-management.svg";
import iconScholar from "../../include/images/icon-scholorship.svg";
import iconeduLoan from "../../include/images/icon-edu-laon.svg";
import iconFellow from "../../include/images/icon-fellowship.svg";
import iconCrf4 from "../../include/images/icon-crf-4.svg";
import iconCrf3 from "../../include/images/icon-crf-3.svg";
import iconCrf2 from "../../include/images/icon-crf-2.svg";
import iconCrf1 from "../../include/images/icon-crf-1.svg";
import iconPlus from "../../include/images/icon-plus.svg";
import playicon from "../../include/images/youtube-play-icon.svg";
import reviewConst from "../../include/images/review-consultant.svg";
import btnComp2 from "../../include/images/btn-camparison2.svg";
import compImg from "../../include/images/camparison-img.png";
import contactUs from "../../include/images/contact-us.svg";
import howWork from "../../include/images/how-work.svg";
import whyUs from "../../include/images/why-us.svg";
import whatWeDo from "../../include/images/what-we-do.svg";
import school from "../../include/images/school.svg";
import school1 from "../../include/images/school-img1.jpg";
import school2 from "../../include/images/school-img2.jpg";
import school3 from "../../include/images/school-img3.jpg";
import courseType2 from "../../include/images/course-type2.svg";
import courseType3 from "../../include/images/course-type3.svg";
import placement from "../../include/images/placement.svg";
import exploreBtn from "../../include/images/explre-btn-icon.svg";
import applyBtn from "../../include/images/apply-btn-icon.svg";

//component
import Header from "../common/header";
import Hero from "../common/hero";
import Slider from "react-slick";
import NewsLetter from "../common/newLetter";
import Footer from "../common/footer";

class College extends Component {
  state = {
    exploreCollege: "course",
    location: "state",
    distanceCourse: "course",
    findCollege: "course",
  };

  settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    infinite: true,
    centerMode: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          adaptiveHeight: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          adaptiveHeight: false,
        },
      },
    ],
  };

  settings1 = {
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    infinite: true,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          adaptiveHeight: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          adaptiveHeight: false,
        },
      },
    ],
  };

  render() {
    return (
      <>
        <Header page="college" />
        <div id="main">
          <Hero />
          <div class="content-container ">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="heading">
                    <h3>Explore Colleges & Universities</h3>
                    <div class="heading_shap"></div>
                    <p>Find colleges & Universities by differant Categories</p>
                  </div>
                  <div class="grid-container">
                    <ul class="grid-list type_4 d-flex flex-wrap">
                      <li class="grid-item">
                        <a href="#" class="grid-box">
                          <div class="grid-content">
                            <div class="grid-img">
                              <img src={iconUniv} alt="" />
                            </div>
                            <h5>University</h5>
                          </div>
                        </a>
                      </li>
                      <li class="grid-item">
                        <a href="#" class="grid-box">
                          <div class="grid-content">
                            <div class="grid-img">
                              <img src={iconcollege} alt="" />
                            </div>
                            <h5>Gov. Colleges</h5>
                          </div>
                        </a>
                      </li>
                      <li class="grid-item">
                        <a href="#" class="grid-box">
                          <div class="grid-content">
                            <div class="grid-img">
                              <img src={iconSchool} alt="" />
                            </div>
                            <h5>IIITs </h5>
                          </div>
                        </a>
                      </li>
                      <li class="grid-item">
                        <a href="#" class="grid-box">
                          <div class="grid-content">
                            <div class="grid-img">
                              <img src={iconNIT} alt="" />
                            </div>
                            <h5>GFITs</h5>
                          </div>
                        </a>
                      </li>
                      <li class="grid-item">
                        <a href="#" class="grid-box">
                          <div class="grid-content">
                            <div class="grid-img">
                              <img src={iconIIm} alt="" />
                            </div>
                            <h6>IIHM</h6>
                          </div>
                        </a>
                      </li>
                      <li class="grid-item">
                        <a href="#" class="grid-box">
                          <div class="grid-content">
                            <div class="grid-img">
                              <img src={iconIIm} alt="" />
                            </div>
                            <h6>NIFT </h6>
                          </div>
                        </a>
                      </li>
                    </ul>
                    <div class="show-more-btn text-center">
                      <a href="#" class="btn btn-primary">
                        Show More <img src={arrow1} alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container grey-bg1">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="heading blue-heading">
                    <h3>Explore Services by Categories</h3>
                    <div class="heading_shap"></div>
                  </div>
                  <div class="grid-container">
                    <ul class="grid-list square type_2 d-flex flex-wrap">
                      <li class="grid-item">
                        <a href="#" class="grid-box">
                          <div class="grid-content">
                            <div class="grid-img">
                              <img src={reviewConst} alt="" />
                            </div>
                            <h5>
                              Review
                              <br /> & Ratings
                            </h5>
                          </div>
                        </a>
                      </li>
                      <li class="grid-item">
                        <a href="#" class="grid-box">
                          <div class="grid-content">
                            <div class="grid-img">
                              <img src={iconRank} alt="" />
                            </div>
                            <h5>Rank</h5>
                          </div>
                        </a>
                      </li>
                      <li class="grid-item">
                        <a href="#" class="grid-box">
                          <div class="grid-content">
                            <div class="grid-img">
                              <img src={iconECoiuns} alt="" />
                            </div>
                            <h5>E- Counseling</h5>
                          </div>
                        </a>
                      </li>
                      <li class="grid-item">
                        <a href="#" class="grid-box">
                          <div class="grid-content">
                            <div class="grid-img">
                              <img src={iconAdms} alt="" />
                            </div>
                            <h5>Admission</h5>
                          </div>
                        </a>
                      </li>
                      <li class="grid-item">
                        <a href="#" class="grid-box">
                          <div class="grid-content">
                            <div class="grid-img">
                              <img src={iconAdms3} alt="" />
                            </div>
                            <h5>Admission</h5>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container btn-tabs-container">
            <div class="container container1">
              <div class="row">
                <div class="col-md-12">
                  <div class="heading">
                    <h3>Explore Colleges by Courses & Exam</h3>
                    <div class="heading_shap"></div>
                  </div>
                  <div class="merit-tabs">
                    <ul
                      class="nav nav-tabs with-shadow"
                      id="ex1"
                      role="tablist"
                    >
                      <li class="nav-item" role="presentation">
                        <a
                          class={
                            this.state.exploreCollege === "course"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="ex1-tab-20"
                          data-mdb-toggle="tab"
                          href="#ex1-tabs-20"
                          role="tab"
                          aria-controls="ex1-tabs-20"
                          aria-selected="true"
                          onClick={(e) => {
                            if (e) e.preventDefault();
                            this.setState({ exploreCollege: "course" });
                          }}
                        >
                          Course
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          class={
                            this.state.exploreCollege === "exam"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="ex1-tab-21"
                          data-mdb-toggle="tab"
                          href="#ex1-tabs-21"
                          role="tab"
                          aria-controls="ex1-tabs-21"
                          aria-selected="false"
                          onClick={(e) => {
                            if (e) e.preventDefault();
                            this.setState({ exploreCollege: "exam" });
                          }}
                        >
                          Exam
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="meril-tab-body">
                    <div class="tab-content" id="ex1-content">
                      <div
                        class={
                          this.state.exploreCollege === "course"
                            ? "tab-pane fade active show"
                            : "tab-pane fade"
                        }
                        id="ex1-tabs-20"
                        role="tabpane20"
                        aria-labelledby="ex1-tab-20"
                      >
                        <div class="btn-tabs-container">
                          <div class="btn-tabs-box">
                            <a href="#" class="btn-tabs-link">
                              BE/B.tech
                            </a>
                            <a href="#" class="btn-tabs-link">
                              PGDM
                            </a>
                            <a href="#" class="btn-tabs-link">
                              B.Com
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BA
                            </a>
                            <a href="#" class="btn-tabs-link">
                              B.PHA
                            </a>
                            <a href="#" class="btn-tabs-link">
                              LLB
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BSC
                            </a>
                            <a href="#" class="btn-tabs-link">
                              GNM
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BUMS
                            </a>
                            <a href="#" class="btn-tabs-link">
                              MBBS
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BBA
                            </a>
                            <a href="#" class="btn-tabs-link">
                              B.Arch
                            </a>
                            <a href="#" class="btn-tabs-link">
                              MBA
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BHMS
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BVSc
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BHA
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BHM
                            </a>
                          </div>
                          <div class="show-more-btn text-center">
                            <a href="#" class="btn btn-default">
                              See More <img src={arrow1} alt="" />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        class={
                          this.state.exploreCollege === "exam"
                            ? "tab-pane fade active show"
                            : "tab-pane fade"
                        }
                        id="ex1-tabs-21"
                        role="tabpane21"
                        aria-labelledby="ex1-tab-21"
                      >
                        <div class="btn-tabs-container">
                          <div class="btn-tabs-box">
                            <a href="#" class="btn-tabs-link">
                              BE/B.tech
                            </a>
                            <a href="#" class="btn-tabs-link">
                              PGDM
                            </a>
                            <a href="#" class="btn-tabs-link">
                              B.Com
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BA
                            </a>
                            <a href="#" class="btn-tabs-link">
                              B.PHA
                            </a>
                            <a href="#" class="btn-tabs-link">
                              LLB
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BSC
                            </a>
                            <a href="#" class="btn-tabs-link">
                              GNM
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BUMS
                            </a>
                            <a href="#" class="btn-tabs-link">
                              MBBS
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BBA
                            </a>
                            <a href="#" class="btn-tabs-link">
                              B.Arch
                            </a>
                            <a href="#" class="btn-tabs-link">
                              MBA
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BHMS
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BVSc
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BHA
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BHM
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BE/B.tech
                            </a>
                            <a href="#" class="btn-tabs-link">
                              PGDM
                            </a>
                            <a href="#" class="btn-tabs-link">
                              B.Com
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BA
                            </a>
                            <a href="#" class="btn-tabs-link">
                              B.PHA
                            </a>
                            <a href="#" class="btn-tabs-link">
                              LLB
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BSC
                            </a>
                            <a href="#" class="btn-tabs-link">
                              GNM
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BUMS
                            </a>
                            <a href="#" class="btn-tabs-link">
                              MBBS
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BBA
                            </a>
                            <a href="#" class="btn-tabs-link">
                              B.Arch
                            </a>
                          </div>
                          <div class="show-more-btn text-center">
                            <a href="#" class="btn btn-default">
                              See More <img src={arrow1} alt="" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container btn-tabs-container grey-bg4">
            <div class="container container1">
              <div class="row">
                <div class="col-md-12">
                  <div class="heading blue-heading">
                    <h3>Explore Colleges by Location</h3>
                    <div class="heading_shap"></div>
                    <p>Find Consultant For School By Location & Grades</p>
                  </div>
                  <div class="merit-tabs">
                    <ul class="nav nav-tabs bg_red" id="ex1" role="tablist">
                      <li class="nav-item" role="presentation">
                        <a
                          class={
                            this.state.location === "state"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="ex1-tab-22"
                          data-mdb-toggle="tab"
                          href="#ex1-tabs-22"
                          role="tab"
                          aria-controls="ex1-tabs-22"
                          aria-selected="true"
                          onClick={(e) => {
                            if (e) e.preventDefault();
                            this.setState({ location: "state" });
                          }}
                        >
                          states
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          class={
                            this.state.location === "city"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="ex1-tab-23"
                          data-mdb-toggle="tab"
                          href="#ex1-tabs-23"
                          role="tab"
                          aria-controls="ex1-tabs-23"
                          aria-selected="false"
                          onClick={(e) => {
                            if (e) e.preventDefault();
                            this.setState({ location: "city" });
                          }}
                        >
                          City
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="meril-tab-body">
                    <div class="tab-content" id="ex1-content">
                      <div
                        class={
                          this.state.location === "state"
                            ? "tab-pane fade active show"
                            : "tab-pane fade"
                        }
                        id="ex1-tabs-22"
                        role="tabpane22"
                        aria-labelledby="ex1-tab-22"
                      >
                        <div class="btn-tabs-container">
                          <div class="btn-tabs-box type_2">
                            <a href="#" class="btn-tabs-link">
                              Uttar Pradesh
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Madhya Pradesh
                            </a>
                            <a href="#" class="btn-tabs-link">
                              West Bengal
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Andhra Pradesh
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Maharashtra
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Rajasthan
                            </a>
                            <a href="#" class="btn-tabs-link">
                              NewDelhi
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Tamil Nadu
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Karnataka
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Kerala
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Uttarakhand
                            </a>
                          </div>
                          <div class="show-more-btn text-center">
                            <a href="#" class="btn btn-primary">
                              Show More <img src={arrow1} alt="" />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        class={
                          this.state.location === "city"
                            ? "tab-pane fade active show"
                            : "tab-pane fade"
                        }
                        id="ex1-tabs-23"
                        role="tabpane23"
                        aria-labelledby="ex1-tab-23"
                      >
                        <div class="btn-tabs-container">
                          <div class="btn-tabs-box type_2">
                            <a href="#" class="btn-tabs-link">
                              Banguluru
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Mumbai
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Pune
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Chennai
                            </a>
                            <a href="#" class="btn-tabs-link">
                              New Delhi
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Noida
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Kolkata
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Jaipur
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Hyderabad
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Bhopal
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Lucknow
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Coimbtore
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Chandigarh
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Ahemdabad
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Dehradun
                            </a>
                          </div>
                          <div class="show-more-btn text-center">
                            <a href="#" class="btn btn-primary">
                              Show More <img src={arrow1} alt="" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="heading ">
                    <h3>Distance / Correspondance College</h3>
                    <div class="heading_shap"></div>
                  </div>
                  <div class="merit-tabs">
                    <ul
                      class="nav nav-tabs with-shadow"
                      id="ex1"
                      role="tablist"
                    >
                      <li class="nav-item" role="presentation">
                        <a
                          class={
                            this.state.distanceCourse === "course"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="ex1-tab-24"
                          data-mdb-toggle="tab"
                          href="#ex1-tabs-24"
                          role="tab"
                          aria-controls="ex1-tabs-24"
                          aria-selected="true"
                          onClick={(e) => {
                            if (e) e.preventDefault();
                            this.setState({ distanceCourse: "course" });
                          }}
                        >
                          Course
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          class={
                            this.state.distanceCourse === "location"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="ex1-tab-25"
                          data-mdb-toggle="tab"
                          href="#ex1-tabs-25"
                          role="tab"
                          aria-controls="ex1-tabs-25"
                          aria-selected="false"
                          onClick={(e) => {
                            if (e) e.preventDefault();
                            this.setState({ distanceCourse: "location" });
                          }}
                        >
                          Location
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="meril-tab-body">
                    <div class="tab-content" id="ex1-content">
                      <div
                        class={
                          this.state.distanceCourse === "course"
                            ? "tab-pane fade active show"
                            : "tab-pane fade"
                        }
                        id="ex1-tabs-24"
                        role="tabpane24"
                        aria-labelledby="ex1-tab-24"
                      >
                        <ul class="grid-list square type_3 d-flex flex-wrap">
                          <li class="grid-item">
                            <a href="#" class="grid-box">
                              <div class="grid-content">
                                <div class="grid-img">
                                  <img src={iconMang} alt="" />
                                </div>
                                <h4>Management</h4>
                              </div>
                            </a>
                          </li>
                          <li class="grid-item">
                            <a href="#" class="grid-box">
                              <div class="grid-content">
                                <div class="grid-img">
                                  <img src={iconEng} alt="" />
                                </div>
                                <h4>Engineering</h4>
                              </div>
                            </a>
                          </li>
                          <li class="grid-item">
                            <a href="#" class="grid-box">
                              <div class="grid-content">
                                <div class="grid-img">
                                  <img src={iconMed} alt="" />
                                </div>
                                <h4>Medical</h4>
                              </div>
                            </a>
                          </li>
                          <li class="grid-item">
                            <a href="#" class="grid-box">
                              <div class="grid-content">
                                <div class="grid-img">
                                  <img src={iconScience} alt="" />
                                </div>
                                <h4>Science</h4>
                              </div>
                            </a>
                          </li>
                          <li class="grid-item">
                            <a href="#" class="grid-box">
                              <div class="grid-content">
                                <div class="grid-img">
                                  <img src={iconArts} alt="" />
                                </div>
                                <h4>Arts</h4>
                              </div>
                            </a>
                          </li>
                          <li class="grid-item">
                            <a href="#" class="grid-box">
                              <div class="grid-content">
                                <div class="grid-img">
                                  <img src={iconarch} alt="" />
                                </div>
                                <h4>Architecture</h4>
                              </div>
                            </a>
                          </li>
                          <li class="grid-item">
                            <a href="#" class="grid-box">
                              <div class="grid-content">
                                <div class="grid-img">
                                  <img src={iconPharmacy} alt="" />
                                </div>
                                <h4>Pharmacy</h4>
                              </div>
                            </a>
                          </li>
                          <li class="grid-item">
                            <a href="#" class="grid-box">
                              <div class="grid-content">
                                <div class="grid-img">
                                  <img src={iconLaw} alt="" />
                                </div>
                                <h4>Law</h4>
                              </div>
                            </a>
                          </li>
                          <li class="grid-item">
                            <a href="#" class="grid-box">
                              <div class="grid-content">
                                <div class="grid-img">
                                  <img src={iconParaMed} alt="" />
                                </div>
                                <h4>Paramedical</h4>
                              </div>
                            </a>
                          </li>
                          <li class="grid-item">
                            <a href="#" class="grid-box">
                              <div class="grid-content">
                                <div class="grid-img">
                                  <img src={iconDesign} alt="" />
                                </div>
                                <h4>Design</h4>
                              </div>
                            </a>
                          </li>
                        </ul>
                        <div class="show-more-btn text-center">
                          <a href="#" class="btn btn-primary">
                            Show More <img src={arrow1} alt="" />
                          </a>
                        </div>
                      </div>
                      <div
                        class={
                          this.state.distanceCourse === "location"
                            ? "tab-pane fade active show"
                            : "tab-pane fade"
                        }
                        id="ex1-tabs-25"
                        role="tabpane25"
                        aria-labelledby="ex1-tab-25"
                      >
                        <div class="btn-tabs-container">
                          <div class="btn-tabs-box type_2">
                            <a href="#" class="btn-tabs-link">
                              Uttar Pradesh
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Madhya Pradesh
                            </a>
                            <a href="#" class="btn-tabs-link">
                              West Benga
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Andhra Pradesh
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Maharashtra
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Rajasthan
                            </a>
                            <a href="#" class="btn-tabs-link">
                              NewDelhi
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Tamil Nadu
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Karnataka
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Kerala
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Uttarakhand
                            </a>
                          </div>
                          <div class="show-more-btn text-center">
                            <a href="#" class="btn btn-default">
                              Show More <img src={arrow1} alt="" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container finance-education-container grey-bg">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="heading blue-heading">
                    <h3>Finance Your Education</h3>
                    <div class="heading_shap"></div>
                  </div>
                  <div class="fe-container">
                    <ul class="fe-list mobile-slider d-flex flex-wrap">
                      <li class="fe-item">
                        <a href="#" class="fe-box">
                          <div class="fe-img">
                            <img src={iconScholar} alt="" />
                          </div>
                          <span class="fe-doller"> $ </span>
                          <span class="fe-rupee"> ₹ </span>
                          <div class="fe-content">
                            <h5>Scholorship</h5>
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been
                            </p>
                          </div>
                        </a>
                      </li>
                      <li class="fe-item">
                        <a href="#" class="fe-box">
                          <div class="fe-img">
                            <img src={iconeduLoan} alt="" />
                          </div>
                          <span class="fe-doller"> $ </span>
                          <span class="fe-rupee"> ₹ </span>
                          <div class="fe-content">
                            <h5>Education Loan</h5>
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been
                            </p>
                          </div>
                        </a>
                      </li>
                      <li class="fe-item">
                        <a href="#" class="fe-box">
                          <div class="fe-img">
                            <img src={iconFellow} alt="" />
                          </div>
                          <span class="fe-doller"> $ </span>
                          <span class="fe-rupee"> ₹ </span>
                          <div class="fe-content">
                            <h5>Fellowship</h5>
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been
                            </p>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container review-container">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
                  <div class="submit-review-container">
                    <div class="heading heading-white mb-4">
                      <h3>Submit Review Of Your College !</h3>
                      <div class="heading_shap"></div>
                      <p>
                        Rate your college across various parameters like
                        Infrastructure, Events, Industry Exposure Safety. Your
                        opinion matters the most to us.
                      </p>
                    </div>
                    <a href="#" class="btn btn-default">
                      Write a review
                      <img src={arrow1} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container comparison-container">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="heading">
                    <h3>Compare College</h3>
                    <div class="heading_shap"></div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="comparison-img">
                    <img src={compImg} alt="" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="comparison-link-content">
                    <a href="#" class="btn btn-default btn-block">
                      <img src={btnComp2} alt="" /> Compare Colleges{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container common-registration-form-container">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="heading blue-heading">
                    <h3>Common Registration Form (CRF)</h3>
                    <div class="heading_shap"></div>
                    <p>
                      1 single form and get access to 600+ colleges across India
                      !
                    </p>
                  </div>
                  <div class="block-container">
                    <ul class="block-list mobile-slider blue-dots d-flex flex-wrap">
                      <li class="block-item">
                        <div class="block-box">
                          <div class="block-content">
                            <div class="block-img">
                              <img src={iconCrf1} alt="" />
                            </div>
                            <h5>One platform to apply to 600+ colleges</h5>
                          </div>
                        </div>
                      </li>
                      <li class="block-item">
                        <div class="block-box">
                          <div class="block-content">
                            <div class="block-img">
                              <img src={iconCrf2} alt="" />
                            </div>
                            <h5>
                              Track & manage all your applications in one place
                            </h5>
                          </div>
                        </div>
                      </li>
                      <li class="block-item">
                        <div class="block-box">
                          <div class="block-content">
                            <div class="block-img">
                              <img src={iconCrf3} alt="" />
                            </div>
                            <h5>No need to visit College, Offline forms</h5>
                          </div>
                        </div>
                      </li>
                      <li class="block-item">
                        <div class="block-box">
                          <div class="block-content">
                            <div class="block-img">
                              <img src={iconCrf4} alt="" />
                            </div>
                            <h5>Upto 90% discount on your application fee</h5>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div class="text-center">
                      <a href="#" class="btn btn-default">
                        Start your Application Now
                        <img src={arrow1} alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container organisation-container grey-bg1">
            <div class="container">
              <div class="row">
                <div class="col-lg-8 offset-lg-2">
                  <div class="heading blue-heading">
                    <h3>Add Your College</h3>
                    <div class="heading_shap"></div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="organisation-content-container">
                    <div class="organisation-page-ink-content-outer">
                      <div class="organisation-page-link-list">
                        <div class="opl-item">
                          <a href="#!" class="opl-box">
                            <div class="opl-conetnt">
                              <div class="opl-img">
                                <img src={whatWeDo} alt="" />
                              </div>
                              <h6>What we do ?</h6>
                              <p>
                                {" "}
                                What do you think is better to receive after
                                each lesson: a lovely looking .
                              </p>
                              <span class="opl-link">Read more</span>
                            </div>
                          </a>
                        </div>
                        <div class="opl-item">
                          <a href="#!" class="opl-box">
                            <div class="opl-conetnt">
                              <div class="opl-img">
                                <img src={whyUs} alt="" />
                              </div>
                              <h6>Why with us ?</h6>
                              <p>
                                {" "}
                                What do you think is better to receive after
                                each lesson: a lovely looking .
                              </p>
                              <span class="opl-link">Read more</span>
                            </div>
                          </a>
                        </div>
                        <div class="opl-item">
                          <a href="#!" class="opl-box">
                            <div class="opl-conetnt">
                              <div class="opl-img">
                                <img src={howWork} alt="" />
                              </div>
                              <h6>How does it work</h6>
                              <p>
                                {" "}
                                What do you think is better to receive after
                                each lesson: a lovely looking .
                              </p>
                              <span class="opl-link">Read more</span>
                            </div>
                          </a>
                        </div>
                        <div class="opl-item">
                          <a href="#!" class="opl-box">
                            <div class="opl-conetnt">
                              <div class="opl-img">
                                <img src={contactUs} alt="" />
                              </div>
                              <h6> Contact Us</h6>
                              <p>
                                {" "}
                                What do you think is better to receive after
                                each lesson: a lovely looking .
                              </p>
                              <span class="opl-link">Read more</span>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="organisation-video-content">
                      <div class="organisation-video-box">
                        <div class="ratio ratio-16x9">
                          <iframe
                            src="https://www.youtube.com/embed/vlDzYIIOYmM"
                            title="YouTube video"
                            allowfullscreen
                          ></iframe>
                        </div>
                      </div>
                      <div class="organisation-video-caption">
                        <img src={playicon} alt="" />
                        <h6>THREE HOUR STDUY WITH ME UNIVERSITY</h6>
                      </div>
                      <div class="organisation-video-btn text-center">
                        <a href="#" class="btn btn-default">
                          <img src={iconPlus} alt="" />
                          List Institue / Business ...
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container btn-tabs-container">
            <div class="container container1">
              <div class="row">
                <div class="col-md-12">
                  <div class="heading blue-heading">
                    <h3>Find College by top courses & Exam</h3>
                    <div class="heading_shap"></div>
                  </div>
                  <div class="merit-tabs">
                    <ul
                      class="nav nav-tabs with-shadow"
                      id="ex1"
                      role="tablist"
                    >
                      <li class="nav-item" role="presentation">
                        <a
                          class={
                            this.state.findCollege === "course"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="ex1-tab-26"
                          data-mdb-toggle="tab"
                          href="#ex1-tabs-26"
                          role="tab"
                          aria-controls="ex1-tabs-26"
                          aria-selected="true"
                          onClick={(e) => {
                            if (e) e.preventDefault();
                            this.setState({ findCollege: "course" });
                          }}
                        >
                          Course
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          class={
                            this.state.findCollege === "exam"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="ex1-tab-27"
                          data-mdb-toggle="tab"
                          href="#ex1-tabs-27"
                          role="tab"
                          aria-controls="ex1-tabs-27"
                          aria-selected="false"
                          onClick={(e) => {
                            if (e) e.preventDefault();
                            this.setState({ findCollege: "exam" });
                          }}
                        >
                          Exam
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="meril-tab-body">
                    <div class="tab-content" id="ex1-content">
                      <div
                        class={
                          this.state.findCollege === "course"
                            ? "tab-pane fade active show"
                            : "tab-pane fade"
                        }
                        id="ex1-tabs-26"
                        role="tabpane26"
                        aria-labelledby="ex1-tab-26"
                      >
                        <div class="btn-tabs-container">
                          <div class="btn-tabs-box">
                            <a href="#" class="btn-tabs-link">
                              BE/B.tech
                            </a>
                            <a href="#" class="btn-tabs-link">
                              PGDM
                            </a>
                            <a href="#" class="btn-tabs-link">
                              B.Com
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BA
                            </a>
                            <a href="#" class="btn-tabs-link">
                              B.PHA
                            </a>
                            <a href="#" class="btn-tabs-link">
                              LLB
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BSC
                            </a>
                            <a href="#" class="btn-tabs-link">
                              GNM
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BUMS
                            </a>
                            <a href="#" class="btn-tabs-link">
                              MBBS
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BBA
                            </a>
                            <a href="#" class="btn-tabs-link">
                              B.Arch
                            </a>
                            <a href="#" class="btn-tabs-link">
                              MBA
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BHMS
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BVSc
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BHA
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BHM
                            </a>
                          </div>
                          <div class="show-more-btn text-center">
                            <a href="#" class="btn btn-default">
                              See More <img src={arrow1} alt="" />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        class={
                          this.state.findCollege === "exam"
                            ? "tab-pane fade active show"
                            : "tab-pane fade"
                        }
                        id="ex1-tabs-27"
                        role="tabpane27"
                        aria-labelledby="ex1-tab-27"
                      >
                        <div class="btn-tabs-container">
                          <div class="btn-tabs-box type_2">
                            <a href="#" class="btn-tabs-link">
                              NEET UG
                            </a>
                            <a href="#" class="btn-tabs-link">
                              NEET PG
                            </a>
                            <a href="#" class="btn-tabs-link">
                              JEE Mains.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              JEE Advance.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              CAT.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              MAT.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              GMAT.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              GATE.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              COMED-K.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              VITEEE.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              MHCET
                            </a>
                            <a href="#" class="btn-tabs-link">
                              MET.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              BITSAT.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              SNAP.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              NMAT
                            </a>
                            <a href="#" class="btn-tabs-link">
                              XAT.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              CMAT
                            </a>
                            <a href="#" class="btn-tabs-link">
                              SRM JEE.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              WB JEE.
                            </a>
                            <a href="#" class="btn-tabs-link">
                              Uniguage
                            </a>
                            <a href="#" class="btn-tabs-link">
                              ICAR
                            </a>
                            <a href="#" class="btn-tabs-link">
                              NATA
                            </a>
                          </div>
                          <div class="show-more-btn text-center">
                            <a href="#" class="btn btn-primary">
                              Show More <img src={arrow1} alt="" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container merilbell-youtube-chanel-container grey-bg">
            <div class="container container1">
              <div class="row">
                <div class="col-md-12">
                  <div class="heading">
                    <h3>MeritBell.com Youtube Channel</h3>
                    <div class="heading_shap"></div>
                  </div>
                  <div class="meritbell-video-container">
                    <ul class="meritbell-video-list mobile-slider d-flex flex-wrap">
                      <li class="meritbell-video-item">
                        <div class="meritbell-video-box">
                          <div class="ratio ratio-16x9">
                            <iframe
                              src="https://www.youtube.com/embed/vlDzYIIOYmM"
                              title="YouTube video"
                              allowfullscreen
                            ></iframe>
                          </div>
                        </div>
                      </li>
                      <li class="meritbell-video-item">
                        <div class="meritbell-video-box">
                          <div class="ratio ratio-16x9">
                            <iframe
                              src="https://www.youtube.com/embed/vlDzYIIOYmM"
                              title="YouTube video"
                              allowfullscreen
                            ></iframe>
                          </div>
                        </div>
                      </li>
                      <li class="meritbell-video-item">
                        <div class="meritbell-video-box">
                          <div class="ratio ratio-16x9">
                            <iframe
                              src="https://www.youtube.com/embed/vlDzYIIOYmM"
                              title="YouTube video"
                              allowfullscreen
                            ></iframe>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div class="more-video text-center">
                      <a href="#" class="btn btn-default">
                        <i class="fab fa-youtube"></i> more videos
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container meritbell-scholership-container">
            <div class="container container1">
              <div class="row">
                <div class="col-md-12">
                  <div class="heading blue-heading">
                    <h3>Colleges Under MeritBell Scholership</h3>
                    <div class="heading_shap"></div>
                  </div>
                  <div class="ms-container">
                    <Slider
                      {...this.settings}
                      className="ms-list ms-slider blue-dots"
                    >
                      <li class="ms-item">
                        <div class="ms-box">
                          <div class="ms-img">
                            <img src={school1} alt="" />
                          </div>
                          <div class="ms-caption">
                            <img src={school} alt="" />
                            <p>Er.Perumal Manimekalai College</p>
                          </div>
                        </div>
                      </li>
                      <li class="ms-item">
                        <div class="ms-box">
                          <div class="ms-img">
                            <img src={school2} alt="" />
                          </div>
                          <div class="ms-caption">
                            <img src={school} alt="" />
                            <p>The Oxford College of Law</p>
                          </div>
                        </div>
                      </li>
                      <li class="ms-item">
                        <div class="ms-box">
                          <div class="ms-img">
                            <img src={school3} alt="" />
                          </div>
                          <div class="ms-caption">
                            <img src={school} alt="" />
                            <p>Adhiyamaan College Of Engineering</p>
                          </div>
                        </div>
                      </li>
                      <li class="ms-item">
                        <div class="ms-box">
                          <div class="ms-img">
                            <img src={school2} alt="" />
                          </div>
                          <div class="ms-caption">
                            <img src={school} alt="" />
                            <p>Er.Perumal Manimekalai College</p>
                          </div>
                        </div>
                      </li>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container ranking-container grey-bg">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="heading">
                    <h3>Get Your College Ranked</h3>
                    <div class="heading_shap"></div>
                  </div>
                  <div class="ranking-content-outer d-flex flex-wrap">
                    <div class="ranking-text">
                      <h5>Why Ranking?</h5>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic
                      </p>
                    </div>
                    <div class="ranking-content">
                      <div class="ranking-left">
                        <h5>proceduce</h5>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic
                        </p>
                      </div>
                      <div class="ranking-right-video">
                        <div class="ratio ratio-16x9">
                          <iframe
                            src="https://www.youtube.com/embed/vlDzYIIOYmM"
                            title="YouTube video"
                            allowfullscreen
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-center">
                    <a href="#" class="btn btn-default">
                      Get Ranked
                      <img src={arrow1} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container treading-school-college-container red-bg">
            <div class="container container1">
              <div class="row">
                <div class="col-md-12">
                  <div class="heading heading-white">
                    <h3>Treading School & Colleges</h3>
                    <div class="heading_shap"></div>
                    <p>
                      What do you think is better to receive after each lesson.
                    </p>
                  </div>
                  <div class="tsc-container">
                    <Slider
                      {...this.settings1}
                      class="tsc-list school-info-slider pb-0  d-flex flex-wrap"
                    >
                      <li class="tsc-item">
                        <div class="tsc-box">
                          <div class="tsc-thumb">
                            <img src={school1} alt="" />
                          </div>
                          <div class="tsc-body">
                            <h5 class="tsc-title">
                              <img src={school} alt="" />
                              Er.Perumal Manimekalai College
                            </h5>
                            <div class="tsc-meta">
                              <span class="tsc-meta-item">
                                <img src={courseType2} alt="" />
                                course
                              </span>
                              <span class="tsc-meta-item">
                                <img src={courseType3} alt="" />
                                course
                              </span>
                              <span class="tsc-meta-item">
                                <img src={placement} alt="" />
                                Placement
                              </span>
                            </div>
                            <div class="tsc-btn">
                              <a href="#" class="btn btn-default">
                                <img src={exploreBtn} alt="" />
                                Explore
                              </a>
                              <a href="#" class="btn btn-default">
                                <img src={applyBtn} alt="" />
                                Apply
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="tsc-item">
                        <div class="tsc-box">
                          <div class="tsc-thumb">
                            <img src={school2} alt="" />
                          </div>
                          <div class="tsc-body">
                            <h5 class="tsc-title">
                              <img src={school} alt="" />
                              Er.Perumal Manimekalai College
                            </h5>
                            <div class="tsc-meta">
                              <span class="tsc-meta-item">
                                <img src={courseType2} alt="" />
                                course
                              </span>
                              <span class="tsc-meta-item">
                                <img src={courseType3} alt="" />
                                course
                              </span>
                              <span class="tsc-meta-item">
                                <img src={placement} alt="" />
                                Placement
                              </span>
                            </div>
                            <div class="tsc-btn">
                              <a href="#" class="btn btn-default">
                                <img src={exploreBtn} alt="" />
                                Explore
                              </a>
                              <a href="#" class="btn btn-default">
                                <img src={applyBtn} alt="" />
                                Apply
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="tsc-item">
                        <div class="tsc-box">
                          <div class="tsc-thumb">
                            <img src={school3} alt="" />
                          </div>
                          <div class="tsc-body">
                            <h5 class="tsc-title">
                              <img src={school} alt="" />
                              Er.Perumal Manimekalai College
                            </h5>
                            <div class="tsc-meta">
                              <span class="tsc-meta-item">
                                <img src={courseType2} alt="" />
                                course
                              </span>
                              <span class="tsc-meta-item">
                                <img src={courseType3} alt="" />
                                course
                              </span>
                              <span class="tsc-meta-item">
                                <img src={placement} alt="" />
                                Placement
                              </span>
                            </div>
                            <div class="tsc-btn">
                              <a href="#" class="btn btn-default">
                                <img src={exploreBtn} alt="" />
                                Explore
                              </a>
                              <a href="#" class="btn btn-default">
                                <img src={applyBtn} alt="" />
                                Apply
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="tsc-item">
                        <div class="tsc-box">
                          <div class="tsc-thumb">
                            <img src={school3} alt="" />
                          </div>
                          <div class="tsc-body">
                            <h5 class="tsc-title">
                              <img src={school} alt="" />
                              Er.Perumal Manimekalai College
                            </h5>
                            <div class="tsc-meta">
                              <span class="tsc-meta-item">
                                <img src={courseType2} alt="" />
                                course
                              </span>
                              <span class="tsc-meta-item">
                                <img src={courseType3} alt="" />
                                course
                              </span>
                              <span class="tsc-meta-item">
                                <img src={placement} alt="" />
                                Placement
                              </span>
                            </div>
                            <div class="tsc-btn">
                              <a href="#" class="btn btn-default">
                                <img src={exploreBtn} alt="" />
                                Explore
                              </a>
                              <a href="#" class="btn btn-default">
                                <img src={applyBtn} alt="" />
                                Apply
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-shape"></div>
          </div>
          <NewsLetter />
        </div>
        <Footer />
      </>
    );
  }
}

export default College;
