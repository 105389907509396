import React, { Component } from "react";

//images
import arrow1 from "../../../include/images/arrow-1.svg";

//Component
import Form from "../../common/form/form";

class HwoToReach extends Form {
  render() {
    return (
      <>
        <div class="main-container">
          <div class="heading heading-with-btn">
            <h3>How To Reach</h3>
            <div class="heading_shap"></div>
            <a href="#" class="btn btn-default">
              {" "}
              Skip <img src={arrow1} alt="" />{" "}
            </a>
          </div>
          <div class="dashboard-box">
            <div class="row">
              <div class="col-md-12">
                <div class="sub-heading">
                  <h5>Distance From Airport :</h5>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                {this.renderInput("airportName", "", "Airport Name")}
              </div>
              <div class="col-md-6 col-lg-4">
                {this.renderInput(
                  "distanceFromAirport",
                  "",
                  "Distance From Airport ( In KM)"
                )}
              </div>
              <div class="col-md-6 col-lg-4">
                {this.renderInput("timeToReach", "", "Time To Reach")}
              </div>
              <div class="col-md-12">
                <div class="sub-heading">
                  <h5>Distance From Railway Station :</h5>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                {this.renderInput("rsName", "", "R.S. name")}
              </div>
              <div class="col-md-6 col-lg-4">
                {this.renderInput(
                  "distanceFromRailwaySt",
                  "",
                  "Distance From Railway Station "
                )}
              </div>
              <div class="col-md-6 col-lg-4">
                {this.renderInput("timeToReach", "", "Time To Reach")}
              </div>
              <div class="col-md-12">
                <div class="add-more text-center">
                  <a href="#!" class="add-more-btn">
                    + Add More
                  </a>
                </div>
              </div>
              <div class="col-md-12">
                <div class="sub-heading">
                  <h5>Distance From Bus Station :</h5>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                {this.renderInput("busStationName", "", "Bus Station name")}
              </div>
              <div class="col-md-6 col-lg-4">
                {this.renderInput(
                  "distanceFromBusSt",
                  "",
                  "Distance From Bus Station"
                )}
              </div>
              <div class="col-md-6 col-lg-4">
                {this.renderInput("timeToReach", "", "Time To Reach")}
              </div>
              <div class="col-md-6">
                {this.renderInput(
                  "busNumber",
                  "Bus Number : ",
                  "Enter Bus Number"
                )}
              </div>
              <div class="col-md-12">
                <div class="next-prev-btn d-flex justify-content-between">
                  <a href="#" class="btn btn-default">
                    {" "}
                    <img src={arrow1} alt="" /> Back
                  </a>
                  <a href="#" class="btn btn-default">
                    {" "}
                    Next <img src={arrow1} alt="" />{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HwoToReach;
