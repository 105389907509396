import React, { Component } from "react";

//images
import arrow1 from "../../../include/images/arrow-1.svg";

//Component
import Form from "../../common/form/form";

class Gallery extends Form {
  render() {
    return (
      <>
        <div class="main-container">
          <div class="heading heading-with-btn">
            <h3>Images & Gallery</h3>
            <div class="heading_shap"></div>
            <a href="#" class="btn btn-default">
              {" "}
              Skip <img src={arrow1} alt="" />{" "}
            </a>
          </div>
          <div class="dashboard-box">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <select class="form-control">
                    <option>Number of cabins </option>
                    <option> Option 1</option>
                    <option> Option 2</option>
                    <option> Option 3</option>
                    <option> Option 4</option>
                  </select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="sub-heading">
                  <h5>Upload Cover Image :</h5>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input type="file" placeholder="" class="form-control" />
                </div>
              </div>
              <div class="col-md-6"></div>
              <div class="col-md-12">
                <div class="sub-heading">
                  <h5>Upload Office Image :</h5>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input type="file" placeholder="" class="form-control" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input type="file" placeholder="" class="form-control" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="sub-heading">
                  <h5>Upload Videos :</h5>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input type="file" placeholder="" class="form-control" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input type="file" placeholder="" class="form-control" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="next-prev-btn d-flex justify-content-between">
                  <a href="#" class="btn btn-default">
                    {" "}
                    <img src={arrow1} alt="" /> Back
                  </a>
                  <a href="#" class="btn btn-default">
                    {" "}
                    Next <img src={arrow1} alt="" />{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Gallery;
