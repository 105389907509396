import React, { useEffect, useState } from "react";
import Header from "../common/header";
import logo from "../../include/images/logo.png";
import { Link, useLocation, useParams } from "react-router-dom";
import Login from "./login";
import Register from "./register";
import mixins from "../../utils/mixins";

const AuthLayout = () => {
  let { pathname } = useLocation();
  pathname = pathname.split("/")[1];
  const { category } = useParams();

  //states
  const [bullets] = useState([
    "Apply to Colleges/Schools directly.",
    "View Colleges and Schools.",
    "Check detailed fees.",
    "Most Genuine reviews.",
    "Compare Colleges and Schools.",
    "Never miss important deadlines.",
    "Get free counselling.",
    "Exam papers.",
    "Predict your Rank.",
  ]);
  const [OtherBullets] = useState([
    "Get your listing updated on world's largest education portal MeritBell.com",
    "Promote, Advertise and Showcase your listings with us.",
    "Post, Edit and Delete your listings anytime.",
    "Get discovered by thousands of students and parents visiting MeritBell.com.",
    "Increase and diversify your admissions without lossing any resources and loosing focus and academics.",
    "View your listing, activity,performance, responses and queries all in one place.",
    "Read and know genuine reviews by students and parents.",
    "Get high quality leads.",
    "Interact with students and parents directly.",
    "Participate in our virtual edufair.",
  ]);
  const [catOptions] = useState([
    { label: "As Seller", value: "seller" },
    {
      label: "As Home Tutor",
      value: "homeTutor",
    },
    {
      label: "As Coaching Institute",
      value: "coachingInstitute",
    },
    { label: "As School", value: "school" },
    {
      label: "As PG/Hostel",
      value: "pg/hostel",
    },
    {
      label: "As Consultant",
      value: "consultant",
    },
    {
      label: "As Student",
      value: "student",
    },
    {
      label: "As Institution",
      value: "institution",
    },
  ]);
  const [type, setType] = useState(null);
  const [callingCodes, setCallingCodes] = useState(null);

  const onChangeType = (e) => {
    setType(e);
  };

  useEffect(() => {
    setType(catOptions.find((c) => category == c.value));
  }, [category]);

  useEffect(() => {
    const codes = mixins.methods.getCountryCodes();
    setCallingCodes(codes);
  }, []);

  return (
    <>
      <Header />
      <div className="auth-container container">
        <div className="row h-100">
          <div className="col-md-12 d-flex align-items-center justify-content-center">
            <div className="auth-card w-100 d-xl-flex flex-xl-wrap">
              <div className="auth-left-panel">
                <div className="left-img-container">
                  <img src={logo} alt="logo" />
                </div>
                <div className="left-text-container w-100">
                  <h6 className="reason-text position-relative">
                    {type?.value === "student"
                      ? "Why Sign Up With Us ?"
                      : "Why list on MeritBell"}
                  </h6>
                  <ul className="list-container">
                    {type?.value === "student"
                      ? bullets.map((bu) => (
                          <li key={bu}>
                            <p>{bu}</p>
                          </li>
                        ))
                      : OtherBullets.map((bu) => (
                          <li key={bu}>
                            <p>{bu}</p>
                          </li>
                        ))}
                  </ul>
                </div>
              </div>
              <div className="auth-right-panel">
                <div className="switch-container d-flex flex-column align-items-center">
                  <div class="merit-tabs">
                    <ul class="nav nav-tabs bg_red" id="ex1" role="tablist">
                      <li class="nav-item" role="presentation">
                        <Link
                          className={
                            pathname === "login"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to={`/login/${category}`}
                          id="ex1-tab-22"
                          data-mdb-toggle="tab"
                          href="#ex1-tabs-22"
                          role="tab"
                          aria-controls="ex1-tabs-22"
                          aria-selected="true"
                        >
                          Login
                        </Link>
                      </li>
                      <li class="nav-item" role="presentation">
                        <Link
                          className={
                            pathname === "signup"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to={`/signup/${category}`}
                          id="ex1-tab-23"
                          data-mdb-toggle="tab"
                          href="#ex1-tabs-23"
                          role="tab"
                          aria-controls="ex1-tabs-23"
                          aria-selected="false"
                        >
                          Sign up
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {pathname === "login" ? (
                    <Login
                      category={category}
                      catOptions={catOptions}
                      type={type}
                      onChangeType={onChangeType}
                      callingCodes={callingCodes}
                    />
                  ) : (
                    <Register
                      category={category}
                      catOptions={catOptions}
                      type={type}
                      onChangeType={onChangeType}
                      callingCodes={callingCodes}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
