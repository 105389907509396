const countryCodes = require("country-codes-list");
export default {
  methods: {
    getCountryCodes: function () {
      const myCountryCodesObject = countryCodes.customList(
        "countryCode",
        "{countryCallingCode}"
      );
      return myCountryCodesObject;
    },
  },
};
