import React from "react";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
  MDBBtn,
  MDBModalTitle,
  MDBModalHeader,
} from "mdb-react-ui-kit";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const CategorySelector = (props) => {
  const navigate = useNavigate();
  //states
  const { toggleCat, setToggleCat, type, setType } = props;
  const [category] = useState([
    { name: "As Seller", className: "fas fa-user fa-3x", value: "seller" },
    {
      name: "As Home Tutor",
      className: "fas fa-user fa-3x",
      value: "homeTutor",
    },
    {
      name: "As Coaching Institute",
      className: "fas fa-chalkboard fa-3x",
      value: "coachingInstitute",
    },
    { name: "As School", className: "fas fa-school fa-3x", value: "school" },
    {
      name: "As PG/Hostel",
      className: "fas fa-hotel fa-3x",
      value: "pg/hostel",
    },
    {
      name: "As Consultant",
      className: "fas fa-users fa-3x",
      value: "consultant",
    },
    {
      name: "As Student",
      className: "fas fa-user-graduate fa-3x",
      value: "student",
    },
    {
      name: "As Institution",
      className: "fas fa-university fa-3x",
      value: "institution",
    },
  ]);
  return (
    <MDBModal
      className="category-modal"
      tabIndex="-1"
      show={toggleCat}
      setShow={setToggleCat}
    >
      <MDBModalDialog centered size="lg">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle className="w-100">
              <div className="title-conatiner d-flex flex-wrap w-100 mt-2">
                <a
                  className={
                    type === "login" ? "title-link active" : "title-link"
                  }
                  href="javascript:void(0)"
                  onClick={() => setType("login")}
                >
                  Login
                </a>
                <a
                  className={
                    type === "register" ? "title-link active" : "title-link"
                  }
                  href="javascript:void(0)"
                  onClick={() => setType("register")}
                >
                  Register
                </a>
              </div>
            </MDBModalTitle>
            <i
              onClick={() => setToggleCat(!toggleCat)}
              class="fas fa-times fa-lg cursor-pointer"
            ></i>
          </MDBModalHeader>
          <MDBModalBody>
            <div className="category-container">
              <ul className="cat-list-container d-flex flex-wrap-reverse align-items-strech justify-content-center">
                {category.map((cat) => (
                  <li
                    className="cat-item d-flex align-items-center justify-content-center cursor-pointer"
                    onClick={() =>
                      navigate(
                        type === "login"
                          ? `/login/${cat.value}`
                          : `/signup/${cat.value}`
                      )
                    }
                  >
                    <a
                      href="javascript:void(0)"
                      className="cat-link text-center "
                    >
                      <div className="icon-container">
                        <i class={cat.className}></i>
                      </div>
                      {cat.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default CategorySelector;
