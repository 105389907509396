import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

//images
import cancel from "../../include/images/cancel.png";
import iconDash from "../../include/images/dashboard-icon.svg";
import iconBasic from "../../include/images/basic-icon.svg";
import iconCall from "../../include/images/call-icon.svg";
import iconAdr from "../../include/images/address-icon.svg";
import iconComDet from "../../include/images/company-details-icon.svg";
import iconService from "../../include/images/service-icon.svg";
import iconGal from "../../include/images/gallery-icon.svg";
import iconSetting from "../../include/images/setting-icon.svg";
import iconSocial from "../../include/images/social-icon.svg";
import iconAward from "../../include/images/award-icon.svg";
import iconTimming from "../../include/images/timing-icon.svg";
import iconReach from "../../include/images/reach-icon.svg";
import iconBanking from "../../include/images/banking-icon.svg";
import iconRate from "../../include/images/rating-icon.svg";
import iconUpg from "../../include/images/upgrade-icon.svg";
import iconLead from "../../include/images/lead-icon.svg";
import iconFaq from "../../include/images/faq-icon.svg";
import iconContact from "../../include/images/contact-icon.svg";
import iconLogout from "../../include/images/logout-icon.svg";
import { Link } from "react-router-dom";

const DashboardSidebar = (props) => {
  const location = useLocation();
  const pathname = location.pathname;
  return (
    <div class="sidebar">
      <div
        class="hamburger d-block d-xl-none"
        onClick={() => props.toggleMobSideBar()}
      >
        <img src={cancel} alt="" />
      </div>
      <div class="sidebar-header">
        <div class="user-box d-flex align-items-center">
          <div class="user">
            <img
              class="user-image"
              src="https://via.placeholder.com/100"
              alt=""
            />
            <span>Upload Logo</span>
          </div>
          <h4 class="user-name">Company Name</h4>
        </div>
      </div>
      <div class="navigation">
        <ul class="nav flex-column">
          <li
            class={pathname === "/dashboard" ? "nav-item active" : "nav-item"}
            onClick={() => props.toggleMobSideBar()}
          >
            <Link to="/dashboard" class="nav-link">
              <img src={iconDash} alt="" /> Dashboard
            </Link>
          </li>
          <li
            class={
              pathname === "/dashboard/basic-detail"
                ? "nav-item active"
                : "nav-item"
            }
            onClick={() => props.toggleMobSideBar()}
          >
            <Link to="/dashboard/basic-detail" className="nav-link">
              <img src={iconBasic} alt="" /> Basic Information
            </Link>
          </li>
          <li
            class={
              pathname === "/dashboard/contact-detail"
                ? "nav-item active"
                : "nav-item"
            }
            onClick={() => props.toggleMobSideBar()}
          >
            <Link to="/dashboard/contact-detail" class="nav-link">
              <img src={iconCall} alt="" /> Contact Details
            </Link>
          </li>
          <li
            class={
              pathname === "/dashboard/address" ? "nav-item active" : "nav-item"
            }
            onClick={() => props.toggleMobSideBar()}
          >
            <Link to="/dashboard/address" class="nav-link">
              <img src={iconAdr} alt="" /> Address
            </Link>
          </li>
          <li
            class={
              pathname === "/dashboard/company-detail"
                ? "nav-item active"
                : "nav-item"
            }
            onClick={() => props.toggleMobSideBar()}
          >
            <Link to="/dashboard/company-detail" class="nav-link">
              <img src={iconComDet} alt="" /> Company Details
            </Link>
          </li>
          <li
            class={
              pathname === "/dashboard/service" ? "nav-item active" : "nav-item"
            }
            onClick={() => props.toggleMobSideBar()}
          >
            <Link to="/dashboard/service" class="nav-link">
              <img src={iconService} alt="" /> Service
            </Link>
          </li>
          <li
            class={
              pathname === "/dashboard/gallery" ? "nav-item active" : "nav-item"
            }
            onClick={() => props.toggleMobSideBar()}
          >
            <Link to="/dashboard/gallery" class="nav-link">
              <img src={iconGal} alt="" /> Image & Gallery
            </Link>
          </li>
          <li
            class={
              pathname === "/dashboard/facilities"
                ? "nav-item active"
                : "nav-item"
            }
            onClick={() => props.toggleMobSideBar()}
          >
            <Link to="/dashboard/facilities" class="nav-link">
              <img src={iconSetting} alt="" /> Facilities & Amenities
            </Link>
          </li>
          <li
            class={
              pathname === "/dashboard/social-media"
                ? "nav-item active"
                : "nav-item"
            }
            onClick={() => props.toggleMobSideBar()}
          >
            <Link to="/dashboard/social-media" class="nav-link">
              <img src={iconSocial} alt="" /> Social Media
            </Link>
          </li>
          <li
            class={
              pathname === "/dashboard/certification"
                ? "nav-item active"
                : "nav-item"
            }
            onClick={() => props.toggleMobSideBar()}
          >
            <Link to="/dashboard/certification" class="nav-link">
              <img src={iconAward} alt="" /> Certification & Awards
            </Link>
          </li>
          <li
            class={
              pathname === "/dashboard/timing" ? "nav-item active" : "nav-item"
            }
            onClick={() => props.toggleMobSideBar()}
          >
            <Link to="/dashboard/timing" class="nav-link">
              <img src={iconTimming} alt="" /> Timing & Other Details
            </Link>
          </li>
          <li
            class={
              pathname === "/dashboard/how-to-reach"
                ? "nav-item active"
                : "nav-item"
            }
            onClick={() => props.toggleMobSideBar()}
          >
            <Link to="/dashboard/how-to-reach" class="nav-link">
              <img src={iconReach} alt="" /> How To Reach
            </Link>
          </li>
          <li
            class={
              pathname === "/dashboard/banking" ? "nav-item active" : "nav-item"
            }
            onClick={() => props.toggleMobSideBar()}
          >
            <Link to="/dashboard/banking" class="nav-link">
              <img src={iconBanking} alt="" /> Banking & Payment
            </Link>
          </li>
          <li class="nav-item">
            <a href="#!" class="nav-link">
              <img src={iconRate} alt="" /> Reviews & rating
            </a>
          </li>
          <li class="nav-item">
            <a href="#!" class="nav-link">
              <img src={iconUpg} alt="" /> Upgrade / Subscriptio
            </a>
          </li>
          <li class="nav-item">
            <a href="#!" class="nav-link">
              <img src={iconLead} alt="" /> Leads
            </a>
          </li>
          <li class="nav-item">
            <a href="#!" class="nav-link">
              <img src={iconFaq} alt="" /> FAQs
            </a>
          </li>
          <li class="nav-item">
            <a href="#!" class="nav-link">
              <img src={iconContact} alt="" /> Contact Us
            </a>
          </li>
          <li class="nav-item">
            <a href="#!" class="nav-link">
              <img src={iconLogout} alt="" /> Log Out
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DashboardSidebar;
