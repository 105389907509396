import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { useWeb3React } from "@web3-react/core";

export const withRouter = (Component) => {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    // const web3Functions = useWeb3React();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
        // web3Functions={web3Functions}
      />
    );
  }
  return ComponentWithRouterProp;
};
