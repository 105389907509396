import React, { Component } from "react";

//images
import arrow1 from "../../../include/images/arrow-1.svg";

//component
import Form from "../../common/form/form";

class Certifications extends Form {
  render() {
    return (
      <>
        <div class="main-container">
          <div class="heading heading-with-btn">
            <h3>Certification & Awards</h3>
            <div class="heading_shap"></div>
            <a href="#" class="btn btn-default">
              {" "}
              Skip <img src={arrow1} alt="" />{" "}
            </a>
          </div>
          <div class="dashboard-box">
            <div class="row">
              <div class="col-md-12">
                <div class="sub-heading">
                  <h5>
                    Upload award if you have recieved any related to your
                    profession
                  </h5>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input type="file" placeholder="" class="form-control" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input type="file" placeholder="" class="form-control" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="add-more text-center">
                  <a href="#!" class="add-more-btn">
                    + Add More
                  </a>
                </div>
              </div>
              <div class="col-md-4">
                <div class="sub-heading">
                  <h5>Office Operation & Timing :</h5>
                </div>
              </div>
              <div class="col-md-4">
                <div class="sub-heading">
                  <h5>Opens 24 Hour </h5>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-check-group d-flex type2">
                  <div class="form-check ">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="yesNo"
                      id="select30"
                      value="Yes"
                    />
                    <label class="form-check-label" for="select30">
                      Yes
                    </label>
                  </div>
                  <div class="form-check ">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="yesNo"
                      id="select31"
                      value="No"
                    />
                    <label class="form-check-label" for="select31">
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="sub-heading">
                  <h5>Show Hours of Operation :</h5>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <div class="switch-toggle">
                    <span>Yes</span>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                    />
                    <span>No</span>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="d-flex flex-wrap">
                  <div class="sub-heading me-3">
                    <h5>Sunday Working :</h5>
                  </div>
                  <div class="form-group">
                    <div class="switch-toggle">
                      <span>Yes</span>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                      />
                      <span>No</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="sub-heading">
                  <h5>Working Days :</h5>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-check-group d-flex flex-wrap type2 grid-six">
                  <div class="form-check ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="select32"
                      value="Mon"
                    />
                    <label class="form-check-label" for="select32">
                      Mon
                    </label>
                  </div>
                  <div class="form-check ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="selec33"
                      value="Tue"
                    />
                    <label class="form-check-label" for="selec33">
                      Tue
                    </label>
                  </div>
                  <div class="form-check ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="select34"
                      value="Wed"
                    />
                    <label class="form-check-label" for="select34">
                      Wed
                    </label>
                  </div>
                  <div class="form-check ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="select35"
                      value="Thu"
                    />
                    <label class="form-check-label" for="select35">
                      Thu
                    </label>
                  </div>
                  <div class="form-check ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="select36"
                      value="Fri"
                    />
                    <label class="form-check-label" for="select36">
                      Fri
                    </label>
                  </div>
                  <div class="form-check ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="select37"
                      value="Sat"
                    />
                    <label class="form-check-label" for="select37">
                      Sat
                    </label>
                  </div>
                  <div class="form-check ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="select38"
                      value="Sun"
                    />
                    <label class="form-check-label" for="select38">
                      Sun
                    </label>
                  </div>
                  <div class="form-check ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="select39"
                      value="All"
                    />
                    <label class="form-check-label" for="select39">
                      All
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="sub-heading">
                  <h5>Do you Offer Scholarship :</h5>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <div class="switch-toggle">
                    <span>Yes</span>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                    />
                    <span>No</span>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="d-flex flex-wrap">
                  <div class="sub-heading me-3">
                    <h5>Franchisee Available</h5>
                  </div>
                  <div class="form-group">
                    <div class="switch-toggle">
                      <span>Yes</span>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                      />
                      <span>No</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="sub-heading">
                  <h5>Do you hoop to Get Scholarship :</h5>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <div class="switch-toggle">
                    <span>Yes</span>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                    />
                    <span>No</span>
                  </div>
                </div>
              </div>
              <div class="col-md-4"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>I Want Information Through :</label>
                  <select class="form-control">
                    <option>Select Medium </option>
                    <option> Option 1</option>
                    <option> Option 2</option>
                    <option> Option 3</option>
                    <option> Option 4</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>I Want to Recieve call Between :</label>
                  <select class="form-control">
                    <option>Select Timing </option>
                    <option> Option 1</option>
                    <option> Option 2</option>
                    <option> Option 3</option>
                    <option> Option 4</option>
                  </select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="next-prev-btn d-flex justify-content-between">
                  <a href="#" class="btn btn-default">
                    {" "}
                    <img src={arrow1} alt="" /> Back
                  </a>
                  <a href="#" class="btn btn-default">
                    {" "}
                    Next <img src={arrow1} alt="" />{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Certifications;
