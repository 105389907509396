import React, { Component } from "react";
import iconEmail from "../../include/images/icon-email.svg";

class NewsLetter extends Component {
  render() {
    return (
      <div class="cta-container">
        <div class="container">
          <div class="row">
            <div class="col-xl-10 offset-xl-1">
              <div class="cta-content">
                <div class="cta-left">
                  <h4>
                    {" "}
                    <img src={iconEmail} alt="" />
                    Subscribe to Our Newsletter!
                  </h4>
                </div>
                <div class="cta-right">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Email"
                    />
                    <input type="button" class="btn" value="Submit" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default NewsLetter;
