import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../include/images/logo.png";

class Logo extends Component {
  render() {
    return (
      <Link class="navbar-brand" to="/">
        <img src={logo} />
      </Link>
    );
  }
}
export default Logo;
